import { QuizFieldTypes, QuizFieldTypeDetails } from "constants/quiz-field-types-enum";
import styles from './QuizBlockResult.module.scss'
import FeatherIcon from "feather-icons-react";
import cn from "classnames";

export const QuizBlockResult = ({ block, blockResult, internalStakeholder=null }) => {


  const renderFieldAnswer = (field, answer) => {
    if (!answer) return;
    const isAnswer = field?.options?.options?.filter(op=> op.isAnswer)
    const correctAnswers = isAnswer?.map(ans=> ans?.value)
    
    switch (field.typeOf) {
      case QuizFieldTypes.MultipleChoice:
        if (field.options.multipleSelection) {
          return (
            <ul className={styles.options}>
              {correctAnswers.map((option, i) => (
                <li
                  className={cn(styles.option, { [styles.optionSelected]: answer.value?.options?.includes(option) })}
                  key={`${option} - ${i}`}
                >
                  {option}
                  {!!answer.value?.options?.includes(option) && '✅' }
                </li>
              ))}
            </ul>
          );
        } else {
          return <p>{`${answer.value.text} ${isAnswer[0]?.value === answer?.value?.text ? '✅' : '❌'}`}</p>;
        }
      case QuizFieldTypes.YesNo:
        return <p className="margin-bottom-0">{`${answer.value.text} ${field?.options?.answer === answer?.value?.text ? '✅' : '❌'}`}</p>;

      default:
        return <p className="margin-bottom-0">{`${answer.value.text}`}</p>;

    }
  };

  const renderMarks = (field, answer) =>{
    const total = field?.options?.marks || 1

    if (field?.typeOf === 'multi_choice'){
      if (field?.options?.multipleSelection){
        const isAnswer = field?.options?.options?.filter(op=> op.isAnswer)
        const answeredOptions = isAnswer?.map(a=>a.value)
        const answers = answeredOptions?.filter(element => !answer?.value?.options?.includes(element));
        return !answers?.length ? `${total}/${total}` : `0/${total}`
      }else{
        const isAnswer = field?.options?.options?.find(op=> op.isAnswer)
        return isAnswer?.value === answer?.value?.text ? `${total}/${total}` : `0/${total}`
      }
    }else{
      return field?.options?.answer.toUpperCase() === answer?.value?.text.toUpperCase() ?
       `${total}/${total}` : `0/${total}`
    }
  }

  return (
    <>
      {internalStakeholder &&
        <div className={cn(styles.fieldRow, "pt-3")}>
          <p style={{color: internalStakeholder.stakeholderTag.color}} className="title-4 font-weight-500">{internalStakeholder?.stakeholderTag?.name}</p>
          <p className="title-4 font-weight-500">{internalStakeholder?.name}</p>
        </div>
      }
      <h4>Block Summary ({block.fields.length} fields):</h4>
      {block.fields.map((field) => {
        const answer = blockResult?.answers.find((answer) => answer.fieldId === field.id);
        return (<>
          <div key={field.id} className={cn(styles.fieldRow, "margin-bottom--small")}>
            <div>
              <p className="t-body t-color-secondary">
                {field.order}. {QuizFieldTypeDetails[field.typeOf].title}
              </p>
              <p className="t-body margin-bottom-0">"{field.text}"</p>
            </div>
            {!!['multi_choice','yes_no']?.includes(field?.typeOf) &&
              <>
                <div>
                  <p className="t-body t-color-secondary">Answer</p>
                  <div className="t-body">{renderFieldAnswer(field, answer)}</div>
                </div>
                <div>
                  <p className="t-body t-color-secondary">Marks</p>
                  <div className="t-body">{renderMarks(field, answer)}</div>
                </div>
              </>
            }
          </div>
          <hr/>
        </>);
      })}
    </>
  );
};
