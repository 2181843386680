import cn from 'classnames';
import Moment from 'react-moment';
import { HistoryType } from '../HistoryTypes/HistoryTypes';
import styles from './HistoryRow.module.scss';

export const HistoryRow = ({ history, externalStakeholder, className }) => {
  return (
    <div className={cn("d-flex card card-with-border", styles.root, className)}>
      <p className={cn(styles.date)}>
        <Moment date={history?.createdAt} format='DD/MM/YYYY @ h:ma' />
      </p> 
      <p className={cn(styles.text)}>
        {<HistoryType history={history} externalStakeholder={externalStakeholder} />}
      </p>
    </div>
  )
}