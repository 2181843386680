import styles from './VideoWidget.module.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import urlParser from 'utils/format-video-url.js';

export const VideoWidget = ({ 
  src,
  className,
  noClickable=true,
  loop=true,
  autoplay=true,
  mute=true,
}) => {

  const [parameterizedSrc, setParameterizedSrc] = useState();
  
  useEffect(() => {
    if(!src) return;
    const code = urlParser(src)
    const embeddedUrl = `https://www.youtube.com/embed/${code}`
    let _parameterizedSrc = `${embeddedUrl}?modestbranding=1&controls=0&fs=0&disablekb=0&rel=0`;
    if(loop)     _parameterizedSrc += '&loop=1';
    if(autoplay) _parameterizedSrc += '&autoplay=1';
    if(mute)     _parameterizedSrc += '&mute=1';
    setParameterizedSrc(_parameterizedSrc)
  }, [src])

  return (
    <div className={cn(styles.root, { [className]: className })}>
      {parameterizedSrc ?
        <iframe
          width="853"
          height="480"
          src={parameterizedSrc}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          style={{ backgroundColor: '#e5e5e5' }}
        />
        : 
        <div className={styles.placeholder}></div>
      }
      {noClickable && 
        <div className={styles.overlap}></div>
      }
    </div>
  )
}