import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { useExternalStakeholderLayoutContext } from "templates/ExternalStakeholderLayout/ExternalStakeholderLayout";
import { HistoryRow } from "views/StakeholderProfileScreen/tabs/History/components/HistoryRow/HistoryRow";

const HISTORIES_QUERY = loader("src/graphql/queries/external_stakeholder_histories.graphql");

export const HistoryTab = ({currentStakeholder}) =>{

    const [histories, setHistories] = useState([])

    const {setLoading} = useExternalStakeholderLayoutContext()

    const { loading: fetchingHistories } = useQuery(HISTORIES_QUERY, {
        onCompleted: data => {
            setHistories(data.externalStakeholderHistories)
        }
    });

    useEffect(()=>{
        setLoading(fetchingHistories)
    },[fetchingHistories])

    const sortedHistories = [...histories];

    sortedHistories.sort((a, b) => {
        const idA = parseInt(a.id)
        const idB = parseInt(b.id)
        return idB - idA; 
        })
  
  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      {sortedHistories?.map((history) => (
        <HistoryRow history={history} externalStakeholder={{firstName: currentStakeholder.name.split(' ')[0]}} />
      ))}
    </div>
  )
}