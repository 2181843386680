import JSZip from 'jszip'
import { saveAs } from "file-saver";
import Papa from "papaparse";
import readXlsxFile from 'read-excel-file'

export const downloadBase64AsZip = async (uploads, fileName) => {

  const trimmedFileName = fileName?.trim().replace(/\s+/g, '_') || 'file'

  const zip = new JSZip();
  const filenameTracker = {}; // Object to track file occurrences

  // Add each file to the ZIP
  uploads.forEach((u) => {
    const [metadata, base64Data] = u.uploadString.split(",");
    const mimeType = metadata.match(/data:(.*?);/)[1];
    const originalFilename = metadata.match(/name=(.*?);/)[1];
    const extension = originalFilename.includes(".")
      ? originalFilename.split(".").pop()
      : ""; // Extract file extension

    // Determine filename based on "text" or default to the original filename
    let filename = u.text
      ? `${u.text}.${extension}` // Use text + extension
      : originalFilename; // Default to original filename

    // Handle duplicate filenames
    if (filenameTracker[filename]) {
      const count = filenameTracker[filename] + 1;
      const baseName = filename.includes(".")
        ? filename.replace(`.${extension}`, "")
        : filename;
      filename = `${baseName}_${count}.${extension}`; // Append counter
      filenameTracker[filename] = 1; // Track the new filename
    } else {
      filenameTracker[filename] = 1; // First occurrence of the filename
    }

    // Decode Base64 to binary data
    const binaryData = atob(base64Data);
    const arrayBuffer = new Uint8Array(binaryData.length).map((_, i) =>
      binaryData.charCodeAt(i)
    );

    // Add file to ZIP
    zip.file(filename, arrayBuffer, { binary: true });
  });

  // Generate the ZIP file
  try {
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Create a link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `${trimmedFileName}.zip`; 
    link.click();

    // Clean up
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Error creating ZIP file:", error);
  }
};

export const downloadZip = async (blobArray,namesArray, zipFileName)=>{
    const zip = new JSZip()
    blobArray.forEach((blob,index)=>{
      zip.file(`${namesArray[index]}.pdf`,blob)
    })
    const content = await zip.generateAsync({type: 'blob'})
    saveAs(content,`${zipFileName}.zip`)
  }

export const base64Decoder =(string)=>{
  var binary = atob(string.replace(/\s/g, ''));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
  }
  return view
}

export const jpegImageToBlob = (string) =>{

  const byteCharacters = window.atob(string);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: "image/jpeg",
  });
  return blob
}

export const csvDataToJson=(data)=>{
  const json = []
  var error = false
  var errorIndex = []
  data?.forEach((row,index)=>{
    const obj={}
    if (!row[1] || !row[2] || !row[3] || !row[4]){
      error = true
      errorIndex.push(index+1)
    }
    obj.firstName = `${row[0]}`?.trim()
    obj.lastName = `${row[1]}`?.trim()
    obj.email = `${row[2]}`?.trim()
    obj.phoneNumber = `${row[3]}`?.trim()
    obj.startDate = `${row[4]}`?.trim()
    json.push(obj)
  })
  if(error) {
    return {error:`Row ${errorIndex.toString()} has data issue`}
  }else{
    return json
  }
}

export const sampleCsv = () =>{
  const json=[]
  for(var i=0 ; i<20 ; i++){
    json.push(`"John" "Mayor" "JohnMayor${i+1}@gmail.com" "+641234567890" "${i+1}/02/2023"\n`)
  }
  const string = json.toString().replaceAll(',',"").replaceAll(" ",",")
  const blob = new Blob([string], {type: 'text/plain'})
  saveAs(blob,"sample.csv")
  const blobXlsx = new Blob([string], {type: 'application/vnd.ms-excel'})
  saveAs(blobXlsx, "sample.xlsx")
}

export const readFile = (fileUploaded , setData , triggerUnexpectedError) =>{
  const fileType = fileUploaded.type
  if (fileType ==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
    readXlsxFile(fileUploaded).then((rows) => {
      setData(rows)
    })
    .catch(error=>triggerUnexpectedError(`${error}`))
  }else if (fileType==="text/csv"){
    Papa.parse(fileUploaded, {
      skipEmptyLines : true,
    complete: function(result) {
        setData(result.data)
      }
    })
  }
}

export const checkPermission = (user, permission) => {
  const isSuperAdmin = user?.roles?.find(role => role.name === 'super_admin')
  return isSuperAdmin || user?.permissions?.includes(permission)
}

export const checkSuperAdmin = (user) => {
  return !!user?.roles?.find(role => role.name === 'super_admin')
}

export const generatePassword = (length) => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_+?,.-";
  let password = "";
  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
}

export const titleize = (str) => {
  return str.replace(/_/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
}

export const makeOptions = (array, label = 'name') => {
  return array?.map((item) => {
    return {label: item[label], value: item.id}
  })
}

export const consolidatedCsvs = (csvs) =>{
  const header = csvs[0].split('\n')
  const final = [`${header[0]}\n`]
  csvs.forEach((csv)=>{
    const data = csv.split('\n')
    final.push(`${data[1]}\n`)
  })
  return new Blob([final.toString().replace(/\n,/g, "\n")], { type: "text/csv;charset=utf-8" });
}


export const formatQuestionnaireFields=(text, otherBlocks)=>{
  const extractedObjects = regexOccurences(text)
  extractedObjects?.forEach((object)=>{
    const theBlock = otherBlocks?.find(block => block?.block?.shortCode === object?.name);
    const field = theBlock?.block?.fields?.find(f => f.order === (object.order+1) );
    const answer = theBlock?.result?.answersAttributes?.find(answer => answer?.fieldId === field?.id);
    const string = `{{${object?.name}_${object?.order}}}`;
    if (answer?.value?.number || answer?.value?.text){
      text = text.replace(string, answer?.value?.number || answer?.value?.text)
    }else{
      text = text.replace(string, '')
    }
  })
  return text;
}


export const regexOccurences = (text, regexCheck=/\{{([^{}]+)_([0-9]+)\}}/g)=> {
  const extractedObjects = [];

  let match;
  while ((match = regexCheck.exec(text)) !== null) {
    const name = match[1];
    const order = parseInt(match[2]) ; 
    extractedObjects.push({ name, order });
  }
  return extractedObjects
}


export const convertsLinksToClickText = (text) => {
  text = text?.replace(/\b(https?:\/\/\S+|www\.\S+)/gi, (match) => {
    if (match.startsWith('www.')) {
        return '<a href="http://' + match + '" target="_blank">click here</a>';
    } else {
        return '<a href="' + match + '" target="_blank">click here</a>';
    }
  });
  return text
}