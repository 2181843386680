/* eslint-disable default-case */
import cn from "classnames";
import styles from "./Diary.module.scss";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useEffect, forwardRef, useImperativeHandle, useState } from "react";
import { CustomButton } from "views/StakeholderJourneyScreens/components/CustomButton/CustomButton";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { DiaryFields } from "./components/DiaryFields/DiaryFields";
import { Select } from "components/FormComponents";

const CREATE_DIARY_ENTRY_MUTATION = loader("src/graphql/mutations/create_diary_entry.graphql");
const DELETE_DIARY_BLOCK_RESULT_MUTATION = loader("src/graphql/mutations/delete_diary_block_result.graphql")

export const DiaryForm = forwardRef(
  ({setLoading, entries, otherBlocks, required, token, block, result, stakeholderJourneyId, onSubmit = () => {}, onSubmitCta = () => {}, stakeholderName, answerTags}, ref) => {
    const {
      watch,
      register,
      reset,
      control,
      handleSubmit,
      clearErrors,
      unregister,
      setError,
      setValue,
      formState: { errors },
    } = useForm();

    useImperativeHandle(ref, () => ({
      submitCta: () => {
        handleSubmit(onSubmitCta)();
      },
    }));
    const [totalEntries, setTotalEntries] = useState(entries)

    const { append, remove, fields: answers } = useFieldArray({ control, name: "answersAttributes" });
   
    const [createDiaryEntry] = useMutation(CREATE_DIARY_ENTRY_MUTATION);
    
    const [deleteDiary, { loading: deletingDiary }] = useMutation(DELETE_DIARY_BLOCK_RESULT_MUTATION, {
      onCompleted: (data) => {
        if (data?.deleteDiaryBlockResult) {
          const deletedId = data.deleteDiaryBlockResult?.id
          const index = results?.findIndex((br)=>br?.id === deletedId)
          const filteredResults = results?.filter((r)=> r.id !== deletedId)
          setResults(filteredResults)
          setTotalEntries(data?.deleteDiaryBlockResult?.answeredFields)
          remove(index)
        }
      },
    });

    const [results , setResults] = useState(result)
    const [filteredFields, setFilteredFields] = useState(block.fields)
    const [tags , setTags] = useState(null)

    const tagWatch = watch("tag");
    const watchAnswerAttributes = watch('answersAttributes');


    useEffect(() => {
      const uniqueTags = [...new Set(block.fields.flatMap(field => field.options.tag || []))];
      setTags(uniqueTags)
    }, []);

    useEffect(()=>{
      if (tagWatch==='empty'){
        setFilteredFields(block.fields)
      }else{
        const filteredFields = block.fields.filter(field => field.options.tag === tagWatch);
        setFilteredFields(filteredFields)
      }
    },[tagWatch])

    useEffect(()=>{
      //set Field Tags
      setFilteredFields(block.fields)
      const uniqueTags = [...new Set(block.fields.flatMap(field => field.options.tag || []))];
      setTags(uniqueTags)

      //set form fields
      results?.forEach((r)=>{
        let attributes = {
          answersAttributes: [],
        };
        block.fields.forEach((field)=>{
          const answer = r?.answersAttributes?.find((answer) => answer.fieldId === field.id)
          if(answer){
            attributes.answersAttributes.push(answer)
          }else{
            attributes.answersAttributes.push({fieldId: field.id, value: {}})
          }
        })
        append(attributes)
      })
     },[])

    useEffect(() => {
      setLoading(deletingDiary);
    }, [deletingDiary]);

    const createEntry = async() => {
      setLoading(true)
      const response = await createDiaryEntry({
          variables: {
            blockId: block.id,
            token: token,
          }
        })
      setLoading(false)
      setResults([...results, response?.data?.createDiaryEntry]);

      let attributes = {
        answersAttributes: [],
      };

      block.fields.forEach((field)=>{
        attributes.answersAttributes.push({fieldId: field.id, value: {}})
      })

      append(attributes)
      setFilteredFields(block.fields)
    }
    
    return (
      <div className={cn(styles.root, "card", "card-with-border")}>
        <div className={cn("card_content", styles.content)}>
          <p className={cn("title-3")}>{block?.name}</p>
          <p className={cn(styles.entries)}>{totalEntries} entries</p>
          {!!tags?.length && <div className='d-flex justify-content-center align-items-center w-100'>
            <p>Select a Tag to filter fields</p>
            <Select register={register} name={`tag`} placeholder="Select Tag" className={cn(styles.tag, "ms-3 me-3")}>
            <Select.Item value="empty" active={tagWatch === ""}>
              All Fields
            </Select.Item>
            {tags?.map((tag, index) => (
              <Select.Item key={index} value={tag} active={tagWatch === tag}>
                {tag}
              </Select.Item>
            ))}
            </Select>
          </div>}
          <div className={cn(styles.form, "margin-bottom-0")}>
            {answers?.map((answerFields, diaryIndex) => {
              delete answerFields.id;
              return (
                <div className="mb-5">
                  <DiaryFields
                    block={block}
                    filteredFields={filteredFields}
                    answerFields={answerFields}
                    register={register}
                    diaryIndex={diaryIndex}
                    result={results}
                    control={control}
                    watchAnswerAttributes={watchAnswerAttributes}
                    stakeholderJourneyId={stakeholderJourneyId}
                    token={token}
                    key={diaryIndex}
                    setLoading={setLoading}
                    deleteDiary={deleteDiary}
                    setResults={setResults}
                    setError={setError}
                    clearErrors={clearErrors}
                    watch={watch}
                    setEntries={setTotalEntries}
                    answerTags={answerTags}
                  />
                </div>
              );
            })}
            <CustomButton className={styles.appendButton} onClick={createEntry}>
              Add Diary Entry
            </CustomButton>
          </div>
        </div>
      </div>
    );

});
