import { CollapsibleBlock } from "components/CollapsibleBlock/CollapsibleBlock";
import styles from './DiaryList.module.scss';
import cn from 'classnames';
import moment from "moment";
import { IconButton } from "components/IconButton/IconButton";
import { Badge, ListGroup } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { loader } from "graphql.macro";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { consolidatedCsvs } from "utils/helpers";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { InlineDiaryEntry } from "../InlineDiaryEntry/InlineDiaryEntry";
import { useState } from "react";
import { useFieldArray, useForm } from 'react-hook-form';
import { Button } from "components/FormComponents";

const DOWNLOAD_DIARY_ENTRY_QUERY = loader("src/graphql/queries/multiple_block_results.graphql")
const CREATE_DIARY_ENTRY_MUTATION = loader("src/graphql/mutations/create_diary_entry.graphql");

export const DiaryList = ({
    block,
    result,
    entries,
    index,
    setLoading,
    overdue,
    token,
    journeyBlockId,
    overdueDays,
    stakeholderJourneyId,
    tags={tags}
}) =>{

    const {
      watch,
      register,
      control,
      reset,
      clearErrors,
      setError,
      formState: { errors },
    } = useForm({
      defaultValues: {
      }
    });

    const [newEntry , setNewEntry] = useState(null)
    const [newAttributes , setNewAttributes] = useState(null)
    const [results , setResults] = useState(result)
    const [totalEntries, setTotalEntries] = useState(entries)
    const [isOverdue, setIsOverdue] = useState(overdue)
    
    const { append, remove, fields: answers } = useFieldArray({ control, name: "answersAttributes" });


    const [fetchCsv, { loading: fetchingCsv }] = useLazyQuery(DOWNLOAD_DIARY_ENTRY_QUERY, {
      onCompleted: (data) => {
        const blob = consolidatedCsvs([data.multipleBlockResults[0].result])
        const name = `${data.multipleBlockResults[0].blockName}`.replace(/ /g, "_")
        saveAs(blob,`${name}.csv`);
      },
      onError: (error) => {
        setLoading(false);
      },
    });

    const downloadEntry = (id) =>{
      fetchCsv({variables: {blockResultIds: [id]}})
    }
    const [createDiaryEntry] = useMutation(CREATE_DIARY_ENTRY_MUTATION);

    useEffect(()=>{
      setLoading(fetchingCsv)
    },[fetchingCsv])
    
    const formatUpdatedAt=(dateString)=> {
        return moment.utc(dateString).local().format('MMM DD, YYYY @h:mmA');
    }
  
    const latestUpdatedAt = results.reduce((latest, res) => {
        const updatedAt = moment.utc(res.updatedAt);
        if (!latest || updatedAt.isAfter(latest)) {
            return updatedAt;
        } else {
            return latest;
        }
    }, null);

    let entry = 0

    
    let formattedTime;
    if(!totalEntries){
    formattedTime = "• No Logging found"
    }else if (latestUpdatedAt) {
        formattedTime = `• Last updated ${formatUpdatedAt(latestUpdatedAt)}`;
    } else {
        formattedTime = "• No Logging found";
    }
    const navigate = useNavigate();

    const createEntry = async() => {
      reset()
      answers?.forEach((answer,index)=>{
        remove(index)
      })
      setLoading(true)
      const response = await createDiaryEntry({
          variables: {
            blockId: block.id,
            token: token,
          }
        })
      setLoading(false)
      setNewEntry(response?.data?.createDiaryEntry)
      let attributes = {
        answersAttributes: [],
      };

      block.fields.forEach((field)=>{
        attributes.answersAttributes.push({fieldId: field.id, value: {}})
      })

      append(attributes.answersAttributes)
      setNewAttributes(attributes)
    }

    return(
        <CollapsibleBlock actionComponent={<Button className={styles.actionButton} onClick={()=>createEntry()}>Add Entry</Button>}  open={!!newEntry}  color={isOverdue ? 'red' : '#1f4f5c'} key={index} className={cn('margin-bottom')} >
              <CollapsibleBlock.Header>
                <header className={styles.header}>
                  <div className={styles.title}>
                    <p className={cn('title-4', styles.blockName)}>{block?.name}</p>
                    {isOverdue && <Badge className={styles.badge}>{`${overdueDays} days overdue`}</Badge>}
                    
                  </div>
                  <p className='t-body t-color-secondary margin-bottom-0 margin-top--small'>
                    <span className={cn(styles.info,'t-capitalize t-color-secondary')}>{`${totalEntries}x entries ${formattedTime}`} </span>
                  </p>
                </header>
              </CollapsibleBlock.Header>
              <CollapsibleBlock.Content>
               <div className={styles.entries}>
                  <hr/>
                  {
                    results?.map((res)=>{
                      const notLogged = new Date(res.updatedAt).getTime() === new Date(res.createdAt).getTime();
                      if (notLogged || !res?.answersAttributes?.length){
                        return
                      }
                      return(
                        <div className="mt-4">
                            <ListGroup>
                              <ListGroup.Item className={cn(`${styles.listItem}  list-item--success d-flex justify-content-between`)}>
                                <div className={cn(styles.content)}>
                                  <div className={cn("d-flex")}>
                                    <p className="title-4 font-weight-500">{`Entry #${++entry}`}</p>
                                  </div>
                                  <p className={cn(styles.date) + " pt-2"}>Last Updated {formatUpdatedAt(moment.utc(res.updatedAt))}</p>
                                </div>
                                <div>
                                  <Dropdown>
                                    <Dropdown.Toggle className={cn(styles.dropdownToggle)}>
                                      <IconButton className={cn(styles.moreBtn)} icon="more-vertical" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={cn(styles.dropdownMenu)}>
                                      <Dropdown.Item
                                      className={cn(styles.menuItem)}
                                      href="#"
                                      onClick={()=>downloadEntry(res.id)}
                                      >
                                        Download
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                </ListGroup.Item>
                          </ListGroup>
                        </div>
                      )
                    })
                  }                
                {newEntry && newAttributes && 
                 <InlineDiaryEntry setIsOverdue={setIsOverdue} setTotalEntries={setTotalEntries} 
                 totalEntries={totalEntries} entry={entry} setLoading={setLoading} 
                 setNewEntry={setNewEntry} setResults={setResults} results={results} 
                 watch={watch} setError={setError} clearErrors={clearErrors} register={register} 
                 control={control} stakeholderJourneyId={stakeholderJourneyId} block={block} 
                 filteredFields={block.fields} token={token} newEntry={newEntry} answerFields={answers}
                 tags={tags}
                 /> }
               </div>
              </CollapsibleBlock.Content>
        </CollapsibleBlock>
    )
}