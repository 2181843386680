import React, { useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import {Badge} from "react-bootstrap";
import styles from './JourneyBlocks.module.scss'
import { useState } from "react";
import cn from 'classnames';
import { IconButton } from "components/IconButton/IconButton";
import { Block } from "./Block/Block";

export const JourneyBlocks = ({journey}) =>{
    const [open , setOpen] = useState([])

    const handleToggle = (index) => {
      const newOpen = [...open];
      newOpen[index] = !newOpen[index];
      setOpen(newOpen);
    };

    return(
        <div className={styles.root}>
            {
                journey?.journeyBlocks?.map((journeyBlock, index)=>{
                    return(
                        <ListGroup>
                        <ListGroup.Item
                          className={cn(`${styles.listItem}  list-item--success d-flex justify-content-between align-items-center`)}
                          style={{ borderLeft: `4px solid ${journeyBlock?.block?.stakeholderTag?.color}` }}
                        >
                          <div className="w-100">
                            <div className={cn("d-flex")}>
                              <p className="title-4 font-weight-500">{journeyBlock?.block?.name}</p>
                              <Badge className={cn("margin-left--small", "t-capitalize", `badge--${journeyBlock?.required ? "completed" : "pending"}`)}>
                                  {journeyBlock?.required ? "Required" : "Not Required"}
                              </Badge>
                            </div>
                            <Collapse in={open[index]}>
                              <div>
                                  <Block
                                    block={journeyBlock?.block}
                                  />
                              </div>
                            </Collapse>
                          </div>
                          <div>
                            <div className={cn(styles.actionIcons,"d-flex align-items-center")}>
                              <IconButton
                                className={cn(styles.collapseToggle)}
                                icon={`chevron-${open[index] ? "up" : "down"}`}
                                onClick={() => handleToggle(index)}
                              />
                            </div>
                          </div>
                          </ListGroup.Item>
                      </ListGroup>
                    )
                })
            }
      </div>
    )
}