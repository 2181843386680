import React, { useState } from 'react';
import { CollapsibleBlock } from 'components/CollapsibleBlock/CollapsibleBlock';
import styles from './FrameworkPreview.module.scss';
import cn from 'classnames';
import { Badge, Collapse } from 'react-bootstrap';
import { QuestionnairePreview } from 'views/NewStakeholderJourneyScreen/components/JourneyPreview/QuestionnairePreview/QuestionnairePreview';
import { DocumentSigningPreview } from 'views/NewStakeholderJourneyScreen/components/JourneyPreview/DocumentSigningPreview/DocumentSigningPreview';
import { InformationPreview } from 'views/NewStakeholderJourneyScreen/components/JourneyPreview/InformationPreview/InformationPreview';
import { IconButton } from 'components/IconButton/IconButton';

export const FrameworkPreview = ({ journeyFrameworks }) => {
  const [openCollapse, setOpenCollapse] = useState({});

  const handleToggleCollapse = (id) => {
    setOpenCollapse((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderTypePreview = (block) => {
    switch (block.typeOf) {
      case 'diary':
      case 'questionnaire':
        return <QuestionnairePreview block={block} />;
      case 'document':
        return <DocumentSigningPreview block={block} />;
      case 'information':
        return <InformationPreview block={block} />;
      default:
        return null;
    }
  };

  return (
    <>
      {journeyFrameworks?.map((journeyFramework,index) => {
        const isLast = index === journeyFrameworks.length - 1
       return ( <> 
          <div className={cn('card card-with-border', styles.card, { [styles.lastCard]: isLast && !openCollapse[journeyFramework.id] })} key={journeyFramework.id}>
            <div className={styles.mainHeader}>
              <div className={styles.title}>
                {journeyFramework?.journey?.name}
              </div>
              <div className={styles.right}>
                <div className={cn(styles.active, styles.blockBadge)}>
                  {journeyFramework?.journey?.journeyBlocks?.length}
                </div>
                <IconButton className={styles.toggleButton} icon={`chevron-${openCollapse[journeyFramework.id] ? 'up' : 'down'}`} onClick={() => handleToggleCollapse(journeyFramework.id)}/>
              </div>
            </div>
          </div>
            <Collapse className={styles.collapse} in={openCollapse[journeyFramework.id] || false}>
              <div>
                {journeyFramework?.journey?.journeyBlocks?.map((journeyBlock)=>{
                  return (
                    <CollapsibleBlock
                    color={journeyBlock.block.stakeholderTag.color}
                    className={cn(styles.root)}
                    >
                      <CollapsibleBlock.Header>
                        <div className={cn(styles.header, 'mt-1')}>
                          <p className='title-5 font-weight-500'>{journeyBlock?.block?.name}</p>
                          <Badge
                            className={cn('margin-left--small', {
                              ['badge--success']: true,
                              ['badge--default']: false,
                            })}
                          >
                            {journeyBlock.required ? 'Required' : 'Nice to do'}
                          </Badge>
                        </div>
                      </CollapsibleBlock.Header>
                      <CollapsibleBlock.Content>
                        {renderTypePreview(journeyBlock.block)}
                      </CollapsibleBlock.Content>
                  </CollapsibleBlock>
                  )
                })}
              </div>
            </Collapse>
        </>)
      })}
    </>
  );
};
