export const DiaryReminderTypes = [
    {
        title: 'Open',
        value: 'open'
    },
    {
        title: 'Daily',
        value: 'daily'
    },
    {
        title: 'Weekly',
        value: 'weekly'
    },
    {
        title: 'Monthly',
        value: 'monthly'
    },
    {
        title: 'Yearly',
        value: 'yearly'
    }
]