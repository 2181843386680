import { useEffect } from "react";
import { QuestionnairePreview } from "views/NewStakeholderJourneyScreen/components/JourneyPreview/QuestionnairePreview/QuestionnairePreview";
import { DocumentSigningPreview } from "views/NewStakeholderJourneyScreen/components/JourneyPreview/DocumentSigningPreview/DocumentSigningPreview";
import { InformationPreview } from "views/NewStakeholderJourneyScreen/components/JourneyPreview/InformationPreview/InformationPreview";

export const Block = ({block}) =>{

    const renderTypePreview = (block) => {
        switch(block.typeOf) {
          case 'questionnaire': 
            return <QuestionnairePreview block={block} />
          case 'document':
            return <DocumentSigningPreview block={block} />
          case 'information':
            return <InformationPreview block={block} />
        }
      }
    
    return (
        <div>
            {renderTypePreview(block)}
        </div>
    )
}