import styles from './MultipleChoice.module.scss';
import FeatherIcon from 'feather-icons-react';
import { Input } from './../Input/Input';
import cn from 'classnames';
import { useState, useEffect } from 'react';

export const MultipleChoice = ({
  options: {
    multipleSelection,
    options,
    otherOption,
  },
  value,
  onChange,
  disabled,
  optionsClassName
}) => {

  // States to handle other option
  const [otherOptionEnabled, setOtherOptionEnabled] = useState(false);
  const [otherOptionValue, setOtherOptionValue] = useState('');

  // Handle when OTHER OPTION is enabled and answer is not from options
  useEffect(()=>{
    const allOptions = options?.map((option)=>option?.value)
    //In case of Multiple Selection, "value" is an array
    if (otherOption && multipleSelection && value?.length){
      const otherAnswer = value.find((answer)=> !!answer.length && !allOptions.includes(answer))
      if (otherAnswer){
        setOtherOptionEnabled(true)
        setOtherOptionValue(otherAnswer)
      } 
    }else if (otherOption && !multipleSelection && value && !allOptions.includes(value)) {
      setOtherOptionEnabled(true)
      setOtherOptionValue(value)
    }
  },[])
  
  // On option clicked (except for "Other")
  const handleOptionSelected = (selectedValue) => {
    if(disabled) return
    if(multipleSelection) {
      if(!value) {
        onChange([selectedValue])
      } else if (value.includes(selectedValue)) {
        onChange(value.removeValue(selectedValue));
      } else {
        onChange([...value, selectedValue])
      }
    } else {
      setOtherOptionEnabled(false);
      onChange(selectedValue)
    }
  }

  // Check if option selected
  const isSelected = (optionValue) => {
    if(!value) return false;

    if(multipleSelection) {
      return value.includes(optionValue);
    } else {
      return value === optionValue
    }
  }

  // On "Other" option clicked
  const handleOtherOptionClick = () => {
    if(disabled){return}
    if(multipleSelection) {
      if(otherOptionEnabled) {
        onChange(value.removeValue(otherOptionValue));
        setOtherOptionEnabled(false);
      } else {
        setOtherOptionEnabled(true);
      }
    } else {
      onChange(otherOptionValue);
      setOtherOptionEnabled(true);
    }
  }

  const handleOtherOptionInputChange = (e) => {
    const newValue = e.target.value;

    if(multipleSelection) {
      if(!value) {
        onChange([newValue])
      } else {
        const otherOptionIndex = value.indexOf(otherOptionValue);
        if(otherOptionIndex < 0) {
          onChange([...value, newValue])
        } else {
          onChange([...value.slice(0, otherOptionIndex), newValue, ...value.slice(otherOptionIndex + 1)])
        }
      }
    } else {
      onChange(newValue)
    }

    setOtherOptionValue(newValue)
  }

  return (
    <div className={styles.root}>
      {options?.map(option => (
        <Option 
          key={option.value}
          value={option.value}
          selected={isSelected(option.value)}
          multipleSelection={multipleSelection}
          onClick={handleOptionSelected}
          disabled={disabled}
          optionsClassName={optionsClassName}
        />
      ))}
      {otherOption &&
        <div className={cn(styles.option, styles.otherOption, { [styles.optionSelected]: otherOptionEnabled, [styles.disabled]: disabled, [optionsClassName]: optionsClassName } )}>
          <div className={styles.title} onClick={handleOtherOptionClick}>
            {multipleSelection ? 
              <div className={styles.checkbox}><FeatherIcon className={styles.checkIcon} icon='check' size={13}/></div> :
              <div className={styles.radioButton}></div>
            }
            Other
          </div>
          {otherOptionEnabled && 
            <Input className={styles.input} inputProps={{ value: otherOptionValue, onChange: handleOtherOptionInputChange, disabled: disabled }} />
          }
        </div>  
      }
    </div>
  )
}

const Option = ({ 
  value,
  selected=false,
  multipleSelection=false,
  onClick,
  disabled,
  optionsClassName
}) => (
  <div className={cn(styles.option, { [styles.optionSelected]: selected, [styles.disabled]: disabled, [optionsClassName]: optionsClassName } )} onClick={() => onClick(value)}>
    {multipleSelection ? 
      <div className={styles.checkbox}><FeatherIcon className={styles.checkIcon} icon='check' size={13}/></div> :
      <div className={styles.radioButton}></div>
    }
    {value}
  </div>
)