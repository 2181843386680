import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import styles from './BlockSettings.module.scss';
import { InputGroup, Input, Select } from 'components/FormComponents';
import { BlockTypesList } from 'constants/block-types-enum';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { BlockTypes } from 'constants/block-types-enum';
import cn from 'classnames';
import { StakeholderTagType } from 'constants/stakeholder-tag-type-enum';
import { DiaryReminderTypes } from 'constants/diary-reminder-types-enum';

export const BlockSettings = ({
  control,
  register,
  unregister,
  stakeholderTags = [],
  blockExternalStakeholder = false,
  isBlockBundle = false,
  classNames,
  setValue
}) => {

  const stakeholderTagId = useWatch({ control, name: 'stakeholderTagId'})
  const blockType        = useWatch({ control, name: 'typeOf' })
  const reminder         = useWatch({ control, name: 'options.reminders'})
  
  useEffect(() => {
    if(!blockType) return;

    if(blockType !== BlockTypes.Diary){
      unregister('options.reminders')
    }

    switch(blockType) {
      case BlockTypes.DocumentSigning:
        unregister('fieldsAttributes');
        unregister('blockInfoItemsAttributes')
        break;
      case BlockTypes.Diary:
        unregister('documentTemplateAttributes');
        unregister('blockInfoItemsAttributes');
        if(!reminder){
          setValue('options.reminders', 'open')
        }
        break
      case BlockTypes.Questionnaire:
        unregister('documentTemplateAttributes');
        unregister('blockInfoItemsAttributes')
        break;
      case BlockTypes.Information:
        unregister('fieldsAttributes');
        unregister('documentTemplateAttributes');
        break;
      case BlockTypes.Bundle:
        unregister('fieldsAttributes')
        unregister('documentTemplateAttributes');
        unregister('blockInfoItemsAttributes')
        const employeeTag = stakeholderTags.find(st => st.typeOf === StakeholderTagType.External)
        setValue('stakeholderTagId', employeeTag?.id)
    }
  }, [blockType])

  return (
    <EditableSettings title='Block Settings' className={cn('margin-top', styles.root, { [classNames]: classNames })}>
        <div className='inline'>
          <InputGroup title='Block Type'>
            <Select name='typeOf' register={register}>
              {BlockTypesList.map(type => (
                <Select.Item key={type.key} description={type.subTitle} value={type.key} active={blockType === type.key}>{type.title}</Select.Item>
              ))}
            </Select>
          </InputGroup>
          <InputGroup title='Stakeholder'>
            <Select name='stakeholderTagId' register={register} disabled={blockExternalStakeholder || isBlockBundle}>
              {stakeholderTags.map(stakeholderTag => (
                <Select.Item
                  key={stakeholderTag.id}
                  value={stakeholderTag.id}
                  active={stakeholderTagId === stakeholderTag.id}
                >
                  {stakeholderTag.name}
                </Select.Item>
              ))}
            </Select>
          </InputGroup>
        </div>
        {blockType === BlockTypes.Diary &&
          <div className='inline'>
            <InputGroup title='Reminders'>
              <Select name='options.reminders' register={register}>
                {DiaryReminderTypes.map(type => (
                  <Select.Item key={type.title} active={reminder === type.value} value={type.value}>{type.value}</Select.Item>
                ))}
              </Select>  
            </InputGroup>
          </div>
        }
        <hr />
        <InputGroup title='Block Heading (the title that the candidate will see)' className='margin-top'>
          <Input
            name='heading'
            placeholder='Give this block a heading that the candidate will see'
            register={register}
          />
        </InputGroup>
    </EditableSettings>
  )

}
