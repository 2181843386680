import { Link } from "react-router-dom";
import { AppLayout, useAppContext } from "./../../templates/AppLayout/AppLayout";
import FeatherIcon from "feather-icons-react";
import { loader } from "graphql.macro";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { ExternalStakeholderRow } from "./components/ExternalStakeholderRow/ExternalStakeholderRow";
import styles from "./DashboardScreen.module.scss";
import { Button, Input, Select } from "components/FormComponents";
import cn from "classnames";
import { useForm, useWatch } from "react-hook-form";
import fuzzysort from "fuzzysort";
import { Checkbox } from "components/FormComponents/Checkbox/Checkbox";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { titleize } from "utils/helpers";
import { Modal } from "components/Modal/Modal";
import { StakeholderStatusType } from "constants/stakeholder-status-enum";

const EXTERNAL_STAKEHOLDERS_WITH_DELETED_QUERY = loader("./../../graphql/queries/external_stakeholders_with_deleted.graphql");
const EXTERNAL_STAKEHOLDER_QUERY = loader("./../../graphql/queries/external_stakeholder.graphql");
const ARCHIVE_EXTERNAL_STAKEHOLDER= loader("./../../graphql/mutations/archive_external_stakeholder.graphql")
const UNARCHIVE_EXTERNAL_STAKEHOLDER= loader("./../../graphql/mutations/unarchive_external_stakeholders.graphql")
const DELETE_EXTERNAL_STAKEHOLDERS = loader("./../../graphql/mutations/delete_external_stakeholders.graphql")
const SEND_JOURNEY_REMINDER = loader("./../../graphql/mutations/send_journey_reminder.graphql")

export const DashboardScreen = () => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm({
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    useDefaultValues: { tag: "", status: "empty", journey: "empty" },
    defaultValues: {
      status: ''
    }
  });

  const searchName = watch("searchName");

  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  const [externalStakeholders, setExternalStakeholders] = useState([]);
  const [filtered, setFiltered] = useState();
  const [filterBy, setFilterBy] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([])
  const [archive, setArchive] = useState(false)
  const [journeys, setJourneys] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [reminderModal, setReminderModal] = useState(false)

  const tagWatch = useWatch({ control, name: "tag" });
  const statusWatch = useWatch({ control, name: "status" });
  const journeyWatch = useWatch({ control, name: "journey" });


  const filterByTagAndStatus = (filtered) => {
    if ((tagWatch === "empty" || !tagWatch) && (statusWatch === "empty" || !statusWatch)){
      return filtered?.filter((es)=>es.status !== 'archived')
    }
    if ((tagWatch ==="empty" || tagWatch===undefined) && statusWatch && statusWatch !== "empty"){
      return filtered?.filter((es)=> es.status === statusWatch)
    }
    if (tagWatch !== "empty" && tagWatch && statusWatch !== "empty" && statusWatch) {
      return filtered.filter((item) => {
        return item.stakeholderJourneys.some((journey) => {
          return journey.blockResults.some((blockResult) => {
            return blockResult.block.stakeholderTag.name === tagWatch;
          });
        });
      });
    } else if (tagWatch !== "empty") {
      return filtered?.filter((es) => es.stakeholderTag.name === tagWatch);
    } else if (statusWatch !== "empty") {
      return filtered?.filter((es) => es.status === statusWatch);
    }
  };

  const filterByJourney = (stakeholders) =>{
    if (journeyWatch === 'empty' || !journeyWatch){
      return stakeholders
    }else{
      const filteredJourneys = []
      stakeholders?.forEach((stakeholder)=>{
         stakeholder?.stakeholderJourneys?.forEach((sj)=>{
          if (sj?.journey?.name === journeyWatch && !filteredJourneys.includes(stakeholder)) filteredJourneys.push(stakeholder)
        })
      })
      return filteredJourneys
    }
  }

  const filter = {
    status: {
      asc: (a, b) => b.status.localeCompare(a.status),
      desc: (a, b) => a.status.localeCompare(b.status),
    },
    name: {
      asc: (a, b) => b.name.localeCompare(a.name),
      desc: (a, b) => a.name.localeCompare(b.name),
    },
    company: {
      asc: (a, b) => b.company.name.localeCompare(a.company.name),
      desc: (a, b) => a.company.name.localeCompare(b.company.name),
    },
    date: {
      asc: (a, b) => b.startDate.localeCompare(a.startDate),
      desc: (a, b) => a.startDate.localeCompare(b.startDate),
    },
  };

  const handleFilterChange = (column, order) => {
    if (filterBy[0] === column && filterBy[1] === order) {
      if (filterBy[1] === "asc") {
        setFilterBy([column, "desc"]);
        setFiltered(filtered?.sort(filter[column]["desc"]));
      } else {
        setFilterBy([column, "asc"]);
        setFiltered(filtered?.sort(filter[column]["asc"]));
      }
    } else {
      setFilterBy([column, order]);
      setFiltered(filtered?.sort(filter[column][order]));
    }
  };

  const [fetchStakeholders, { loading: fetchingStakeholders }] = useLazyQuery(EXTERNAL_STAKEHOLDERS_WITH_DELETED_QUERY, {
    onCompleted: (data) => {
      setExternalStakeholders(data.externalStakeholdersWithDeleted);
      setFiltered(data.externalStakeholdersWithDeleted);
      const journeys = []
      data.externalStakeholdersWithDeleted?.forEach((stakeholder)=>{
         stakeholder?.stakeholderJourneys?.forEach((sj)=>{
          if(!journeys?.includes(sj?.journey?.name)) journeys.push(sj?.journey?.name)
        })
      })
      setJourneys(journeys)
    },
    onError: () => {
      triggerUnexpectedError();
    },
  });


  const [archiveExternalStakeholder, { loading: archiving }] = useMutation(ARCHIVE_EXTERNAL_STAKEHOLDER, {
    onCompleted: (data) => {
      setFiltered(filtered?.filter((stakeholder)=>stakeholder.id !== data?.archiveExternalStakeholder?.map((es)=>es?.id)))
      setSelected([])
      const status = data?.archiveExternalStakeholder[0]
      alert(`Stakeholder successfully ${status?.archived ? 'archived' : 'unarchived'}`);
      fetchStakeholders()
    },
    onError: () => {
      triggerUnexpectedError();
      setArchive(false)
    },
  });

  const [unarchiveExternalStakeholder, { loading: unarchiving }] = useMutation(UNARCHIVE_EXTERNAL_STAKEHOLDER, {
    onCompleted: (data) => {
      setFiltered(filtered?.filter((stakeholder)=>stakeholder.id !== data?.unarchiveExternalStakeholders?.map((es)=>es?.id)))
      setSelected([])
      alert('Stakeholder successfully Unarchived');
      fetchStakeholders()
    },
    onError: () => {
      triggerUnexpectedError();
      setArchive(false)
    },
  });

  const [deleteExternalStakeholder, { loading: deleting }] = useMutation(DELETE_EXTERNAL_STAKEHOLDERS, {
    onCompleted: (data) => {
      alert('Stakeholder successfully Deleted');
      setSelected([])
      fetchStakeholders()
    },
    onError: () => {
      triggerUnexpectedError();
      setArchive(false)
    },
  });

  const [sendReminder, {loading: sendingReminder}] = useMutation(SEND_JOURNEY_REMINDER,{
    onCompleted:(data)=>{
      if(!data.sendJourneyReminder) setReminderModal(true)
    }
  });

  useEffect(() => {
    setLoading(archiving || unarchiving || fetchingStakeholders || deleting);
  }, [archiving, unarchiving, fetchingStakeholders, deleting ]);

  useEffect(() => {
    fetchStakeholders()
    setSelected([])
  }, []);

  useEffect(() => {
    if (searchName !== null && searchName?.trim() !== "") {
      const filter = fuzzysort.go(searchName, externalStakeholders, { key: "name", threshold: -10000 });
      const result = [];
      filter.forEach((e) => {
        result.push(e.obj);
      });
      setFiltered(result);
    } else {
      fetchStakeholders();
    }
  }, [searchName]);

  useEffect(() => {
    const statuses = externalStakeholders.map((es) => es.status);
    setStatuses([...new Set(statuses)]);

    const allTags = externalStakeholders?.flatMap((stakeholder) =>
      stakeholder?.stakeholderJourneys?.flatMap((journey) =>
      journey?.blockResults?.map((result) => result?.block?.stakeholderTag?.name)
     )
    );
    const uniqueTags = Array.from(new Set(allTags)).filter(Boolean);
    setTags(uniqueTags);
  }, [externalStakeholders]);

  useEffect(()=>{
    setSelected([])
  },[statusWatch, journeyWatch, tagWatch])

  const handleDeleteExternalStakeholder = (ids , multiple) =>{
    deleteExternalStakeholder({ variables: { ids: multiple ?  ids?.map((id)=>parseInt(id)) : parseInt(ids)  }})
    setDeleteModal(false)
  }
  const handleArchiveExternalStakeholder = (val,multiple) =>{
    archiveExternalStakeholder({ variables: { ids: multiple ?  val?.map((value)=>parseInt(value)) : parseInt(val)  }})
    setArchive(false)
  }
  const handleUnarchive = (val, multiple) =>  unarchiveExternalStakeholder({ variables: { ids: multiple ?  val?.map((value)=>parseInt(value)) : parseInt(val)  }})
  const handleCheckBoxClick = (status) => status ? setSelected([]) : setSelected(filterByTagAndStatus(filtered)?.map((es)=>es?.id))
  return (
    <>
      <AppLayout.Header>
        <AppLayout.Title>Dashboard</AppLayout.Title>
        <AppLayout.Subtitle>Below you can quickly monitor the progress of all your employees.</AppLayout.Subtitle>
        <form className="d-flex justify-content-between mt-5">
          <Input name="searchName" register={register} type="text" icon="search" placeholder="Search for an employee..." />
          <div className="flex-03 d-flex align-items-center justify-content-between">
          <div className="t-subtitle d-flex align-items-center justify-content-center">
              Tags
              <Select register={register} name={`tag`} placeholder="Select Tag" className={cn(styles.select, "ms-3 me-3")}>
                <Select.Item value="empty" active={tagWatch === ""}>
                  All
                </Select.Item>
                {tags?.map((tag, index) => (
                  <Select.Item key={index} value={tag} active={tagWatch === tag}>
                    {titleize(tag)}
                  </Select.Item>
                ))}
              </Select>
            </div>
            <div className="t-subtitle d-flex align-items-center justify-content-center">
              Showing
              <Select register={register} name={`status`} placeholder="Select any" className={cn(styles.select, "ms-3 me-3")}>
                <Select.Item key={"null"} value={"empty"} active={statusWatch === ""}>
                  Active
                </Select.Item>
                {StakeholderStatusType?.map((status, index) => (
                  <Select.Item key={index} value={status.key} active={statusWatch === status.key}>
                    {titleize(status.value)}
                  </Select.Item>
                ))}
              </Select>
            </div>
            <div className="t-subtitle d-flex align-items-center justify-content-center">
              Journeys
              <Select register={register} name={`journey`} placeholder="Select any" className={cn(styles.select, "ms-3 me-3")}>
                <Select.Item key={"null"} value={"empty"} active={journeyWatch === ""}>
                  All
                </Select.Item>
                {journeys?.map((journey, index) => (
                  <Select.Item key={index} value={journey} active={journeyWatch === journey}>
                    {titleize(journey)}
                  </Select.Item>
                ))}
              </Select>
            </div>
            <Link to="/new_launch" className={cn(styles.addBtn, "button")}>
              <FeatherIcon icon="user-plus" size={15} className="margin-right--small" />
              New Launch
            </Link>
          </div>
        </form>
      </AppLayout.Header>
      <AppLayout.Body>
        <>
          <Modal
          visible={reminderModal}
          title="Reminder not sent"
          subtitle="There are currently no in-progress or pending journeys for this stakeholder"
          onClose={() => {
            setReminderModal(false);
          }}
        />
          <ConfirmationModal
          visible={archive || deleteModal}
          title={`${deleteModal ? 'Delete' : archive && statusWatch==='archived' ? 'Unarchive' : 'Archive' } Stakeholders Confirmation`}
          subtitle={`${selected?.length} stakeholders will be ${deleteModal ? 'completely Deleted' : statusWatch==='archived' ? 'Unarchived' : ' Archived'}`}
          onClose={() => {
             setArchive(false)
             setDeleteModal(false)
            }}
          onConfirmation={() =>{
              deleteModal ? handleDeleteExternalStakeholder(selected, true) :
              statusWatch==='archived' ? handleUnarchive(selected,true) : handleArchiveExternalStakeholder(selected,true)
              setArchive(false)
              setDeleteModal(false)
            }}
          buttonTitle={`Yes`}
          />
          {!!selected?.length &&
            <div className={styles.checkboxBar}>
              <div className={styles.bar}>
                <div className={styles.content}>
                  <div className={styles.text}>Bulk Actions ( {selected?.length} growers selected ) </div>
                  <div className={styles.archiveJourneys}>
                    <Button icon="trash-2" type="primary" onClick={()=>setDeleteModal(true)} className={styles.button}> Delete Growers </Button>
                    <Button icon="archive" type="primary" onClick={()=>setArchive(true)} className={styles.button}> {statusWatch ==='archived' ? 'Unarchive Growers': 'Archive Growers'}</Button>
                  </div>
                </div>
              </div>
              <div className={styles.arrowDown}></div>
            </div>
          }
        </>
        <div className={styles.tableHeaders}>
          <Checkbox className={styles.checkbox} onClick={()=>handleCheckBoxClick(selected?.length === filterByTagAndStatus(filtered)?.length)} partialChecked={!!selected?.length && selected?.length !== filterByTagAndStatus(filtered)?.length} checked={!!selected?.length && selected?.length === filterByTagAndStatus(filtered)?.length}/>
          <span
            onClick={() => {
              handleFilterChange("status", "asc");
            }}
            className={cn(styles.statusParent, styles.filter, "d-flex align-items-center")}
          >
            Status
            {filterBy[0] === "status" && (
              <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
            )}
          </span>
          <span
            onClick={() => {
              handleFilterChange("name", "asc");
            }}
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Name
            {filterBy[0] === "name" && (
              <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
            )}
          </span>
          <span
            onClick={() => {
              handleFilterChange("company", "asc");
            }}
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Brand Name
            {filterBy[0] === "company" && (
              <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
            )}
          </span>
          <span
            onClick={() => {
              handleFilterChange("date", "asc");
            }}
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Start Date
            {filterBy[0] === "date" && (
              <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
            )}
          </span>
        </div>
        <div className={styles.tableRows}>
          {externalStakeholders.length === 0 && (
            <div className={cn(styles.message, "d-flex align-items-center justify-content-center")}>
              <p className={cn(styles.centerTitle, "title-3 margin-right")}>{String.fromCodePoint("128526")} Get started by creating a</p>
              <Link to="/new_launch" className={cn(styles.centerBtn, "button")}>
                <FeatherIcon icon="user-plus" size={15} className="margin-right--small" />
                New Launch
              </Link>
            </div>
          )}
          {filterByJourney(filterByTagAndStatus(filtered))?.map((externalStakeholder) => (
            <ExternalStakeholderRow
              key={externalStakeholder.id}
              handleArchive={handleArchiveExternalStakeholder}
              externalStakeholder={externalStakeholder}
              sendReminder={sendReminder}
              query={EXTERNAL_STAKEHOLDER_QUERY}
              selected={selected}
              setSelected={setSelected}
              handleUnarchive={handleUnarchive}
              tagWatch={tagWatch}
              handleDelete={handleDeleteExternalStakeholder}
              showing={statusWatch}
              alert={alert}
              setLoading={setLoading}
            />
          ))}
        </div>
      </AppLayout.Body>
    </>
  );
};
