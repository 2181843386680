import styles from './FrameworkBuilder.module.scss';
import { EditableTitle } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { Button } from 'components/FormComponents';
import cn from 'classnames';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { loader } from 'graphql.macro';
import { useState, useEffect } from 'react';
import { CollapsibleGroup } from 'components/CollapsibleBlock/CollapsibleBlock';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import FeatherIcon from 'feather-icons-react';
import { JourneyFrameworkConfig } from '../components/JourneyFrameworkConfig/JourneyFrameworkConfig';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { FrameworkConfig } from '../components/FrameworkConfig/FrameworkConfig';


const FRAMEWORK_QUERY = loader('./../../../../../graphql/queries/framework.graphql')


export const FrameworkBuilder = ({id, brands, journeys, onSave, setLoading, onDelete}) =>{
    const {
        register,
        reset,
        control,
        setValue,
        handleSubmit,
        unregister,
        watch,
        clearErrors,
        setError,
        formState: { errors },
      } = useForm();

    const { fields: journeyFrameworks, append, swap } = useFieldArray({ control, name: 'journeyFrameworksAttributes', keyName: 'key' });

    const [framework, setFramework] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [openSettings, setOpenSettings] = useState(false)

    const watchJourneyFrameworks = watch('journeyFrameworksAttributes');

    const { loading: fetchingFramework } = useQuery(FRAMEWORK_QUERY, {
    variables: {id},
    onCompleted: data => setFramework(data.framework),
    });
    
    const onClose=()=>{
        console.log("Close")
    }

    const addJourney = () => {
      if (journeys.length > 0) {
        const usedJourneys = journeyFrameworks.map((jb) => (jb.journeyId))
        const unusedJourneys = journeys.filter((e) => (!usedJourneys.includes(e.id)))
        append({
          journeyId: unusedJourneys[0].id,
        });
        return
      }
    }

    const onDragEnd = ({ source, destination }) => {
      // if(!source || !destination) return;
  
      // swap(source.index, destination.index);
      // setValue(`journeyBlocksAttributes[${source.index}].order`, source.index + 1);
      // setValue(`journeyBlocksAttributes[${destination.index}].order`, destination.index + 1);
    }

    const handleRemove = (indexToRemove) => {
      let _journeyFrameworks = []
      journeyFrameworks.forEach((journeyFramework,index)=>{
        let _journeyFramework;
        if(index === indexToRemove || journeyFramework._destroy){
          _journeyFramework = {...journeyFramework, _destroy: true}
        }
        else{
          _journeyFramework = journeyFramework
        }
        delete _journeyFramework.key
        if(!_journeyFramework._destroy || _journeyFramework.id) _journeyFrameworks.push(_journeyFramework)
      })
      setValue('journeyFrameworksAttributes', _journeyFrameworks)
    }

    const frameworkName = useWatch({ control, name: "name" });

    const onSubmit = (data) =>{
      delete data.__typename;
      data.journeyFrameworksAttributes?.forEach((jF)=>{
        delete jF.__typename;
      })

      onSave(data)
    }

    const handleBrandUpdate = (data) =>{
      const frameworkAttributes = {id: framework.id, brandId: data?.brandId}
      onSave(frameworkAttributes)
      setOpenSettings(false)
    }
  
    useEffect(()=>{
      setLoading(fetchingFramework)
    },[fetchingFramework])


    useEffect(() => {
      reset(framework);
    }, [framework]);

    return(
    <>
      
      <ConfirmationModal
        title="Delete Framework Configuration"
        subtitle="This Framework will be permanently deleted. This data cannot be recovered"
        visible={openModal}
        onClose={() => {setOpenModal(false)}}
        onConfirmation={()=>{
          onDelete(framework.id)
          setOpenModal(false)
        }}
        buttonTitle={"Delete Framework"}
      />
      <FrameworkConfig
        visible={openSettings}
        brands={brands}
        framework={framework}
        onClose={() => {setOpenSettings(false)}}
        onSubmit={handleBrandUpdate}
        errors={errors}
      />


      <form className={cn(styles.root, "card card-with-border")} onSubmit={handleSubmit(onSubmit)}>
          <div className={cn("card_content")}>
            <header className={styles.header}>
              <EditableTitle register={register} name="name" value={frameworkName} />
              <div className={cn(styles.actions)}>
                <IconButton tip="Framework Settings" icon="settings" onClick={()=>setOpenSettings(true)}  />
                <IconButton tip="Delete Framework" icon="trash-2" onClick={()=>setOpenModal(true)}  />
              </div>
            </header>          
          </div>
          <div className={cn('card_content', 'background-secondary', styles.content)}>
            <CollapsibleGroup newStartOpen={true}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='options'>
                  {({ innerRef, droppableProps, placeholder }) => (
                    <div className='w-100' {...droppableProps} ref={innerRef}>
                      {journeyFrameworks.map((journeyFramework, index) => {
                      return (
                        (!journeyFramework._destroy) &&
                          <JourneyFrameworkConfig
                            index={index}
                            framework={framework}
                            journeyFramework={watchJourneyFrameworks?.[index]}
                            journeys={journeys}
                            control={control}
                            remove={handleRemove}
                            useWatch={useWatch}
                            setValue={setValue}
                            errors={errors}
                            clearErrors={clearErrors}
                            watch={watch}
                            register={register}
                            journeyFrameworkObject={journeyFramework}
                            key={journeyFramework.key}
                          />
                      )})}
                      {placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CollapsibleGroup>
          </div>
          <div className={cn("card_content", "background-secondary", styles.buttonContent)}></div>
          <div className="card_footer d-flex justify-content-between">
            <Button type="secondary" onClick={onClose}>
              Cancel
            </Button>
            <div className={cn(styles.btns,'d-flex justify-content-end')}>
              <Button className={styles.add} disabled={journeyFrameworks.length === journeys.length}  type='secondary' onClick={addJourney}>Add Journey</Button>
              <Button submit>Save</Button>
            </div>
          </div>
        </form>
      </>

    )
}