import { CollapsibleBlock } from '../../../../../../components/CollapsibleBlock/CollapsibleBlock';
import { Select } from '../../../../../../components/FormComponents';
import { IconButton } from '../../../../../../components/IconButton/IconButton';
import { InitialLetterBadge } from '../../../../../../components/InitialLetterBadge/InitialLetterBadge';
import styles from './JourneyFrameworkConfig.module.scss';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ReactSelect } from 'components/FormComponents/ReactSelect/ReactSelect';
import { JourneyBlocks } from './components/JourneyBlocks/JourneyBlocks';

export const JourneyFrameworkConfig = ({
  framework,
  journeyFramework,
  journeys,
  index,
  watch,
  control,
  setValue,
  remove,
  useWatch,
  errors,
  clearErrors,
  journeyFrameworkObject,
  register
}) => {
//   const registerName = `journeyAttributes.${index}`;
//   const block = blocks.find(block => block.id === journeyBlock.blockId)
//   const journeyBlocks = useWatch({control, name: `journeyBlocksAttributes`})
//   const [usedBlocksIds, setUsedBlocksIds] = useState([]);
//   const [isDragDisabled, setIsDragDisabled] = useState(false);
    const registerName = `journeyFrameworksAttributes.${index}`;
    const journey = journeys.find(journey=> journey.id === journeyFramework?.journeyId);

    const journeyFrameworks = useWatch({control, name: `journeyFrameworksAttributes`});
    const [usedJourneysId, setUsedJourneysId] = useState([]);
    const [isDragDisabled, setIsDragDisabled] = useState(false);

    const journeyOptions = journeys?.filter((journey) => !usedJourneysId?.includes(`${journey.id}`))?.map(journey => 
        { return { label: journey.name, value: journey.id} })

    const actionComponent =()=>{
      return(
        <>
          <div className={cn(styles.blockItem, styles.blockBadge, styles.active)}>
            {journey?.journeyBlocks?.length}
          </div>
          <IconButton tip='Delete Journey' className={styles.removeButton} onClick={() => remove(index)} icon='trash-2' />
        </>
      )
    }
    useEffect(()=>{
        setUsedJourneysId(journeyFrameworks?.map((journeyFramework) => (journeyFramework.journeyId)))
    },[journeyFrameworks])

  return (
    <Draggable draggableId={journeyFrameworkObject.key} index={index} isDragDisabled={isDragDisabled}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <CollapsibleBlock headerClassName={styles.headerClassName} className={styles.root} key={index} keyCode={`${index}`}>
            <CollapsibleBlock.Header >
              <div className={cn(styles.blockSelector, 'w-100')} onMouseEnter={() => setIsDragDisabled(true)} onMouseLeave={() => setIsDragDisabled(false)}>
                <Controller
                  name={`${registerName}.journeyId`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <ReactSelect
                      isSearchable
                      options={journeyOptions}
                      value={value}
                      onChange={onChange}
                    />
                  }
                  />
              </div>
              {actionComponent()}

            </CollapsibleBlock.Header>
            <CollapsibleBlock.Content>
              <JourneyBlocks
                journey={journey}
              />

            </CollapsibleBlock.Content>
          </CollapsibleBlock>
        </div>
      )}
    </Draggable>
  )
}
