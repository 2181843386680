import { useState } from 'react';
import { List } from '../../../../components/List/List';
import {  useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect } from 'react';
import _ from 'lodash';
import { stateCompany } from './../../../../graphql/state';
import { useAppContext } from 'templates/AppLayout/AppLayout';
import { Placeholder } from './Placeholder/Placeholder';
import { Modal } from 'components/Modal/Modal';
import { TeamForm } from './components/TeamForm/TeamForm';

const TEAMS_QUERY                      = loader('src/graphql/queries/teams.graphql');
const USERS_QUERY                      = loader('src/graphql/queries/users.graphql');
const ADD_TEAM_MEMBERS_MUTATION          = loader('src/graphql/mutations/add_team_members.graphql');
const REMOVE_TEAM_MEMBERS_MUTATION       = loader('src/graphql/mutations/remove_team_members.graphql');
const CREATE_TEAM_MUTATION             = loader('src/graphql/mutations/create_team.graphql');
const UPDATE_TEAM_MUTATION             = loader('src/graphql/mutations/update_team.graphql');
const DELETE_TEAM_MUTATION             = loader('src/graphql/mutations/delete_team.graphql');

export const Teams = ({ setLoading, alert, error }) => {

  const { triggerUnexpectedError } = useAppContext();
  const [team, setTeam] = useState();
  const [errorModal, setErrorModal] = useState(false)
  const currentCompany = useReactiveVar(stateCompany);

  const [ fetchTeams, { loading: fetchingTeams, data: teamsData }] = useLazyQuery(TEAMS_QUERY, {
    onError: error => triggerUnexpectedError(error.message)
  });


  const [ fetchUsers, { loading: fetchingUsers, data: usersData }] = useLazyQuery(USERS_QUERY, {
    variables: { companyId:currentCompany.id },
    onError: error => triggerUnexpectedError(error.message)
  });

  const [ createTeam, { loading: creatingTeam } ] = useMutation(CREATE_TEAM_MUTATION, {
    onCompleted: async(data) => {
      const newTeam = data.createTeam;
      setTeam(newTeam)
      fetchTeams()
    },
    onError: error => triggerUnexpectedError(error.message)
  });
  const [ updateTeam, { loading: updatingTeam } ] = useMutation(UPDATE_TEAM_MUTATION, {
    onCompleted: async(data) => {
      const updatedTeam = data.updateTeam;
      fetchTeams();
      setTeam(updatedTeam);
      alert('Team successfully updated');
    },
    onError: error => triggerUnexpectedError(error.message)
  })

  const [ addTeamMembers, { loading: addingTeamMembers } ] = useMutation(ADD_TEAM_MEMBERS_MUTATION, {
    onCompleted: async (data) => {
      const updatedTeam = data.addTeamMembers;
      fetchTeams();
      setTeam(null);
      fetchUsers();
      setTeam(updatedTeam);
      alert('Team successfully assigned to user');
    },
    onError: error => triggerUnexpectedError(error.message)
  })
  const [removeTeamMembers, { loading: removingTeamMembers }] = useMutation(REMOVE_TEAM_MEMBERS_MUTATION, {
    onCompleted: async (data) => {
      const updatedTeam = data.removeTeamMembers;
      fetchTeams();
      setTeam(null);
      fetchUsers();
      setTeam(updatedTeam);
      alert('Team Unassigned successfully!');
    },
    onError: error => triggerUnexpectedError(error.message)
  })
  const [ deleteTeam, { loading: deletingTeam } ] = useMutation(DELETE_TEAM_MUTATION, {
    onCompleted: async(data) => {
      fetchTeams();
      setTeam(null);
      alert('Team successfully deleted');
    },
    onError: error => triggerUnexpectedError(error.message)
  });



  const handleNewTeam = () => {
    createTeam({
      variables: {
        companyId: currentCompany.id,
        name: 'new_team',
        color: '#FFFF',
      }
    })
  }

  const handleTeamUpdate = (teamAttributes) => {
    const data = _.omit(teamAttributes,['users', '__typename', 'userId','company'])
    updateTeam({
      variables: data
    })
  }

  const handleAddTeamMembers = (data) => {
    addTeamMembers({
      variables: data
    })
  }
  const handleRemoveTeamMembers = (data) => {
    removeTeamMembers({ variables: {
        teamId: data.teamId,
        userId: data.userId
      } })
  }

  const handleTeamDeletion = () => {
    deleteTeam({ variables: { id: team.id } })
  }



  useEffect(() => {
    setLoading(
      fetchingTeams     ||
      fetchingUsers     ||
      creatingTeam      ||
      updatingTeam      ||
      addingTeamMembers   ||
      removingTeamMembers ||
      deletingTeam
    )
  }, [fetchingTeams,fetchingUsers, creatingTeam, updatingTeam, addingTeamMembers,removingTeamMembers, deletingTeam])

  useEffect(() => {
    fetchTeams()
    fetchUsers()
  }, [])

  return (
    <div className='d-flex justify-around'>
       <Modal
        visible={errorModal}
        title="Can't Delete Teams"
        subtitle="To delete teams, remove them from journey."
        onClose={() => {setErrorModal(false)}}
      />
      <List
        title='Teams'
        subtitle='Choose from a list of existing teams, or go ahead and add a new one.'
        className='margin-right--large'
        onNew={handleNewTeam}
        setErrorModal={setErrorModal}
        handleSelectedDeletion={()=>{}}
      >
        {teamsData && <List.Category title='Existing Teams' withCount>
          {teamsData.teams?.map(team => (
            <List.Item
              key={`team-${team.id}`}
              title={team.name}
              value={team.id}
              onClick={() => setTeam(team)}
              displayCheckbox={false}
            />
          ))}
        </List.Category>}
      </List>
      {team ?
        <TeamForm
          users={usersData?.users}
          team={team}
          onClose={() => setTeam(null)}
          onSave={handleTeamUpdate}
          onAddTeamMembers={handleAddTeamMembers}
          onRemoveTeamMembers={handleRemoveTeamMembers}
          onDelete={handleTeamDeletion}
        /> :
        <Placeholder />
      }
    </div>
  )
}
