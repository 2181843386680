import CreatableSelect from "react-select/creatable";
import cn from 'classnames';
import styles from './ReactCreatable.module.scss'


export const ReactCretable = ({className, field , defaultValue , options, isMulti=true, placeholder}) =>{

    const styledComponent = {
        control: (base) => ({
          ...base,
          border: '1px solid #E6E6E6',
          '&:hover': {
            border: '1px solid #1f4f5c',
          },
          borderRadius: 6,
          backgroundColor: '#f9f9f9',
          boxShadow: 'none',
          cursor: 'pointer',
        }),
        menu: (provided, state) => ({
          ...provided,
          zIndex: '100',
          overflow: 'visible',
          fontSize: '14px',
        //   height: '4rem'
        }),
        singleValue: (provided) => ({
          ...provided,
          marginLeft: '12px',
        }),
        multiValue: (provided) => ({
          ...provided,
          marginLeft: '12px',
        }),
        placeholder: (provided) => ({
          ...provided,
          marginLeft: '12px',
          fontSize: '14px',
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0px',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#333333',
        }),
        clearIndicator: (provided) => ({
          ...provided,
          color: '#333333',
        }),
        input: (provided) => ({
          ...provided,
          fontSize: '14px',
          marginLeft: '11px',
        }),
      }

    return(
        <div className={cn(styles.root, {[className]: className})}>
            <CreatableSelect
            {...field}
            defaultValue={defaultValue}
            options={options}
            isMulti={isMulti}
            styles={styledComponent}
            placeholder={placeholder}
            components={{
                IndicatorSeparator: () => null,
              }}
            />
        </div>
    )
    
}