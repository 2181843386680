import { Card } from "../../StakeholderBlockRecordScreen/components/Card/Card"
import { BlocksProps } from "../../StakeholderBlockRecordScreen/components/Blocks/interfaces/BlocksProps"
import styles from './Blocks.module.scss'
import { useNavigate } from "react-router-dom"

export const Blocks: React.FC<BlocksProps> = ({blocks}) =>{

    const navigate = useNavigate();
    return(
        <div className={styles.block}>
           {
            blocks?.map((block)=>{
                return(
                    <div>
                        <Card onClick={()=>{
                            navigate(`/evidence/${block.blockId}/${block.stakeholderJourneyId}`,{
                                state: {blockName: block.blockName, journeyName: block.journeyName }
                            })
                        }} bottomNameClassName={styles.bottomName} topName={block.journeyName} middleName={block.blockName} bottomName={`${block.types} types • ${block.entries} entries`}/>
                    </div>
                )
            })
           }
        </div>
    )
}