import { useState } from 'react';
import { List } from '../../../../components/List/List';
import {  useLazyQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect } from 'react';
import _ from 'lodash';
import { useAppContext } from 'templates/AppLayout/AppLayout';
import { Placeholder } from './Placeholder/Placeholder';
import { Modal } from 'components/Modal/Modal';
import { RoleForm } from './components/RoleForm/RoleForm';

const ROLES_QUERY                      = loader('src/graphql/queries/roles.graphql');
const USERS_QUERY                      = loader('src/graphql/queries/users.graphql');
const ASSIGN_ROLE_MUTATION             = loader('src/graphql/mutations/assign_role.graphql');
const UNASSIGN_ROLE_MUTATION           = loader('src/graphql/mutations/unassign_role.graphql');
const CREATE_ROLE_MUTATION             = loader('src/graphql/mutations/create_role.graphql');
const UPDATE_ROLE_MUTATION             = loader('src/graphql/mutations/update_role.graphql');
const DELETE_ROLE_MUTATION             = loader('src/graphql/mutations/delete_role.graphql');

export const Roles = ({ setLoading, alert, error }) => {

  const { triggerUnexpectedError } = useAppContext();
  const [role, setRole] = useState();
  const [errorModal, setErrorModal] = useState(false)


  const [ fetchRoles, { loading: fetchingRoles, data: rolesData }] = useLazyQuery(ROLES_QUERY, {
    onError: error => triggerUnexpectedError(error.message)
  });

  const [ fetchUsers, { loading: fetchingUsers, data: usersData }] = useLazyQuery(USERS_QUERY, {
    onError: error => triggerUnexpectedError(error.message)
  });

  const [ createRole, { loading: creatingRole } ] = useMutation(CREATE_ROLE_MUTATION, {
    onCompleted: async(data) => {
      const newRole = data.createRole;
      setRole(newRole)
      fetchRoles()
    },
    onError: error => triggerUnexpectedError(error.message)
  });
  const [ updateRole, { loading: updatingRole } ] = useMutation(UPDATE_ROLE_MUTATION, {
    onCompleted: async(data) => {
      const updatedRole = data.updateRole;
      fetchRoles();
      setRole(updatedRole);
      alert('Role successfully updated');
    },
    onError: error => triggerUnexpectedError(error.message)
  })

  const [ assignRole, { loading: assigningRole } ] = useMutation(ASSIGN_ROLE_MUTATION, {
    onCompleted: async (data) => {
      const updatedRole = data.assignRole;
      fetchRoles();
      setRole(null);
      fetchUsers();
      setRole(updatedRole);
      alert('Role successfully assigned to user');
    },
    onError: error => triggerUnexpectedError(error.message)
  })
  const [unassignRole, { loading: unassigningRole }] = useMutation(UNASSIGN_ROLE_MUTATION, {
    onCompleted: async (data) => {
      const updatedRole = data.unassignRole;
      fetchRoles();
      setRole(null);
      fetchUsers();
      setRole(updatedRole);
      alert('Role Unassigned successfully!');
    },
    onError: error => triggerUnexpectedError(error.message)
  })
  const [ deleteRole, { loading: deletingRole } ] = useMutation(DELETE_ROLE_MUTATION, {
    onCompleted: async(data) => {
      fetchRoles();
      setRole(null);
      alert('Role successfully deleted');
    },
    onError: error => triggerUnexpectedError(error.message)
  });



  const handleNewRole = () => {
    createRole({
      variables: {
        name: 'new_role',
        displayName: 'New Role',
      }
    })
  }

  const handleRoleUpdate = (roleAttributes) => {
    updateRole({
      variables: _.omit(roleAttributes,
        ['users', '__typename', 'userId', 'permissions']
      )
    })
  }

  const handleAssignRole = (data) => {
    assignRole({
      variables: {
        roleId: data.roleId,
        userId: data.userId
      }
    })
  }
  const handleUnassignRole = (data) => {
    unassignRole({ variables: {
        roleId: data.roleId,
        userId: data.userId
      } })
  }

  const handleRoleDeletion = () => {
    deleteRole({ variables: { id: role.id } })
  }



  useEffect(() => {
    setLoading(
      fetchingRoles   ||
      fetchingUsers   ||
      creatingRole    ||
      updatingRole    ||
      assigningRole   ||
      unassigningRole ||
      deletingRole
    )
  }, [fetchingRoles, fetchingUsers, creatingRole, updatingRole, assigningRole,unassigningRole, deletingRole])

  useEffect(() => {
    fetchRoles()
    fetchUsers()
  }, [])

  return (
    <div className='d-flex justify-around'>
       <Modal
        visible={errorModal}
        title="Can't Delete Roles"
        subtitle="To delete roles, remove them from journey."
        onClose={() => {setErrorModal(false)}}
      />
      <List
        title='Roles'
        subtitle='Choose from a list of existing roles, or go ahead and add a new one.'
        className='margin-right--large'
        setErrorModal={setErrorModal}
        handleSelectedDeletion={()=>{}}
      >
        {rolesData && <List.Category title='Existing Roles' withCount>
          {rolesData.roles?.map(role => (
            <List.Item
              key={`role-${role.id}`}
              title={role.displayName}
              value={role.id}
              onClick={() => setRole(role)}
              displayCheckbox={false}
            />
          ))}
        </List.Category>}
      </List>
      {role ?
        <RoleForm
          users={usersData?.users}
          role={role}
          onClose={() => setRole(null)}
          onSave={handleRoleUpdate}
          onRoleAssign={handleAssignRole}
          onRoleUnassign={handleUnassignRole}
          onDelete={handleRoleDeletion}
          fetchUsers={fetchUsers}
        /> :
        <Placeholder />
      }
    </div>
  )
}
