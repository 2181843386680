import { AppLayout, useAppContext } from './../../templates/AppLayout/AppLayout';
import { Tabs, Tab } from '../../components/Tabs/Tabs';
import { NewStakeholderJourneyScreen } from 'views';
import { NewStakeholderFrameworkScreen } from './Tabs/NewStakeholderFrameworkScreen/NewStakeholderFrameworkScreen';

export const LaunchScreen = () => {

  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  return (
    <>
      <AppLayout.Header>
        <AppLayout.Title>Launch</AppLayout.Title>
        <AppLayout.Subtitle>Choose whether you’d like to launch an individual journey or a framework.</AppLayout.Subtitle>
      </AppLayout.Header>
      <AppLayout.Body>
        <Tabs defaultActiveKey='journeys'>
          <Tab eventKey="journeys" title="Journey Library">
            <NewStakeholderJourneyScreen/>
          </Tab>
          <Tab eventKey="frameworks" title="Framework Library">
            <NewStakeholderFrameworkScreen/>
          </Tab>
        </Tabs>
      </AppLayout.Body>
    </>
  )
}