import { useAppContext } from "templates/AppLayout/AppLayout";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";

import { List } from "components/List/List";
import { StakeholderJourneyConfig } from "views/NewStakeholderJourneyScreen/components/StakeholderJourneyConfig/StakeholderJourneyConfig";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NewStakeholderFrameworkScreen.module.scss";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { JourneyCustomizationPreview } from "views/NewStakeholderJourneyScreen/components/JourneyCustomizationPreview/JourneyCustomizationPreview";
import { StakeholderFrameworkConfig } from "./Components/StakeholderFrameworkConfig/StakeholderFrameworkConfig";


const FRAMEWORKS_QUERY = loader("src/graphql/queries/frameworks.graphql");
const FRAMEWORK_QUERY = loader("src/graphql/queries/framework_detailed.graphql");              

const STAKEHOLDER_QUERY = loader("src/graphql/queries/internal_stakeholders.graphql");
const CREATE_STAKEHOLDER_JOURNEY_MUTATION = loader("src/graphql/mutations/create_stakeholder_journey.graphql");
const CREATE_NEW_BULK_STAKEHOLER_JOURNEY_MUTATION = loader("src/graphql/mutations/create_new_bulk_stakeholders_journey.graphql");

const CREATE_STAKEHOLDER_FRAMEWORK_MUTATION = loader("src/graphql/mutations/create_stakeholder_framework.graphql");

export const NewStakeholderFrameworkScreen = ({}) => {
  const navigate = useNavigate();

  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  const [frameworks, setFrameworks] = useState([]);
  const [framework, setFramework] = useState();
  const [internalStakeholders, setInternalStakeholders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [fetchId, setFetchId] = useState();
  const [step, setStep] = useState(1);
  const [openSettings, setOpenSettings] = useState(false);

  const { loading: fetchingFrameworks } = useQuery(FRAMEWORKS_QUERY, {
    onCompleted: (data) => setFrameworks(data.frameworks),
  });

  const [fetchFramework, { loading: fetchingFramework }] = useLazyQuery(FRAMEWORK_QUERY, {
    onCompleted: (data) => {
      setFramework(data.frameworkDetailed);
    },
  });

  const { loading: fetchingStakeholders } = useQuery(STAKEHOLDER_QUERY, {
    onCompleted: (data) => {
      setInternalStakeholders(data.internalStakeholders);
    },
  });

  const [createStakeholderFramework, { loading: creating }] = useMutation(CREATE_STAKEHOLDER_FRAMEWORK_MUTATION, {
    onCompleted: async (data) => {
      navigate("/");
    },
    onError: (error) => {
      triggerUnexpectedError();
    },
  });

  const [createNewBulkStakeholderJourney, { loading: creatingNewBulkJourney }] = useMutation(CREATE_NEW_BULK_STAKEHOLER_JOURNEY_MUTATION, {
    onCompleted: async (_) => {
      navigate("/");
    },
    onError: (error) => {
      triggerUnexpectedError(error?.message);
    },
  });

  useEffect(() => {
    setLoading(fetchingFrameworks || fetchingFramework || fetchingStakeholders || creating || creatingNewBulkJourney);
  }, [fetchingFrameworks, fetchingFramework, fetchingStakeholders, creating, creatingNewBulkJourney]);

  const handleCreate =(data)=>{
    delete data.formType;
    delete data.formTypecustomActions
    if(data?.externalStakeholderIds){
      const ids = data?.externalStakeholderIds?.map((e)=>e.id)
      data.externalStakeholderIds = ids
    }

    createStakeholderFramework({variables: {stakeholdersFrameworkAttributes: data}})
  }

  const handleJourneySelection = () => {
    setFramework(null);
    setStep(1);
    fetchFramework({ variables: { id: fetchId } });
    setOpenModal(false);
  };

  const handleClick = (id) => {
    if (id === framework?.id) return;
    if (framework && step === 1) {
      return fetchFramework({ variables: { id: id } });
    }
    if (framework) {
      setFetchId(id);
      setOpenModal(true);
      return;
    }

    fetchFramework({ variables: { id: id } });
  };

  return (
    <>
        <div className="d-flex justify-content-center align-items-start w-100">
          <ConfirmationModal
            visible={openModal}
            title="Your journey information will be lost"
            subtitle="Are you sure you want to continue?"
            confirmationIcon="trash-2"
            onClose={() => {
              setOpenModal(false);
            }}
            onConfirmation={handleJourneySelection}
            buttonTitle="Yes"
          />
          <List
            title="Frameworks"
            subtitle="Choose from a list of existing frameworks, or go ahead and add a new one."
            className="margin-right--large height-100"
          >
            <List.Category title="KnowMy Frameworks">
              {frameworks.map((framework) => (
                <List.Item
                  key={framework.id}
                  title={`${framework.name}`}
                  value={framework.id}
                  onClick={() => {
                    handleClick(framework.id);
                  }}
                />
              ))}
            </List.Category>
          </List>
          <div className={styles.journey}>
            {framework && (
              <StakeholderFrameworkConfig
                framework={framework}
                internalStakeholders={internalStakeholders}
                onSubmit={handleCreate}
                onClose={() => setFramework(null)}
                error={triggerUnexpectedError}
                setParentStep={setStep}
                setOpenSettings={setOpenSettings}
                triggerUnexpectedError={triggerUnexpectedError}
                setLoading={setLoading}
              />
            )}
          </div>
        </div>
    </>
  );
};
