import styles from './RemindersList.module.scss';
import cn from 'classnames';
import { useNavigate } from "react-router-dom";
import { DiaryList } from './components/DiaryList/DiaryList';

export const RemindersList = ({ tags, diaries, setLoading, overdueDiaries, upcomingDiaries}) =>{

    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            {diaries?.map((block, index)=>{
                return (<div className={cn(styles.card, "w-100")}>
                    <DiaryList
                      block={block?.block}
                      index={index}
                      setLoading={setLoading}
                      entries={block?.entries}
                      tags={tags}
                      stakeholderJourneyId={block.stakeholderJourneyId}
                      {...block}
                      onNavigate={() => navigate(`/journey/${block.token}/block/${block.journeyBlockId}`, {
                        state: { referrer: window.location.pathname } // Pass the referrer as state
                      })}
                    />
                </div>)
            })}
        </div>
    )
}