import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { Tabs,Tab } from "components/Tabs/Tabs";
import styles from './StakeholderTasksListScreen.module.scss'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { RemindersList } from "./Tabs/RemindersList/RemindersList";

const DIARIES_QUERY = loader("src/graphql/queries/stakeholder_diaries_with_entries.graphql");


export const EnsureStakeholderTasksListScreen = () =>{

    const [diaries , setDiaries] = useState([])
    const [tags, setTags] = useState([])
    const {setLoading} = useEnsureStakeholderLayoutContext()

    const { loading: fetchingDiaries } = useQuery(DIARIES_QUERY, {
        onCompleted: data => {
            setDiaries(data.stakeholderDiariesWithEntries?.stakeholderJourneys)
            setTags(data.stakeholderDiariesWithEntries.tags)
        }
    });
   
    useEffect(()=>{
        setLoading(fetchingDiaries)
    },[fetchingDiaries])



    const navigate = useNavigate();

    const [isOpen , setIsOpen] = useState(true)

    const onClose=()=>{
        navigate('/ensure_dashboard')
    }

    const separateStakeholderBlocks=(stakeholderJourneys)=>{
        let overdueDiaries = [];
        let upcomingDiaries = [];
        let allDiaries = [];
        let openDiaries = [];

        stakeholderJourneys?.forEach(journey => {
            const token = journey.token;
            const stakeholderJourneyId = journey.id
            journey?.stakeholderBlocks?.forEach(block => {
                const blockWithToken = { ...block, token };
                const blockWithJourneyId = {...blockWithToken, stakeholderJourneyId}
                if(block?.block?.options?.reminders === 'open' || !block?.block?.options?.reminders ){
                    openDiaries.push(blockWithJourneyId)
                }else if (block.overdue) {
                    overdueDiaries.push(blockWithJourneyId);
                } else {
                    upcomingDiaries.push(blockWithJourneyId);
                }
                allDiaries.push(blockWithJourneyId)
            });
        });

        
        return {
            overdueDiaries,
            upcomingDiaries,
            allDiaries,
            openDiaries
        };
      }

    const {upcomingDiaries, overdueDiaries , allDiaries , openDiaries} = separateStakeholderBlocks(diaries)
    
    return(
        <>
            <EnsureStakeholderLayout.Header>
                <EnsureStakeholderLayout.Title>Task List</EnsureStakeholderLayout.Title>
                <EnsureStakeholderLayout.Subtitle>Below is your summary of tasks to complete.</EnsureStakeholderLayout.Subtitle>
            </EnsureStakeholderLayout.Header>
            <EnsureStakeholderLayout.Body>
                <Tabs defaultActiveKey="all_diaries" maxWidthContentClassName={styles.maxWidthContent} contentStyles={styles.tabsContent} activeTabColour={styles.activeTabColor} navItemStyles={styles.tab}>
                    <Tab eventKey="all_diaries" title="All Diaries">
                        <div className={styles.container}>
                            <RemindersList tags={tags} overdueDiaries={overdueDiaries} upcomingDiaries={upcomingDiaries} setLoading={setLoading} diaries={allDiaries}/>
                        </div>
                    </Tab>
                    <Tab eventKey="open" title={`Open (${openDiaries?.length})`}>
                        <div>
                            <RemindersList tags={tags} setLoading={setLoading} diaries={openDiaries}/>
                        </div>
                    </Tab>
                    <Tab eventKey="upcoming" title={`Upcoming (${upcomingDiaries?.length})`}>
                        <div>
                            <RemindersList tags={tags} setLoading={setLoading} diaries={upcomingDiaries}/>
                        </div>
                    </Tab>
                    <Tab eventKey="overdue" title={`Overdue (${overdueDiaries?.length})`}>
                        <div>
                            <RemindersList tags={tags} setLoading={setLoading} diaries={overdueDiaries}/>
                        </div>
                    </Tab>
                </Tabs>
            </EnsureStakeholderLayout.Body>
        </>
    )
}