import FeatherIcon from "feather-icons-react";
import cn from "classnames";
import { Button } from "components/FormComponents";
import styles from "./Result.module.scss";
import { useState } from "react";
import { IconButton } from "components/IconButton/IconButton";
import { Collapse } from "react-bootstrap";
import { Tabs, Tab } from "components/Tabs/Tabs";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { Loader } from "components/Loader/Loader";
import { useLazyQuery } from "@apollo/client";
import { saveAs } from "file-saver";
import { useAppContext } from "templates/AppLayout/AppLayout";
import { List } from "./List/List";
import { base64Decoder, downloadZip } from "utils/helpers";
import { consolidatedCsvs } from "utils/helpers";
import { useEffect } from "react";

const DELETE_BLOCK_RESULT_MUTATION = loader("src/graphql/mutations/delete_block_result.graphql");
const MULTIPLE_BLOCK_RESULT_QUERY = loader("src/graphql/queries/multiple_block_results.graphql")
const RESET_RESULT = loader("src/graphql/mutations/reset_block_result.graphql");

export const Result = ({ blockResults=[], title, icon, refetchStakeholder, tabTitle, stakeholderName, journeyBlocks=[], internalStakeholders=[], isOptional=false , optionalDocuments=[], setStakeholder, isBundle=false}) => {
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loader, setLoader] = useState(false);
  
  const {alert, setLoading, triggerUnexpectedError } = useAppContext();

  const [resetResult, { loading: resetting }] = useMutation(RESET_RESULT, {
    onCompleted: (data) => {
      refetchStakeholder()
      alert('Block was successfully reseted.')
    },
    onError: (error) => {
      triggerUnexpectedError(error?.message)
    }
  })

  useEffect(()=>{
    setLoading(resetting)
  },[resetting])

  const handleReset = (result) => {
    resetResult({variables:{blockResultId: result?.id}})
  }

  const getCsvs = async (blockResults) =>{
    const {data} = await fetchBlockResult({variables: {blockResultIds: blockResults.map((v)=>v.id)}})
    data.multipleBlockResults.forEach((result)=>{
      const blob = consolidatedCsvs([result.result])
      saveAs(blob,`${`${result.blockName}-${stakeholderName}`.replace(/ /g, "_")}.csv`)
    })
  }

  const [fetchBlockResult, { loading: fetchingBlockResult }] = useLazyQuery(MULTIPLE_BLOCK_RESULT_QUERY, {
    onCompleted: (data) => {
    },
    onError: (error) => {
      triggerUnexpectedError();
      setLoading(false);
    },
  });

  const [deleteBlockResult, { loading: deleting }] = useMutation(DELETE_BLOCK_RESULT_MUTATION, {
    onCompleted: async (data) => {
      setLoader(false);
      refetchStakeholder();
    },
  });


  const checkDataAvailable = () => {
    if (!blockResults.length && !optionalDocuments?.length) {
      return (
        <div className="text-center">
          <h2>No Data Available</h2>
        </div>
      );
    }
  };

  const handleBlockResultDeletion = (id) => {
    setLoader(true);
    deleteBlockResult({ variables: { id: id } });
    setOpenModal(false);
    setDeleteId(null);
  };

  const bulkDownload = () => {
    const results = [];
    const blobArray=[]
    const pdfNames=[]
    var isQuestionnaire = false;
    blockResults.forEach((result) => {
      if (['questionnaire','quiz'].includes(result.block.typeOf )) {
        isQuestionnaire = true;
        results.push({id: parseInt(result.id)});
      } else if (result.block.typeOf === "document" && result.resultUrl) {
        pdfNames.push(result?.block?.name)
        const view = base64Decoder(result?.resultUrl.replace("data:application/pdf;base64, ",""))
        const blob = new Blob( [view], { type: "application/pdf" });
        blobArray.push(blob)
      }
    });
    isQuestionnaire ? getCsvs(results) : downloadZip(blobArray,pdfNames,stakeholderName)
  };

  return (
    <>
      <div className={cn(styles.root, "w-100 card card-with-border")}>
        <div className={cn(styles.header, "d-flex align-items-center justify-content-between")}>
          <h1 className={cn(styles.title, "title-3 d-flex align-items-center")}>
            {icon && <FeatherIcon className={cn("me-3")} icon={icon} />}
            {title}
          </h1>
          {!isBundle && !!blockResults?.length && <Button
              type="secondary"
              className={cn(styles.downloadBtn) + " me-3"}
              onClick={() => {
                bulkDownload();
              }}
            >
              <FeatherIcon className={cn("me-3")} icon="download" size={18} />
              Download
            </Button>
          }
          <IconButton className={cn(styles.toggleButton)} icon={`chevron-${open ? "up" : "down"}`} onClick={() => setOpen(!open)} />
        </div>
        <div className={cn(styles.body)}>
          <Collapse in={open}>
            <Tabs defaultActiveKey="documents" contentStyles={styles.tabsContent}>
              {checkDataAvailable()}
              <Tab className={cn(styles.tab)} eventKey="documents" title={`${tabTitle} (${blockResults?.length || optionalDocuments?.length})`}>
                <List
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  setDeleteId={setDeleteId}
                  getCsv={getCsvs}
                  allBlockResults={blockResults}
                  journeyBlocks={journeyBlocks}
                  internalStakeholders={internalStakeholders}
                  optionalDocuments={optionalDocuments}
                  handleReset={handleReset}
                />
              </Tab>
            </Tabs>
          </Collapse>
        </div>
      </div>
      <ConfirmationModal
        visible={openModal}
        title="Delete Block Confirmation"
        subtitle="This Block will be permanently deleted. This data cannot be recovered"
        confirmationKey={"Yes"}
        confirmationIcon="trash-2"
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirmation={() => handleBlockResultDeletion(deleteId)}
        buttonTitle="Delete Block"
      />
      {loader && <Loader />}
    </>
  );
};
