//@ts-nocheck
import cn from 'classnames';
import styles from './RecordEntries.module.scss'
import { useQuery, useLazyQuery } from '@apollo/client';
import { RecordEntriesProps } from './interfaces/RecordEntriesProps';
import { loader } from 'graphql.macro';
import { useState, useEffect } from 'react';
import { CollapsibleBlock } from 'components/CollapsibleBlock/CollapsibleBlock';
import { Button } from 'components/FormComponents';
import moment from 'moment';
import { IconButton } from 'components/IconButton/IconButton';
import { Collapse } from 'react-bootstrap';
import { downloadBase64AsZip } from 'utils/helpers';

const FIELDS_WITH_ENTRIES = loader("src/graphql/queries/fields_with_entries.graphql")
const DOWNLOAD_ANSWERS = loader("src/graphql/queries/answer_uploads_as_base64.graphql")

export const RecordEntries: React.FC<RecordEntriesProps> = ({blockId, stakeholderJourneyId, setLoading, types, blockName}) =>{

    const [fields, setFields] = useState([])
    const [openIndexes, setOpenIndexes] = useState({});
    const [fileName, setFileName] = useState(null)
    
    const toggleCollapse = (index) => {
        setOpenIndexes((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const { loading: fetching } = useQuery(FIELDS_WITH_ENTRIES, {
        variables: { stakeholderJourneyId: stakeholderJourneyId, fieldType: 'record', blockId: blockId },
        onCompleted: (data) => {
            setFields(data?.fieldsWithEntries)
        }
      });
    
    const [download, { loading: downloading }] = useLazyQuery(DOWNLOAD_ANSWERS, {
        onCompleted: data => {
            const uploads = data?.answerUploadsAsBase64
            if(uploads?.length){
                downloadBase64AsZip(uploads, fileName)
            }
        }
    });

    useEffect(()=>{
        setLoading(fetching || downloading)
    },[fetching, downloading])
     

    return (
        <div className={styles.root}>
            <div className={cn('card', 'card-with-border', styles.cardWithBorder)}>
                <div className={cn(styles.header, 'card_content w-100')}>
                    <div className={styles.titles}>
                        <div className={styles.blockName}>
                            {blockName}
                        </div>
                        <div className={styles.types}>
                            {types} Types
                        </div>
                    </div>
                    <Button onClick={()=>{
                        setFileName(blockName)
                        const ids = fields?.map(f=> f?.answers?.map(a=> a.id)).flat()
                        download({variables:{answerIds: ids}})
                    }} className={styles.button} icon='download' type='secondary'>Download</Button>
                </div>
            </div>

        {fields?.map((field,index)=>{
                const lastEntry = field?.answers?.slice(-1)[0];
                const formattedLastEntry = moment(lastEntry?.createdAt).format("DD/MM/YYYY @ h:mma");
                return(
                    <CollapsibleBlock headerClassName={cn(styles.blockHeader, styles.borders)} className={cn(styles.collapse)} keyCode={`${index}`}>
                        <CollapsibleBlock.Header>
                            <div className={styles.asd}>
                                <div className={styles.fieldData}>
                                    <div className={styles.info}>
                                        <div className={styles.text}>
                                            {field?.text}
                                        </div>
                                        <div className={styles.date}>{field?.answers?.length} entries {`${field?.answers?.length ? `• Last added ${formattedLastEntry}` : ``}`} </div>
                                    </div>
                                    <Button onClick={()=>{
                                        setFileName(field?.text)
                                        download({variables:{answerIds: field?.answers?.map(a=>a.id)}})
                                    }}
                                     className={styles.button} icon='download' type='secondary'>Download</Button>
                                </div>
                            </div>
                        </CollapsibleBlock.Header>
                        <CollapsibleBlock.Content>
                            <div className={cn(styles.collapseContent,{[styles.borders]: !!field?.answers?.length})}>
                                {field?.answers?.map((answer,answerIndex)=>{
                                    const date =  moment(answer?.createdAt).format("DD/MM/YYYY @ h:mma");
                                    const contentType = new URL(answer?.upload?.url).searchParams.get('response-content-type');
                                    const isImage = contentType.includes('image')
                                    return(
                                        <div className={cn('card', 'card_content', styles.entries)}>
                                            <div className={styles.entryHeader}>
                                                <div className={styles.texts}>
                                                    <div className={styles.answerDate}>
                                                        Added {date}
                                                    </div>
                                                    <div className={styles.answerText}>
                                                         {answer?.value?.text}
                                                    </div>
                                                </div>
                                                <div>
                                                    <IconButton
                                                        onClick={() => toggleCollapse(answerIndex)}
                                                        icon={openIndexes[answerIndex] ? "chevron-up" : "chevron-down"}
                                                    />                                               
                                                </div>
                                            </div>
                                            <Collapse in={openIndexes[answerIndex]}>
                                                <div className={styles.upload}>
                                                    <div className={styles.iframeContainer}>
                                                        { isImage
                                                        ?
                                                            <img scrolling="no" seamless="seamless" className={styles.iframe} src={`${answer?.upload?.url}`}/>
                                                        :  
                                                            <iframe scrolling="no" seamless="seamless" className={styles.iframe} src={`${answer?.upload?.url}`}/>
                                                        }
                                                    </div>
                                                    <div className={styles.fileContainer}>
                                                        <div className={styles.text}> {answer?.upload?.filename}</div>
                                                    </div>
                                                </div> 
                                            </Collapse>
                                        </div>
                                    )
                                })}
                            </div>
                        </CollapsibleBlock.Content>
                    </CollapsibleBlock>
                )
            })}
        </div>

    )
}