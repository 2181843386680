import { useState } from 'react';
import styles from './StakeholderJourneyWelcomeScreen.module.scss';
import { StakeholderJourneyLayout, useStakeholderJourneyLayoutContext } from "../../../templates/StakeholderJourneyLayout/StakeholderJourneyLayout";
import { CustomButton } from '../components/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { MediaType } from 'constants/media-type-enum';
import { VideoWidget } from 'components/VideoWidget/VideoWidget';
import cn from 'classnames';
import { useVariableText } from 'hooks/variable-text';
import { useEffect } from 'react';
import defaultLogo from '../../../assets/images/ensure.png'
import Vimeo from '@u-wave/react-vimeo';
import { LoomSettings } from 'views/BlockBuilderScreen/tabs/Blocks/BlockBuilder/components/InformationBuilder/components/InfoItemConfig/settings_components/LoomSettings/LoomSettings';
import { VidyardSettings } from 'views/BlockBuilderScreen/tabs/Blocks/BlockBuilder/components/InformationBuilder/components/InfoItemConfig/settings_components/VidyardSettings/VidyardSettings';

export const StakeholderJourneyWelcomeScreen = ({}) => {

  const { stakeholderJourney, token } = useStakeholderJourneyLayoutContext();
  const [heading, setHeading] = useState('Hi {{stakeholder_name}}, welcome to your {{company_name}} Onboarding Journey 😎');
  const [subHeading, setSubHeading] = useState("Click the “Get Started” button to begin your journey. There are a series of blocks we’ll need you to complete.")
  const removed = 'Sorry, You Have Been Removed From the Journey.'

  const navigate = useNavigate();

  useEffect(() => {
    if(stakeholderJourney?.name){
      if (stakeholderJourney?.customization?.useWelcomeScreenHeadings) {
        setHeading(stakeholderJourney.customization.welcomeScreenHeading);
        setSubHeading(stakeholderJourney.customization.welcomeScreenSubHeading);
      }

      if (stakeholderJourney?.internalStakeholder) {
        setHeading("Hi {{internal_stakeholder_name}}, can you help us complete {{internal_stakeholder_full_name}}'s journey?");
        setSubHeading("In order to complete {{internal_stakeholder_full_name}}'s journey, we need you to provide some information. Click “Get Started” to see what you have to complete for {{internal_stakeholder_full_name}}")
      }
    }
  }, [stakeholderJourney])

  const logo = stakeholderJourney?.brand?.logo?.url || defaultLogo;

  const headingVariables = useVariableText(heading, stakeholderJourney)
  const subHeadingVariables = useVariableText(subHeading, stakeholderJourney)

  return (
    <StakeholderJourneyLayout.Content withBrandBackgroundColor withFooter className={styles.root}>
      <div className={styles.heading}>
        <h1 className={styles.title} style={{ color: `${stakeholderJourney?.brand?.textColor}` }}>{stakeholderJourney?.name ?  headingVariables : removed}</h1>
        <img
          className={cn(styles.logo)}
          src={logo}
          alt='Brand logo'
        />
      </div>
      <p style={{ color: `${stakeholderJourney?.brand?.textColor}` }} className={cn(styles.subtitle, 't-subtitle')}>{stakeholderJourney?.name && subHeadingVariables}</p>
      {(stakeholderJourney?.customization?.useMedia) &&
      <div className={cn('margin-y--large', 'w-100', styles.mediaWrapper)}>
        {stakeholderJourney.customization.mediaType === MediaType.Video &&
          <VideoWidget className={styles.video} src={stakeholderJourney.customization.videoSrc} mute={false} />
        }
        {stakeholderJourney.customization.mediaType === MediaType.Image &&
          <div className={styles.image}><img src={stakeholderJourney.customization.image.url} /></div>
        }
        {stakeholderJourney.customization.mediaType === MediaType.VimeoVideo &&
          <Vimeo style={{width: '100%', height: '100%'}}  video={stakeholderJourney.customization.videoSrc} responsive/> 
        }
        {stakeholderJourney.customization.mediaType === MediaType.LoomVideo &&
          <LoomSettings loomVideo={stakeholderJourney.customization.videoSrc}/>
        } 
        {stakeholderJourney.customization.mediaType === MediaType.VidyardVideo &&
          <VidyardSettings vidyardVideo={stakeholderJourney.customization.videoSrc}/>
        } 
      </div>
      }
      {stakeholderJourney?.name &&
      <CustomButton className={cn(styles.button, 'margin-top--large')} brand={stakeholderJourney?.brand} onClick={() => navigate(`/journey/${token}/get_started`)}>Get Started</CustomButton>
}
    </StakeholderJourneyLayout.Content>
  )
}
