/* eslint-disable default-case */
import { Badge } from "react-bootstrap";
import cn from "classnames";
import styles from "./QuestionnaireForm.module.scss";
import {
  InputGroup,
  Input,
  OpinionScale,
  MultipleChoice,
  DatePicker,
  PhoneNumberPicker,
  StarRating,
  FilePicker,
} from "../../../../components/FormComponents";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useEffect, forwardRef, useImperativeHandle, useState } from "react";
import { FieldTypes } from "constants/field-types-enum";
import { CustomButton } from "views/StakeholderJourneyScreens/components/CustomButton/CustomButton";
import { Signature } from "components/Signature/Signature";
import { VideoWidget } from "components/VideoWidget/VideoWidget";
import { MobilePdfViewer } from "./MobilePdfViewer/MobilePdfViewer";
import { CaptureMobileCamera } from "./components/CaptureMobileCamera/CaptureMobileCamera";
import { useStakeholderJourneyLayoutContext } from "templates/StakeholderJourneyLayout/StakeholderJourneyLayout";
import {convertsLinksToClickText, formatQuestionnaireFields } from "utils/helpers";
import moment from "moment";
import { Select } from "../../../../components/FormComponents";
import { ReactCretable } from "components/FormComponents/ReactCreatable/ReactCreatable";

export const QuestionnaireForm = forwardRef(
  ({ otherBlocks, answerTags, required, block, result, stakeholderJourneyId, onSubmit = () => {}, onSubmitCta = () => {}, stakeholderName }, ref) => {
    const {
      watch,
      register,
      reset,
      control,
      handleSubmit,
      clearErrors,
      unregister,
      setError,
      formState: { errors },
    } = useForm();
    const { remove, fields: answers } = useFieldArray({ control, name: "answersAttributes" });
    let counter = 1;
    const [fieldsText, setFieldsText] = useState([])
    const [originalFields , setOriginalFields] = useState([])
    const [filteredFields, setFilteredFields] = useState(block.fields)
    const [uniqueTags, setUniqueTags] = useState()

    const answeredfields = result?.answeredFields ? result?.answeredFields.split("/")[0] : result?.answersAttributes?.length;

    const { token } = useStakeholderJourneyLayoutContext();

    const statements = block?.fields?.filter((t) => t?.typeOf === "statement");
    const totalfields = block?.fields.filter((t) => t?.typeOf !== "statement");

    const displayAnsweredfields = parseInt(answeredfields) - parseInt(statements.length);
    const [chooseMobile, setChooseMobile] = useState(new Array(answers?.length).fill(false));
    const tagWatch = watch("tag");
    useImperativeHandle(ref, () => ({
      submit: () => {
        const list = errors?.fieldsAttributes?.map((field) => field);
        const realErrors = list?.filter((e) => e !== undefined).length;
        if (realErrors === 0) {
          clearErrors();
        }
        handleSubmit(onSubmit)();
      },
      submitCta: () => {
        handleSubmit(onSubmitCta)();
      },
    }));

    useEffect(()=>{
      if (tagWatch==='empty'){
        setFilteredFields(block.fields)
      }else{
        const filteredFields = block.fields.filter(field => field.options.tag === tagWatch);
        setFilteredFields(filteredFields)
      }
    },[tagWatch])
    
    useEffect(() => {
      setFilteredFields(block.fields)
      const uniqueTags = [...new Set(block.fields.flatMap(field => field.options.tag || []))];
      setUniqueTags(uniqueTags)
    }, []);

    // Initialize form
    useEffect(() => {
      let attributes = {
        blockId: block.id,
        stakeholderJourneyId: stakeholderJourneyId,
        answersAttributes: [],
      };

      if (result) attributes.id = result.id;

      block.fields.forEach((field) => {
        const answer = result?.answersAttributes.find((answer) => answer.fieldId === field.id);
        if (answer) {
          attributes.answersAttributes.push(answer);
        } else {
          attributes.answersAttributes.push({ fieldId: field.id, value: {} });
        }
      });

      reset(attributes);
    }, [block, result]);

    const formValues = watch();

    const setText = (fieldToChange) =>{
      var text = fieldToChange?.text
      const numbersArray = text?.match(/\{{[^{}]+_(\d+)}}/g)?.map(match => match.match(/_(\d+)}}/)[1]);
      numbersArray?.forEach(number => {
        const regex = `{{${block?.shortCode}_${number}}}`;
        const answerToSet = watchAnswerAttributes[number];
        if (answerToSet?.value?.text || answerToSet?.value?.number) {
          text = text.replace(regex, answerToSet?.value?.text || answerToSet?.value?.number);
        }
      });
      text = formatQuestionnaireFields(text, otherBlocks)
      return text;
    }

    const watchAnswerAttributes = watch('answersAttributes');

    useEffect(()=>{
      setFieldsText([])
      setOriginalFields([])
    },[block])

    useEffect(() => {
      if(!originalFields?.length) setOriginalFields(answers?.map(answer => block?.fields.find(field => field.id === answer.fieldId)))
      if(result.status === 'completed') onMouseLeave()
      answers.forEach((answer)=>{
        const field =  block?.fields.find(field => field.id === answer.fieldId)
        if(!fieldsText?.length){
          setFieldsText((prevFieldsText) => {
            const toPush = [...prevFieldsText, field];
            return toPush;
          });
        }
      })
    }, [answers, block.fields, originalFields]);

    useEffect(() => {
      const list = errors?.answersAttributes?.map((field) => field);
      const realErrors = list?.filter((e) => e !== undefined).length;
      let customErrors = 0
      errors?.answersAttributes?.forEach((answer)=>{
        customErrors = answer?.value?.uploadId?.type === "custom" ? customErrors +1 : customErrors
      })
      if (realErrors === 0 && customErrors === 0) {
        clearErrors();
      }
    }, [errors, formValues]);

    const handleBadge = (blockResult) => {
      return blockResult.status === "completed" ? styles.active : styles.inactive;
    };

    const onMouseLeave = (field) =>{
      originalFields?.forEach((f,index)=>{
          var text = setText(f);
          setFieldsText((prevObjects)=>{
            const newArray = [...prevObjects]
            newArray[index] ={
              ...newArray[index],
              text: text,
            };
            return newArray
          })
      })
    }

    const compareDates = (value, logicValue, operator) => {
      const valueDate = moment(value, "DD/MM/YYYY");
      const logicDate = moment(logicValue, "DD/MM/YYYY");

      switch (operator) {
        case 'lower_than':
          return valueDate.isBefore(logicDate);
        case 'greater_than':
          return valueDate.isAfter(logicDate);
        case 'lower_equal_than':
          return valueDate.isSameOrBefore(logicDate);
        case 'greater_equal_than':
          return valueDate.isSameOrAfter(logicDate);
        default:
          return false;
      }
    };
    
    const meetsLogic = (logic, value, fieldType) => {
      if(!value) return false;

      if (fieldType === 'date') {
        return compareDates(value, logic.value, logic.operator);
      }
      
      switch(logic.operator) {
        case 'is': 
          return value === logic.value;
        case 'is_not': 
          return value !== logic.value;
        case 'lower_than':
          return value < logic.value;
        case 'lower_equal_than':
          return value <= logic.value;
        case 'greater_than':
          return value > logic.value;
        case 'greater_equal_than':
          return value >= logic.value;
      }
    }

    const logicJumps = block.blockLogicJumps

    const fieldOrdersToSkip = []
    const hiddenBranches = []
    logicJumps?.forEach((jump) => {
      const jumpField = block.fields.find((field)=>field.order === jump.field)
      const jumpFieldAnswer = watchAnswerAttributes?.find((answer)=> answer.fieldId === jumpField.id)
      if (meetsLogic(jump, jumpField?.typeOf === 'yes_no_evidence' ? jumpFieldAnswer?.value?.options?.[0] : jumpFieldAnswer?.value?.text || jumpFieldAnswer?.value?.number , jumpField?.typeOf)){
        for(let i = jump.from +1 ; i<jump.to; i++){
          fieldOrdersToSkip.push(i)
        }
      }
      if (!jumpFieldAnswer?.value?.options?.[0] && !jumpFieldAnswer?.value?.text && !jumpFieldAnswer?.value?.number ){
        for(let i = jump.from +1 ; i<jump.to; i++){
          hiddenBranches.push(i)
        }
      }
    })

    const setField = (field) => {
      var text = field?.text;
      text = convertsLinksToClickText(text)
      const numbersArray = text?.match(/\{{[^{}]+_(\d+)}}/g)?.map(match => match.match(/_(\d+)}}/)[1]);
      numbersArray?.forEach(number => {
        var regex = new RegExp(`\\{{${block?.shortCode}_${number}\\}}`, "g");
        text = text.replace(regex, '');
    });
      text = formatQuestionnaireFields(text, otherBlocks);
      return field?.typeOf !== 'statement' ?
       `${counter++}. ${text}${field?.options?.required ? " *" : ""}` : text
    };

    const activeBadge = handleBadge(result);
    const { innerWidth: width } = window;

    return (
      <div className={cn(styles.root, "card", "card-with-border")}>
        <div className={cn("card_content", styles.content)}>
          <Badge className={cn(styles.badge, { ["badge--default"]: !required, ["badge--info"]: required })}>
            {required ? "Required" : "Nice to do"}
          </Badge>
          <h1 className={cn(styles.title, "title-2")}>{block.heading}</h1>
          <div className={cn(styles.blockItem, styles.blockBadge, activeBadge)}>{`${
            displayAnsweredfields < 0 ? 0 : displayAnsweredfields
          }/${totalfields.length}`}</div>
          {!!uniqueTags?.length && <div className='d-flex justify-content-center align-items-center w-100'>
            <p>Select a Tag to filter fields</p>
            <Select register={register} name={`tag`} placeholder="Select Tag" className={cn(styles.tag, "ms-3 me-3")}>
              <Select.Item value="empty" active={tagWatch === ""}>
                All Fields
              </Select.Item>
              {uniqueTags?.map((tag, index) => (
                <Select.Item key={index} value={tag} active={tagWatch === tag}>
                  {tag}
                </Select.Item>
              ))}
            </Select>
          </div>}
          <form className={cn(styles.form, "margin-top--large margin-bottom-0")}>
            {answers.map((answer, index) => {
              const field = filteredFields.find((field) => field.id === answer.fieldId);
              if(!field){
                return
              }
              const description = convertsLinksToClickText(field?.options?.description)
              if (fieldOrdersToSkip?.includes(field?.order) || hiddenBranches?.includes(field?.order) ){
                return
              }

              let input = null;
              let comment = field?.options?.comment;

              const defaultAnswerTags = answer?.tags?.map(tag => { return { label: tag.name, value: tag.id} })

              switch (field?.typeOf) {
                case FieldTypes.FreeText:
                  input = (
                    <Input
                      inputProps={{ disabled: result.status === "completed" }}
                      name={`answersAttributes.${index}.value.text`}
                      register={register}
                    />
                  );
                  break;
                case FieldTypes.MultipleChoice:
                  input = (
                    <Controller
                      control={control}
                      name={
                        field.options?.multipleSelection
                          ? `answersAttributes.${index}.value.options`
                          : `answersAttributes.${index}.value.text`
                      }
                      render={({ field: { onChange, value } }) => (
                        <MultipleChoice
                          disabled={result.status === "completed"}
                          options={field.options}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  );
                  break;
                case FieldTypes.YesNo:
                  input = (
                    <Controller
                      control={control}
                      name={`answersAttributes.${index}.value.text`}
                      render={({ field: { onChange, value } }) => (
                        <MultipleChoice
                          value={value}
                          onChange={onChange}
                          disabled={result.status === "completed"}
                          options={{
                            multipleSelection: false,
                            otherOption: false,
                            options: [{ value: "Yes" }, { value: "No" }],
                          }}
                        />
                      )}
                    />
                  );
                  break;
                case FieldTypes.OpinionScale:
                  input = (
                    <Controller
                      control={control}
                      name={`answersAttributes.${index}.value.number`}
                      render={({ field: { onChange, value } }) => (
                        <OpinionScale disabled={result.status === "completed"} options={field.options} value={value} onChange={onChange} />
                      )}
                    />
                  );
                  break;
                case FieldTypes.Date:
                  input = (
                    <DatePicker
                      disabled={result.status === "completed"}
                      name={`answersAttributes.${index}.value.text`}
                      register={register}
                    />
                  );
                  break;
                case FieldTypes.PhoneNumber:
                  input = (
                    <Controller
                      control={control}
                      name={`answersAttributes.${index}.value.text`}
                      render={({ field: { onChange, value } }) => (
                        <PhoneNumberPicker disabled={result.status === "completed"} value={value} onChange={onChange} />
                      )}
                    />
                  );
                  break;
                case FieldTypes.StarRating:
                  input = (
                    <Controller
                      control={control}
                      name={`answersAttributes.${index}.value.number`}
                      render={({ field: { onChange, value } }) => (
                        <StarRating disabled={result.status === "completed"} options={field.options} value={value} onChange={onChange} />
                      )}
                    />
                  );
                  break;
                case FieldTypes.Upload:
                  input = (
                    <Controller
                      control={control}
                      name={`answersAttributes.${index}.value.uploadId`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          {!chooseMobile[index] && (
                            <FilePicker
                              disabled={result.status === "completed"}
                              options={field.options}
                              value={value}
                              onChange={onChange}
                              stakeholderJourneyToken={token}
                            />
                          )}
                          {width <= 1080 && (
                            <CaptureMobileCamera
                              onChange={onChange}
                              disabled={result.status === "completed"}
                              value={value}
                              stakeholderName={stakeholderName}
                              chooseMobile={chooseMobile}
                              setChooseMobile={setChooseMobile}
                              index={index}
                              stakeholderJourneyToken={token}
                            />
                          )}
                        </>
                      )}
                    />
                  );
                  break;
                case FieldTypes.Pdf:
                  input =
                    width > 1080 ? (
                      <object className={styles.embed} data={field?.upload?.url}></object>
                    ) : (
                           <MobilePdfViewer url={field?.upload?.url}/>
                    );
                  break;
                case FieldTypes.Signature:
                  input = (
                    <Controller
                      control={control}
                      name={`answersAttributes.${index}.value.uploadId`}
                      render={({ field: { onChange, value } }) => (
                        <Signature
                          disabled={result.status === "completed"}
                          className={styles.signature}
                          signatureId={value}
                          fieldId={field.id}
                          onChange={onChange}
                          setError={setError}
                          clearErrors={clearErrors}
                          name={`answersAttributes.${index}.value.uploadId`}
                          stakeholderJourneyToken={token}
                        />
                      )}
                    />
                  );
                  break;
                case FieldTypes.Video:
                  input = (
                    <VideoWidget
                      className={cn(styles.video, styles.infoItem)}
                      src={field.options.videoSrc}
                      autoplay={false}
                      noClickable={false}
                      loop={false}
                      mute={false}
                    />
                  );
                  case FieldTypes.YesNoEvidence:
                    let yesNo = watch(`answersAttributes.${index}.value.options.0`)
                    input = (
                      <>
                      <p className={styles.yesNoFields}>Is evidence required ?</p>
                      <Controller
                        control={control}
                        name={`answersAttributes.${index}.value.options.0`}
                        render={({ field: { onChange, value } }) => (
                          <MultipleChoice
                            value={value}
                            onChange={onChange}
                            disabled={result.status === "completed"}
                            options={{
                              multipleSelection: false,
                              otherOption: false,
                              options: [{ value: "Yes" }, { value: "No" }],
                            }}
                          />
                        )}
                      />

                      <p className={styles.yesNoFields}>Name the evidence</p>
                        <Input
                          inputProps={{ disabled: result.status === "completed" }}
                          name={`answersAttributes.${index}.value.name`}
                          register={register}
                          placeholder="enter your information here"
                        />

                      <p className={styles.yesNoFields}>Tag the evidence type</p>
                      <Controller
                        name={`answersAttributes.${index}.tagsAttributes`}
                        defaultValue={defaultAnswerTags}
                        control={control}
                        render={({ field }) => (
                          <ReactCretable
                           field={field}
                           defaultValue={defaultAnswerTags}
                           placeholder="Enter a new tag or select from a list of existing tags..."
                            options={answerTags?.map(tag =>
                              { return { label: tag.name, value: tag.id} })}
                            isMulti={true}
                          />
                        )}
                        />

                        <p className={styles.yesNoFields}>Upload required evidence:</p>
                        <Controller
                            control={control}
                            name={`answersAttributes.${index}.value.uploadId`}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <FilePicker
                                  disabled={result.status === "completed"}
                                  options={field.options}
                                  value={value}
                                  onChange={onChange}
                                  stakeholderJourneyToken={token}
                                />
                              </>
                            )}
                          />                    
                        <p className={styles.yesNoFields}>Please provide details:</p>
                        <Input
                          inputProps={{ disabled: result.status === "completed" }}
                          name={`answersAttributes.${index}.value.text`}
                          register={register}
                          placeholder="Enter details here"
                        />
                      </>

                    );

              }

              return (
                <InputGroup
                  key={index}
                  title={setField(fieldsText[index])}
                  strong
                  onKeyDown={()=>{
                    onMouseLeave(field)
                  }}
                  onMouseLeave={()=>{
                    onMouseLeave(field)
                  }}
                  error={!!errors?.answersAttributes?.[index]}
                  className={cn(styles.input, "margin-bottom--large")}
                >
                  <>
                  <p className={styles.description} dangerouslySetInnerHTML={{ __html: description}} />
                    {input}

                  {!!comment &&
                      <>
                      <p className={styles.description}>Add Comment:</p>
                      <Input inputProps={{ disabled: result.status === "completed" }}
                      name={`answersAttributes.${index}.value.comment`}
                      register={register}
                      placeholder="Enter a comment"
                      />
                      </>
                  }
                  </>
                </InputGroup>
              );
            })}
            <CustomButton disabled={errors?.answersAttributes?.length} className={styles.next} onClick={handleSubmit(onSubmit)}>
              Complete
            </CustomButton>
          </form>
        </div>
      </div>
    );
  }
);
