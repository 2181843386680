import { useEffect, useState } from "react";
import { Select } from "components/FormComponents";
import styles from './BundleBuilder.module.scss'
import { useWatch } from "react-hook-form";
import { useAppContext } from "templates/AppLayout/AppLayout";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";

const BUNDLES_QUERY = loader("src/graphql/queries/checkmate_bundles.graphql")

export const BundleBuilder = ({register, control , watch , errors } ) => {
      
    const [bundles, setBundles] = useState([])
    const {setLoading} = useAppContext()
    const bundleWatch = useWatch({ control, name: "bundleId" });

    const { loading: fetching } = useQuery(BUNDLES_QUERY, {
        onCompleted: data => {
            setBundles(data?.checkmateBundles)
        }
      });
    
    useEffect(()=>{
        setLoading(fetching)
    },[fetching])
    
    return (
        <div className="card card-with-shadow">
        {
            !!bundles?.length ? (
                <Select register={register} name='bundleId' placeholder="Select the Checkmate Bundle"  className={styles.typeSelector}>
                    {bundles?.map(bundle => (
                        <Select.Item
                        key={bundle.id}
                        value={bundle.id}
                        active={`${bundleWatch}` === `${bundle.id}`}
                        >
                        {bundle.name}
                        </Select.Item>
                    ))}
                </Select>
               ) :
                <div className={styles.notIntegrated}>
                    Checkmate Not Integrated
                </div>
        }
        </div>
    )
}