export const BlockTypes = {
  Questionnaire: 'questionnaire',
  DocumentSigning: 'document',
  Information: 'information',
  Bundle: 'bundle',
  Quiz: 'quiz',
  Diary: 'diary',
}

export const BlockTypesList = [
  { 
    title: 'Questionnaire',
    key:   BlockTypes.Questionnaire,
    subTitle: 'Collect information by asking some questions'
  },
  {
    title: 'Document Signing',
    key:   BlockTypes.DocumentSigning,
    subTitle: 'Allow the candidate to digitally sign a document'
  },
  {
    title: 'Information',
    key:   BlockTypes.Information,
    subTitle: 'Present some information to the candidate'
  },
  {
    title: 'Bundle',
    key: BlockTypes.Bundle,
    subTitle: 'Checkmate Bundle '
  },
  {
    title: 'Quiz',
    key: BlockTypes.Quiz,
    subTitle: 'Assign numbers to questions and collect information'
  },
  {
    title: 'Diary',
    key: BlockTypes.Diary,
    subTitle: 'Repetitive Block'
  }
]