import { FieldTypes, FieldTypeDetails } from "../../../../../../constants/field-types-enum";
import styles from "./QuestionnaireBlockResult.module.scss";
import FeatherIcon from "feather-icons-react";
import cn from "classnames";
import { regexOccurences } from "utils/helpers";

export const QuestionnaireBlockResult = ({ block, blockResult, internalStakeholder=null, otherBlockResults, journeyBlocks=null }) => {

  const renderFieldAnswer = (field, answer) => {
    if (!answer) return;

    switch (field.typeOf) {
      case FieldTypes.OpinionScale:
        return <p>"{`${answer.value.number}/${field.options.useCustomSteps ? field.options.stepsEnd : 10}`}"</p>;
      case FieldTypes.StarRating:
        const stars = answer.value.number;
        const starsArray = Array(stars)
          .fill()
          .map((_, index) => index + 1);
        return (
          <div>
            {starsArray.map((index) => (
              <FeatherIcon key={index} icon="star" size={21} className={styles.star} />
            ))}
          </div>
        );
      case FieldTypes.Upload:
        return (
          <a href={answer.upload?.url} className="link" target="_blank">
            {answer.upload?.filename}
          </a>
        );
      case FieldTypes.MultipleChoice:
        if (field.options.multipleSelection) {
          let optionsValues = field.options.options.map((option) => option.value);
          let nonOptions = answer.value.options ? answer.value.options?.difference(optionsValues) : [];
          let options = [...optionsValues, ...nonOptions];

          return (
            <ul className={styles.options}>
              {options.map((option, i) => (
                <li
                  className={cn(styles.option, { [styles.optionSelected]: answer.value?.options?.includes(option) })}
                  key={`${option} - ${i}`}
                >
                  {option}
                </li>
              ))}
            </ul>
          );
        } else {
          return <p>"{answer.value.text}"</p>;
        }
      case FieldTypes.Signature:
        return (
          <a href={answer.upload?.url} className="link" target="_blank">
            {answer.upload?.filename}
          </a>
        );
      case FieldTypes.YesNoEvidence:
        const yesNo = answer?.value?.options?.[0]
        return(
          <>
              <div>
                <span className={styles.bold}>{`${yesNo ? yesNo : 'No Answer'} - `}</span>
                {answer.upload?.url ? <a href={answer.upload?.url} className="link" target="_blank">
                  {answer.upload?.filename}
                </a>
                : 'No Evidence Uploaded'
                }
              </div>
            
              <p>
                  <span className={styles.bold}>{answer?.value?.text || 'No Details Provided'}</span>
              </p>
          </>
        )
      default:
        return <p className="margin-bottom-0">"{answer.value.text}"</p>;
    }
  };

  const renderTokenizedFieldText=(text)=>{
    const extractedObjects = regexOccurences(text)
    if(!extractedObjects.length) return text

    const allResults = [...otherBlockResults, blockResult]
    const allAnswers = allResults?.reduce((acc, item) => { return acc.concat(item.answers);}, []);

    extractedObjects.forEach((obj)=>{
      const jb = Array.isArray(journeyBlocks[0]) 
      ? journeyBlocks.find((arr) => arr[0]?.block?.shortCode === obj.name)?.[0]
      : journeyBlocks.find((jb) => jb?.block?.shortCode === obj.name);
      const field = jb?.block?.fields.find(field => field.order === obj.order+1)
      const answer = allAnswers?.find(ans=> ans?.fieldId === field?.id)

      text = text.replace(`{{${obj.name}_${obj.order}}}`, answer?.value?.text)
    })
    return text
  }

  return (
    <>
      {internalStakeholder &&
        <div className={cn(styles.fieldRow, "pt-3")}>
          <p style={{color: internalStakeholder.stakeholderTag.color}} className="title-4 font-weight-500">{internalStakeholder?.stakeholderTag?.name}</p>
          <p className="title-4 font-weight-500">{internalStakeholder?.name}</p>
        </div>
      }
      <h4>Block Summary ({block.fields.length} fields):</h4>
      {block.fields.map((field) => {
        const answer = blockResult?.answers.find((answer) => answer.fieldId === field.id);
        return (
          <div key={field.id} className={cn(styles.fieldRow, "margin-bottom--small")}>
            <div>
              <p className="t-body t-color-secondary">
                {field.order}. {FieldTypeDetails[field.typeOf].title}
              </p>
              <p className="t-body margin-bottom-0">"{block.typeOf==='questionnaire'? renderTokenizedFieldText(field.text): field.text}"</p>
              {field?.typeOf==='yes_no_evidence' && <><p className="t-body margin-bottom-0">Details:</p></>}
            </div>
            <div>
              <p className="t-body t-color-secondary">Answer</p>
              <div className="t-body">{renderFieldAnswer(field, answer)}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};
