import styles from "./Card.module.scss"
import cn from "classnames";
import { CardProps } from "./interfaces/CardProps";
import { IconButton } from "components/IconButton/IconButton";

export const Card: React.FC<CardProps> = ({topName, middleName, bottomName, onClick, topNameClassName, bottomNameClassName}) => {
  return (
    <div className={cn("card", "card-with-border", styles.root)}>
      <div className={cn("card_content", styles.content)}>
        <div className={cn(styles.info, 'd-flex justify-content-center align-items-start')}>
            <div className={cn(styles.journeyName, { [topNameClassName]: topNameClassName})}>{topName}</div>
            {!!middleName && <div className={styles.blockName}>{middleName}</div>}
            {!!bottomName && <div className={cn(styles.bottomName, {[bottomNameClassName]: bottomNameClassName})}>{bottomName}</div>}
        </div>
        <span><IconButton tip="Record Field of Block" className={styles.toggleButton} icon='chevron-down' onClick={onClick}/></span>
      </div>
    </div>
  );
};
