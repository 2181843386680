import { RecordsProps } from "./interfaces/RecordsProps"
import styles from './Records.module.scss'
import { Card } from "views/Ensure/StakeholderScreens/StakeholderBlockRecordScreen/components/Card/Card"
import { useNavigate } from "react-router-dom"

export const Records: React.FC<RecordsProps> = ({records, blockId, blockName, stakeholderJourneyId, journeyName}) =>{
    const navigate = useNavigate();

    return(
        <div className={styles.record}>
           {
            records?.map((record)=>{
                return(
                    <div>
                        <Card onClick={()=>{   
                           navigate(`/block_records/${blockId}/${stakeholderJourneyId}/${record.id}`,{
                            state: {blockName: blockName, fieldText: record.text, journeyName: journeyName}
                        })}} topName={record.text} bottomName={`${record.entries} entries`} topNameClassName={styles.topName}/>
                    </div>
                )
            })
           }
        </div>
    )
}