import styles from './InputGroup.module.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const InputGroup = ({
  children,
  className,
  title,
  titleStyles,
  error,
  strong=false,
  inline=false,
  onMouseLeave =()=>{},
}) => (
  <div onMouseLeave={onMouseLeave} className={cn(styles.root, 'margin-bottom', { [className]: className, [styles.rootInline]: inline })}>
    <span className={cn(styles.title, {[titleStyles]: titleStyles, [styles.titleStrong]: strong, [styles.titleError]: error })} dangerouslySetInnerHTML={{ __html: title }} />    
    <div className={styles.input}>
      {children}
    </div>
  </div>
)

InputGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  children: PropTypes.any,
  inline: PropTypes.bool,
  strong: PropTypes.bool,
  onMouseLeave: PropTypes.func
};
