import styles from './InlineDiaryEntry.module.scss';
import diaryStyles from './InlineDiaryEntry.module.scss'
import { ListGroup, Dropdown } from 'react-bootstrap';
import cn from 'classnames';
import { OpinionScale } from "components/FormComponents";
import { DatePicker } from "components/FormComponents";
import { PhoneNumberPicker } from "components/FormComponents";
import { StarRating } from "components/FormComponents";
import { Signature } from "components/Signature/Signature";
import { VideoWidget } from "components/VideoWidget/VideoWidget";
import { FilePicker } from "components/FormComponents";
import { MobilePdfViewer } from 'views/StakeholderJourneyScreens/StakeholderJourneyBlockScreen/DiaryForm/MobilePdfViewer/MobilePdfViewer';
import { Input, InputGroup } from "components/FormComponents";
import { FieldTypes } from "constants/field-types-enum";
import { Controller } from "react-hook-form";
import { MultipleChoice } from "components/FormComponents";
import { Button } from "components/FormComponents";
import { useMutation } from "@apollo/client";
import { loader } from 'graphql.macro';
import { ReactCretable } from 'components/FormComponents/ReactCreatable/ReactCreatable';

const UPDATE_DIARY_BLOCK_RESULT_MUTATION = loader("src/graphql/mutations/update_diary_block_result.graphql");
const DELETE_DIARY_BLOCK_RESULT_MUTATION = loader("src/graphql/mutations/delete_diary_block_result.graphql")


export const InlineDiaryEntry = ({tags, remove, setIsOverdue, setTotalEntries, totalEntries, setLoading, entry, setResults, setNewEntry, results , watch, setError, clearErrors, stakeholderJourneyId, answerFields, filteredFields, token , newEntry, block, register, control}) =>{

    const [updateDiary] = useMutation(UPDATE_DIARY_BLOCK_RESULT_MUTATION)

    const { innerWidth: width } = window;

    const watchAnswerAttributes = watch();

    const [deleteDiary, { loading: deletingDiary }] = useMutation(DELETE_DIARY_BLOCK_RESULT_MUTATION, {
        onCompleted: (data) => {
          setNewEntry(null)
          setLoading(false)
        },
        onError:()=>{
            setLoading(false)
        }
      });

      const transformAnswerTags = (tagsAttributes)=>{
        
        const attributes =  tagsAttributes?.map(tagAttr => {
          // Check if the object has the __isNew__ property
          if (tagAttr.__isNew__) {
            // If it's a new tag, remove label, value, and __isNew__ properties
            const { label, value, ...rest } = tagAttr;
            // Add name key with the value of label
            return { name: label};
          } else {
            // If it's an existing tag, remove label and value properties
            const { label, value} = tagAttr;
    
            return { id: value }
          }
        });
        
        return attributes?.filter((f)=>f)
      }

    const updateDiaryResult = async() =>{

        const answers = watchAnswerAttributes?.answersAttributes
        const resultId = newEntry.id
        
        answers?.forEach((answer)=>{
            if(answer?.tagsAttributes){
                answer.tagsAttributes = transformAnswerTags(answer.tagsAttributes)
            }
          delete answer.__typename 
          delete answer?.value?.__typename 
          delete answer?.upload
        })
        setLoading(true)
        let attributes = {
            blockId: block.id,
            stakeholderJourneyId: stakeholderJourneyId,
            id: resultId,
            answersAttributes: {...answers}
        }
        
        const answersArray = Object.values(attributes.answersAttributes);
        attributes.answersAttributes = answersArray;    
        
        const response = await updateDiary({
        variables: {
            blockResultAttributes: {
            ...attributes,
            },
            token: token,
        }
        })
        const data = response?.data?.updateDiaryBlockResult
        setResults([...results, data])
        setTotalEntries(totalEntries+1)
        setIsOverdue(false)
        setNewEntry(null)

        setLoading(false)
      }

      const deleteDiaryEntry = async() =>{
        const resultId = newEntry?.id
        setLoading(true)
        deleteDiary({variables:{id: resultId, token: token}})
      }

      let counter = 0;

    return (
        <div className="mt-4">
            <ListGroup>
                <ListGroup.Item className={cn(`${styles.listItem}  list-item--success d-flex justify-content-between`)}>
                    <form className={diaryStyles.form} >
                        <div className={styles.header}>
                            <p className="title-4 font-weight-500">{`Entry #${++entry}`}</p>
                        </div>
                    {answerFields?.map((answer, index) => {
                        const field = filteredFields.find((field) => field.id === `${answer.fieldId}`);
                        if(!field){
                        return
                        }
                        let input = null;
                        const fieldText = field?.typeOf !== 'statement' ? `${++counter}. ${field?.text}${field?.options?.required ? " *" : ""}` : field?.text
                        let comment = field?.options?.comment
                        const defaultAnswerTags = answer?.tags?.map(tag => { return { label: tag.name, value: tag.id} })
                        
                        switch (field?.typeOf) {
                        case FieldTypes.FreeText:
                            input = (
                            <Input
                                className={styles.inputField}
                                name={`answersAttributes.${index}.value.text`}
                                register={register}
                            />
                            );
                            break;
                        case FieldTypes.MultipleChoice:
                            input = (
                                <Controller
                                control={control}
                                name={
                                    field.options?.multipleSelection
                                    ? `answersAttributes.${index}.value.options`
                                    : `answersAttributes.${index}.value.text`
                                }
                                render={({ field: { onChange, value } }) => (
                                    <MultipleChoice
                                    optionsClassName={styles.fieldsBackground}
                                    options={field.options}
                                    value={value}
                                    onChange={onChange}
                                    />
                                )}
                                />
                            );
                            break;
                        case FieldTypes.YesNo:
                        input = (
                            <Controller
                            control={control}
                            name={`answersAttributes.${index}.value.text`}
                            render={({ field: { onChange, value } }) => (
                                <MultipleChoice
                                optionsClassName={styles.fieldsBackground}
                                value={value}
                                onChange={onChange}
                                options={{
                                    multipleSelection: false,
                                    otherOption: false,
                                    options: [{ value: "Yes" }, { value: "No" }],
                                }}
                                />
                            )}
                            />
                        );
                        break;
                        case FieldTypes.OpinionScale:
                        input = (
                            <Controller
                            control={control}
                            name={`answersAttributes.${index}.value.number`}
                            render={({ field: { onChange, value } }) => (
                                <OpinionScale options={field.options} value={value} onChange={onChange} />
                            )}
                            />
                        );
                        break;
                        case FieldTypes.Date:
                        input = (
                            <DatePicker
                            className={styles.inputField}
                            name={`answersAttributes.${index}.value.text`}
                            register={register}
                            />
                        );
                        break;
                        case FieldTypes.PhoneNumber:
                        input = (
                            <Controller
                            control={control}
                            name={`answersAttributes.${index}.value.text`}
                            render={({ field: { onChange, value } }) => (
                                <PhoneNumberPicker                                 
                                className={styles.inputField}
                                value={value} onChange={onChange} />
                            )}
                            />
                        );
                        break;
                        case FieldTypes.StarRating:
                        input = (
                            <Controller
                            control={control}
                            name={`answersAttributes.${index}.value.number`}
                            render={({ field: { onChange, value } }) => (
                                <StarRating starsFillColor={"#ffffff"} starsColor={"#1f4f5c"}  options={field.options} value={value} onChange={onChange} />
                            )}
                            />
                        );
                        break;
                        case FieldTypes.Upload:
                        input = (
                            <Controller
                            control={control}
                            name={`answersAttributes.${index}.value.uploadId`}
                            render={({ field: { onChange, value } }) => (
                                <>
                                <FilePicker
                                    dropZoneClassName={styles.fieldsBackground}
                                    className={styles.fieldsBackground}
                                    options={field.options}
                                    value={value}
                                    onChange={onChange}
                                    stakeholderJourneyToken={token}
                                />
                                </>
                            )}
                            />
                        );
                        break;
                        case FieldTypes.Pdf:
                        input =
                            width > 1080 ? (
                            <object className={styles.embed} data={field?.upload?.url}></object>
                            ) : (
                                <MobilePdfViewer url={field?.upload?.url}/>
                            );
                        break;
                        case FieldTypes.Signature:
                        input = (
                            <Controller
                            control={control}
                            name={`answersAttributes.${index}.value.uploadId`}
                            render={({ field: { onChange, value } }) => (
                                <Signature
                                imageClassName={styles.fieldsBackground}
                                canvasClassName={styles.fieldsBackground}
                                signatureId={value}
                                fieldId={field.id}
                                onChange={onChange}
                                setError={setError}
                                clearErrors={clearErrors}
                                name={`answersAttributes.${index}.value.uploadId`}
                                stakeholderJourneyToken={token}
                                />
                            )}
                            />
                        );
                        break;
                        case FieldTypes.Video:
                        input = (
                            <VideoWidget
                            className={cn(styles.video, styles.infoItem)}
                            src={field.options.videoSrc}
                            autoplay={false}
                            noClickable={false}
                            loop={false}
                            mute={false}
                            />
                        );
                        break;
                        
                        case FieldTypes.YesNoEvidence:
                    let yesNo = watch(`answersAttributes.${index}.value.options.0`)
                    input = (
                      <>
                      <p className={styles.yesNoFields}>Is evidence required ?</p>
                      <Controller
                        control={control}
                        name={`answersAttributes.${index}.value.options.0`}
                        render={({ field: { onChange, value } }) => (
                          <MultipleChoice
                            value={value}
                            onChange={onChange}
                            options={{
                              multipleSelection: false,
                              otherOption: false,
                              options: [{ value: "Yes" }, { value: "No" }],
                            }}
                          />
                        )}
                      />

                      <p className={styles.yesNoFields}>Name the evidence</p>
                        <Input
                          name={`answersAttributes.${index}.value.name`}
                          register={register}
                          placeholder="enter your information here"
                        />

                      <p className={styles.yesNoFields}>Tag the evidence type</p>
                      <Controller
                        name={`answersAttributes.${index}.tagsAttributes`}
                        defaultValue={defaultAnswerTags}
                        control={control}
                        render={({ field }) => (
                          <ReactCretable
                           field={field}
                           defaultValue={defaultAnswerTags}
                           placeholder="Enter a new tag or select from a list of existing tags..."
                            options={tags?.map(tag =>
                              { return { label: tag.name, value: tag.id} })}
                            isMulti={true}
                          />
                        )}
                        />

                        <p className={styles.yesNoFields}>Upload required evidence:</p>
                        <Controller
                            control={control}
                            name={`answersAttributes.${index}.value.uploadId`}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <FilePicker
                                  options={field.options}
                                  value={value}
                                  onChange={onChange}
                                  stakeholderJourneyToken={token}
                                />
                              </>
                            )}
                          />                    
                        <p className={styles.yesNoFields}>Please provide details:</p>
                        <Input
                          name={`answersAttributes.${index}.value.text`}
                          register={register}
                          placeholder="Enter details here"
                        />
                      </>

                    );
                        
                        }

                        return (
                        <>
                            <InputGroup
                            key={`${index}`}
                            title={fieldText}
                            strong
                            className={cn(styles.input, "margin-bottom--large")}
                            >  
                            <p className={styles.description}>{field?.description}</p>
                            {input}

                            {!!comment &&
                            <>
                                <p className={diaryStyles.commentText}>Add Comment for question:</p>
                                <Input 
                                className={styles.inputField}
                                name={`answersAttributes.${index}.value.comment`}
                                register={register}
                                placeholder={`Enter your comment for question`}
                                />
                            </>
                    }
                            </InputGroup>
                        </>
                        );
                        })
                    }
                    <div className={styles.buttons}>
                    <Button className={styles.cancelButton} onClick={deleteDiaryEntry}>
                        Cancel
                    </Button>
                    <Button className={styles.submitButton} onClick={updateDiaryResult} >
                        Submit
                    </Button>
                    </div>
                </form>
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}