import { useForm } from 'react-hook-form';
import { Input, InputGroup, Button, FormMessage } from './../../components/FormComponents';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Loading } from './../../components/Loading/Loading';
import { useMutation, useApolloClient } from "@apollo/client";
import { stateUser, stateCompany } from './../../graphql/state';
import { loader } from "graphql.macro";
import Cookies from 'js-cookie';
import styles from './SignInScreen.module.scss';
import { useState } from 'react';
import { GoogleLoginButton } from './components/GoogleLogin/GoogleLogin';
import { TwoFaLogin } from './components/TwoFaLogin/TwoFaLogin';

const SIGN_IN_MUTATION = loader("./../../graphql/mutations/sign_in.graphql");
const SIGN_IN_GOOGLE_MUTATION = loader("./../../graphql/mutations/sign_in_google.graphql");

export const SignInScreen = () => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [twoFa, setTwoFa] = useState(null)

  const client = useApolloClient();

  const { register, handleSubmit, formState: { errors } } = useForm({
    reValidateMode: 'onChange',
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const [ signInGoogle, {loading: signingInWithGoogle } ] = useMutation(SIGN_IN_GOOGLE_MUTATION, {
    onCompleted: async(data) => {
      const { token, user, company } = data.signInGoogle;
      await client.clearStore();
      Cookies.set('token', token, { path: '' });
      setLoading(false);
      stateCompany(company);
      stateUser(user);
    },
    onError: error => {
      setError(error.message);
      setLoading(false);
    }
  });

  const [ signIn ] = useMutation(SIGN_IN_MUTATION, {
    onCompleted: async(data) => {
      const { token, user, company } = data.signIn;
      await client.clearStore();
      if (!user?.secondFactor?.enabled){
        Cookies.set('token', token, { path: '' });
        stateCompany(company);
        stateUser(user);
      }else{
        setTwoFa(token)
      }
      setLoading(false);
    },
    onError: error => {
      setError(error.message);
      setLoading(false);
    }
  });

  const onSubmit = (data) => {
    setLoading(true);
    signIn({ variables: { email: data.email, password: data.password } })
  }

  return (
    <div className={styles.root}>
      <Loading visible={loading || signingInWithGoogle} />
      <img className={styles.logoImg} src='/assets/images/knowmy/ensure.svg' alt='Ensure Logo' />
      {!twoFa ?<>
        <div className='card card-with-shadow margin-top'>
          <div className={cn('card_content', styles.content)}>
            <h1 className='design-marker'>Welcome back!</h1>
            <GoogleLoginButton signInGoogle={signInGoogle} />
            <FormMessage message={error} className='margin-bottom' onClose={() => setError(null)} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputGroup title='Email'>
                <Input
                  name='email'
                  icon='mail'
                  placeholder='john@knowmy.io'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.email?.type}
                  inputProps={{ tabIndex: 1 }}
                />
              </InputGroup>
              <InputGroup title='Password'>
                <Link className={cn('link', 'margin-top--small', styles.forgotPassword)} to="/forgot_password">Forgot your password?</Link>
                <Input
                  name='password'
                  icon='lock'
                  type='password'
                  placeholder='Enter password'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.password?.type}
                  inputProps={{ tabIndex: 2 }}
                />
              </InputGroup>
              <Button submit buttonProps={{ tabIndex: 3 }} className='w-100'>Log In</Button>
              {/* <Link className={cn('link-button', 'margin-top--small')} to="/sign_up">No account? Sign up</Link> */}
            </form>
          </div>
        </div>
        </>
        :
        <>
          <TwoFaLogin token={twoFa}/>
        </>
      }
    </div>
  )
}
