import { Input, InputGroup } from "components/FormComponents";
import { FieldTypes } from "constants/field-types-enum";
import cn from 'classnames'
import styles from './../../Diary.module.scss'
import { useState } from "react";
import { Controller } from "react-hook-form";
import { MultipleChoice } from "components/FormComponents";
import { Button } from "components/FormComponents";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { IconButton } from "components/IconButton/IconButton";
import { ListGroup } from "react-bootstrap";
import moment from "moment";
import { Collapse } from "react-bootstrap";
import diaryStyles from './DiaryFields.module.scss'
import { OpinionScale } from "components/FormComponents";
import { DatePicker } from "components/FormComponents";
import { PhoneNumberPicker } from "components/FormComponents";
import { StarRating } from "components/FormComponents";
import { Signature } from "components/Signature/Signature";
import { VideoWidget } from "components/VideoWidget/VideoWidget";
import { FilePicker } from "components/FormComponents";
import { MobilePdfViewer } from "../../MobilePdfViewer/MobilePdfViewer";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { ReactCretable } from "components/FormComponents/ReactCreatable/ReactCreatable";

const UPDATE_DIARY_BLOCK_RESULT_MUTATION = loader("src/graphql/mutations/update_diary_block_result.graphql");

export const DiaryFields = ({watch, setError, clearErrors, setResults, deleteDiary, setLoading, token, stakeholderJourneyId, watchAnswerAttributes, answerFields, block , diaryIndex, register, result, control, filteredFields, setEntries, answerTags}) =>{
    
    const [updateDiary] = useMutation(UPDATE_DIARY_BLOCK_RESULT_MUTATION)

    const [open, setOpen] = useState(true)

    const [openModal, setOpenModal] = useState(false)

    const { innerWidth: width } = window;

    const newEntryResult = (!result[diaryIndex]?.answersAttributes?.length || result[diaryIndex]?.answersAttributes?.length === 0)

    const transformAnswerTags = (tagsAttributes , dIndex, index)=>{
    
      const prevAnswerTags = result?.[dIndex].answersAttributes[index]?.tags
  
      const attributes =  tagsAttributes?.map(tagAttr => {
        // Check if the object has the __isNew__ property
        if (tagAttr.__isNew__) {
          // If it's a new tag, remove label, value, and __isNew__ properties
          const { label, value, ...rest } = tagAttr;
          // Add name key with the value of label
          return { name: label};
        } else {
          // If it's an existing tag, remove label and value properties
          const { label, value} = tagAttr;
  
          return { id: value }
        }
      });
  
      const newAttIdNames = attributes?.map((a)=> a?.id)
  
      const changedAttributes = prevAnswerTags?.map((prevATag)=>{
        if(!newAttIdNames?.includes(prevATag?.id)){
          attributes.push({id: prevATag.id, _destroy: true})
        }
      })
      
      return attributes?.filter((f)=>f)
    }
    const updateDiaryResult = async(dIndex) =>{

      const answers = watchAnswerAttributes[dIndex]?.answersAttributes
      const resultId = result[dIndex]?.id || result[dIndex]?.data?.createDiaryEntry?.id

      answers?.forEach((answer, index)=>{
        if(answer?.tagsAttributes){
          answer.tagsAttributes = transformAnswerTags(answer.tagsAttributes, dIndex, index)
        }
        delete answer.__typename 
        delete answer?.value?.__typename 
        delete answer?.upload
        delete answer?.tags
      })
      
      let attributes = {
          blockId: block.id,
          stakeholderJourneyId: stakeholderJourneyId,
          id: resultId,
          answersAttributes: {...answers}
      }

      const answersArray = Object.values(attributes.answersAttributes);
      attributes.answersAttributes = answersArray;    
      setLoading(true)
      const response = await updateDiary({
      variables: {
          blockResultAttributes: {
          ...attributes,
          },
          token: token,
      }
      })
      const data = response?.data?.updateDiaryBlockResult
      setEntries(data?.answeredFields)
      setResults(prevResults =>
        prevResults.map(obj => {
          if (obj.id === data?.id) {
            // Merge the updated properties with the existing object
            return { ...obj, ...data };
          }
          return obj; // Return the original object if it's not the one being updated
        })
      );
      setOpen(false)
      setLoading(false)
    }

    const deleteDiaryEntry = (index) =>{
      const resultId = result[index]?.id
      deleteDiary({variables:{id: resultId, token: token}})
    }

    const cancelEntry = (index) =>{
      if (!(index >=0 )) return
      if (!result[index]?.answersAttributes?.length){
        deleteDiaryEntry(index)
      }else{
        setOpen(false)
      }
    }

    const updatedAt = result[diaryIndex]?.updatedAt
    const createdAt = result[diaryIndex]?.createdAt
    const parsedTime = moment(createdAt);
    const formattedTime = parsedTime.format("DD MMMM, YYYY");

    let counter = 0;

    return(
      <ListGroup>
        <ListGroup.Item
          className={cn(`${diaryStyles.listGroup}  list-item--success d-flex justify-content-between`, { [diaryStyles.newEntry]: newEntryResult } )}
        >
          <ConfirmationModal
            visible={openModal}
            title={`Delete Entry #${diaryIndex+1}`}
            subtitle={`Diary Entry #${diaryIndex+1} will be permanently deleted. This data cannot be recovered`}
            confirmationIcon="trash-2"
            onClose={() => {
              setOpenModal(false)
            }}
            onConfirmation={()=>{
              deleteDiaryEntry(diaryIndex)
              setOpenModal(false)
            }}
            buttonTitle="Delete Entry"
            />
          <div className="w-100">
            <div className={diaryStyles.header}>
                <div>
                  <p className={cn("title-4 font-weight-500")}>Diary Entry #{diaryIndex+1}</p>
                  {updatedAt !== createdAt && <p className={cn(diaryStyles.date)}>Last updated {formattedTime}</p>}
                </div>
                <div>
                  <div className={cn("d-flex align-items-center", diaryStyles.icons)}>
                    
                    <IconButton
                      className={cn(diaryStyles.deleteIcon, diaryStyles.headerButtons)}
                      icon={'trash-2'}
                      disabled={result?.length<=1}
                      onClick={() => setOpenModal(true)}
                    />
                    
                    <IconButton
                      className={cn(diaryStyles.headerButtons)}
                      icon={`chevron-${open? 'up' : 'down'}`}
                      onClick={() => setOpen(!open)}
                    />
                  </div>
                </div>
            </div>
            <Collapse in={open}>
                <form className={diaryStyles.form} >
                {answerFields?.answersAttributes?.map((answer, index) => {
                    const field = filteredFields.find((field) => field.id === `${answer.fieldId}`);
                    if(!field){
                      return
                    }
                    let input = null;
                    const fieldText = field?.typeOf !== 'statement' ? `${++counter}. ${field?.text}${field?.options?.required ? " *" : ""}` : field?.text
                    let comment = field?.options?.comment

                    const defaultAnswerTags = answer?.tags?.map(tag => { return { label: tag.name, value: tag.id} })
                    
                    switch (field?.typeOf) {
                    case FieldTypes.FreeText:
                        input = (
                        <Input
                            inputProps={{ disabled: result.status === "completed" }}
                            name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.text`}
                            register={register}
                        />
                        );
                        break;
                    case FieldTypes.MultipleChoice:
                        input = (
                            <Controller
                            control={control}
                            name={
                                field.options?.multipleSelection
                                ? `answersAttributes.${diaryIndex}.answersAttributes.${index}.value.options`
                                : `answersAttributes.${diaryIndex}.answersAttributes.${index}.value.text`
                            }
                            render={({ field: { onChange, value } }) => (
                                <MultipleChoice
                                disabled={result.status === "completed"}
                                options={field.options}
                                value={value}
                                onChange={onChange}
                                />
                            )}
                            />
                        );
                        break;
                    case FieldTypes.YesNo:
                      input = (
                        <Controller
                          control={control}
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.text`}
                          render={({ field: { onChange, value } }) => (
                            <MultipleChoice
                              value={value}
                              onChange={onChange}
                              disabled={result.status === "completed"}
                              options={{
                                multipleSelection: false,
                                otherOption: false,
                                options: [{ value: "Yes" }, { value: "No" }],
                              }}
                            />
                          )}
                        />
                      );
                      break;
                    case FieldTypes.OpinionScale:
                      input = (
                        <Controller
                          control={control}
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.number`}
                          render={({ field: { onChange, value } }) => (
                            <OpinionScale disabled={result.status === "completed"} options={field.options} value={value} onChange={onChange} />
                          )}
                        />
                      );
                      break;
                    case FieldTypes.Date:
                      input = (
                        <DatePicker
                          disabled={result.status === "completed"}
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.text`}
                          register={register}
                        />
                      );
                      break;
                    case FieldTypes.PhoneNumber:
                      input = (
                        <Controller
                          control={control}
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.text`}
                          render={({ field: { onChange, value } }) => (
                            <PhoneNumberPicker disabled={result.status === "completed"} value={value} onChange={onChange} />
                          )}
                        />
                      );
                      break;
                    case FieldTypes.StarRating:
                      input = (
                        <Controller
                          control={control}
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.number`}
                          render={({ field: { onChange, value } }) => (
                            <StarRating disabled={result.status === "completed"} options={field.options} value={value} onChange={onChange} />
                          )}
                        />
                      );
                      break;
                    case FieldTypes.Upload:
                      input = (
                        <Controller
                          control={control}
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.uploadId`}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <FilePicker
                                disabled={result.status === "completed"}
                                options={field.options}
                                value={value}
                                onChange={onChange}
                                stakeholderJourneyToken={token}
                              />
                            </>
                          )}
                        />
                      );
                      break;
                    case FieldTypes.Pdf:
                      input =
                        width > 1080 ? (
                          <object className={styles.embed} data={field?.upload?.url}></object>
                        ) : (
                               <MobilePdfViewer url={field?.upload?.url}/>
                        );
                      break;
                    case FieldTypes.Signature:
                      input = (
                        <Controller
                          control={control}
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.uploadId`}
                          render={({ field: { onChange, value } }) => (
                            <Signature
                              disabled={result.status === "completed"}
                              className={styles.signature}
                              signatureId={value}
                              fieldId={field.id}
                              onChange={onChange}
                              setError={setError}
                              clearErrors={clearErrors}
                              name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.uploadId`}
                              stakeholderJourneyToken={token}
                            />
                          )}
                        />
                      );
                      break;
                    case FieldTypes.Video:
                      input = (
                        <VideoWidget
                          className={cn(styles.video, styles.infoItem)}
                          src={field.options.videoSrc}
                          autoplay={false}
                          noClickable={false}
                          loop={false}
                          mute={false}
                        />
                      );
                      break;
                    
                    case FieldTypes.YesNoEvidence:
                      let yesNo = watch(`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.options.0`)
                      input = (
                        <>
                        <p className={diaryStyles.yesNoFields}>Is evidence required ?</p>
                        <Controller
                          control={control}
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.options.0`}
                          render={({ field: { onChange, value } }) => (
                            <MultipleChoice
                              value={value}
                              onChange={onChange}
                              disabled={result.status === "completed"}
                              options={{
                                multipleSelection: false,
                                otherOption: false,
                                options: [{ value: "Yes" }, { value: "No" }],
                              }}
                            />
                          )}
                        />
                        <>
                          <p className={diaryStyles.yesNoFields}>Name the evidence:</p>
                          <Input
                            inputProps={{ disabled: result.status === "completed" }}
                            name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.name`}
                            register={register}
                            placeholder="enter your information here"
                          />

                         <p className={diaryStyles.yesNoFields}>Tag the evidence type</p>
                         <Controller
                          name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.tagsAttributes`}
                          defaultValue={defaultAnswerTags}
                          control={control}
                          render={({ field }) => (
                            <ReactCretable
                            field={field}
                            defaultValue={defaultAnswerTags}
                            placeholder="Enter a new tag or select from a list of existing tags..."
                            options={answerTags?.map(tag =>
                              { return { label: tag.name, value: tag.id} })}
                            isMulti={true}
                            />
                          )}
                          />
                          <p className={diaryStyles.yesNoFields}>Please upload supporting evidence:</p>
                          <Controller
                              control={control}
                              name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.uploadId`}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <FilePicker
                                    disabled={result.status === "completed"}
                                    options={field.options}
                                    value={value}
                                    onChange={onChange}
                                    stakeholderJourneyToken={token}
                                  />
                                </>
                              )}
                            />
                        </>
                          <>
                          <p className={diaryStyles.yesNoFields}>Please provide details:</p>
                          <Input
                            inputProps={{ disabled: result.status === "completed" }}
                            name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.text`}
                            register={register}
                            placeholder="Enter details here"
                          />
                          </>
                        </>

                      );
                    
                    }

                    return (
                      <>
                        <InputGroup
                        key={`${index}${diaryIndex}`}
                        title={fieldText}
                        strong
                        className={cn(styles.input, "margin-bottom--large")}
                        >  
                          <p className={styles.description}>{field?.description}</p>
                          {input}

                          {!!comment &&
                          <>
                            <p className={diaryStyles.commentText}>Add Comment for question #{counter}:</p>
                            <Input inputProps={{ disabled: result.status === "completed" }}
                            name={`answersAttributes.${diaryIndex}.answersAttributes.${index}.value.comment`}
                            register={register}
                            placeholder={`Enter your comment for question #${counter}`}
                            />
                          </>
                  }
                        </InputGroup>
                      </>
                      );
                    })
                }
                <div className={diaryStyles.buttons}>
                  <Button className={diaryStyles.cancelButton} onClick={()=>cancelEntry(diaryIndex)} >
                    Cancel
                  </Button>
                  <Button className={diaryStyles.submitButton} onClick={()=>updateDiaryResult(diaryIndex)}>
                      Submit
                  </Button>
                </div>
            </form>
            </Collapse>
          </div>
          </ListGroup.Item>
      </ListGroup>
    )
}