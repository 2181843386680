import { Input, FilePicker, MultipleSelect, Button } from 'components/FormComponents';
import { Controller, useWatch } from 'react-hook-form';
import { useHelloSignEmbedded } from 'hooks/hello-sign-embedded';
import { useFieldArray } from 'react-hook-form';
import styles from './DocumentSigningBuilder.module.scss';
import cn from 'classnames';
import { useEffect,useState } from 'react';
import { useAppContext } from 'templates/AppLayout/AppLayout';
import { loader } from "graphql.macro";
import { useLazyQuery} from "@apollo/client";
import { ModalForm } from 'components/ModalForm/ModalForm';

const CUSTOM_FIELDS_QUERY = loader('./../../../../../../../graphql/queries/custom_fields.graphql')
const TEMPLATE_EDIT_QUERY = loader('./../../../../../../../graphql/queries/signeasy_template_embed_url.graphql')

export const DocumentSigningBuilder = ({
  block,
  saved,
  setSaved,
  control,
  register,
  stakeholderTags,
  errors,
  clearErrors,
  unregister,
  setError,
  setLoaderState,
  getTemplateEditUrl = () => {},
  updateDocumentTemplate = () => {},
  handleRemoveDocument = () =>{}
}) => {

  const [visible , setVisible] = useState(false)
  const [isDocSigned, setIsDocSigned] = useState(null)
  const [disableConfig, setDisableConfig] = useState(false)
  const [signeasyTemplateUrl, setSigneasyTemplateUrl] = useState(null)
  const documentTemplate = useWatch({ control, name: 'documentTemplateAttributes' })
  const hellosignTemplateId = useWatch({control, name: 'documentTemplateAttributes.hellosignTemplateId'})
  const watchFields = useWatch({control, name: `documentTemplateAttributes.customFields`})
  const { setLoading, triggerUnexpectedError } = useAppContext();
  var errorCount = 0
  errors?.documentTemplateAttributes?.customFields?.forEach((field)=>{
    errorCount = field ? errorCount + 1 : errorCount
  })
  if (errorCount === 0 && errors?.documentTemplateAttributes?.customFields?.length){
    clearErrors('documentTemplateAttributes.customFields')
  }

  const { fields, append, remove } = useFieldArray({ control, name: `documentTemplateAttributes.customFields` });

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  const [fetchCustomFields] = useLazyQuery(CUSTOM_FIELDS_QUERY);
  const [fetchTemplateUrl, { loading: fetchingTemplateUrl }] = useLazyQuery(TEMPLATE_EDIT_QUERY, {
    onCompleted: data => {
      setSigneasyTemplateUrl(data.signeasyTemplateEmbedUrl)
      setVisible(true)
    }
  });

  const filterFields = (custom , configuredCustom) => {
    const configured = new Set(custom)
    return configuredCustom?.filter((name)=> !configured.has(name))
  }

  const verifyCustomFields = async () => {
    setLoaderState(true)
    let attempts = 0
    let fields = null
    let check = {error : false , exceeded : false}
    while(attempts<3){
      await delay(3000)
      await fetchCustomFields({variables: {id: parseInt(documentTemplate.id)}}).then(data=>{
        fields = data.data?.customFields
        attempts++
        if (watchFields?.length > fields?.length){
          check.error = true
        }else if (watchFields?.length < fields?.length){
          check.exceeded = true
        }else if (watchFields?.length === fields?.length){
          check.error = false
          check.exceeded= false
          attempts = 3
        }
      })
    }

    const allFields = watchFields?.map((field)=> field?.name)

    if (check.error){
      const unconfigured = filterFields(fields, allFields)
      setLoaderState(false)
      triggerUnexpectedError(`Custom Field(s) ''${unconfigured.join(' , ')}'' not configured in document template`)
    } else if (check.exceeded){
      const exceeded = filterFields(allFields, fields)
      setLoaderState(false)
      if (exceeded?.length) triggerUnexpectedError(`Custom Field(s) ''${exceeded.join(' , ')}'' is not present in Block. Please remove it from the document template`)
    }
    setLoaderState(false)
  }

  const handleTemplateConfigured = (data) => {
    if (!data.success) return;
    updateDocumentTemplate({
      variables: {
        documentTemplateAttributes: {
          id: documentTemplate.id,
          hellosignTemplateId: data.templateId
        }
      }
    })
    verifyCustomFields()
    setIsDocSigned(data.templateId)
  }

  const addField = () => {
    append({ name: '' })
    setDisableConfig(true)
  }

  const openSignEasy = () =>{
    fetchTemplateUrl({variables: {documentTemplateId: documentTemplate.id }})
  }

  const onClose=()=>{
    setVisible(false)
    setSigneasyTemplateUrl(null)
  }

  useEffect(() => {
    if (saved) {
      setLoading(true)
      const timer = setTimeout(() => {
        setLoading(false);
        setSaved(false);
      }, 3000);
      return () => clearTimeout(timer);
    }

  },[block])

  useEffect(()=>{
    const names = watchFields?.map((field)=> field?.name)
    fields?.forEach((f,index)=>{
      names?.some((e, i, arr) => arr.indexOf(e) !== i) ?
      setError(`documentTemplateAttributes.customFields.${index}.name`,{type: 'Fields Must Not Match'})
      : clearErrors(`documentTemplateAttributes.customFields.${index}.name`)
    })
  },[watchFields])

  useEffect(()=>{
    setLoading(fetchingTemplateUrl)
  },[fetchingTemplateUrl])

  return (
    <div className='w-100 d-flex align-items-end flex-column'>
      <ModalForm className={styles.modal} visible={visible && signeasyTemplateUrl} onClose={onClose}>
        <iframe
          src={signeasyTemplateUrl}
          style={{width: '1000px', height: '700px'}}
        />

      <div className={cn('card_footer', styles.noBorder, 'd-flex', 'justify-content-center')}>
        <Button type='secondary' onClick={onClose} >Close</Button>
      </div>
      </ModalForm>
      <div className='card card-with-border w-100'>
        <div className='card_content align-items-start'>
          <Input
            register={register}
            name='documentTemplateAttributes.prompt'
            className='w-100'
            validators={{ required: true }}
            error={errors?.documentTemplateAttributes?.prompt?.type}
            noErrorMessage
            placeholder='Your question/prompt here...'
          />
          <Controller
            control={control}
            name="documentTemplateAttributes.documentTemplatesStakeholderTagsAttributes"
            render={({ field: { onChange, value } }) => (
              <MultipleSelect value={value} onChange={onChange} valueKey="stakeholderTagId" placeholder='Other signers' className='margin-top'>
                {stakeholderTags.map(stakeholderTag => (
                  <MultipleSelect.Item key={stakeholderTag.id} value={stakeholderTag.id} active={value?.map(docTempStakeTag => docTempStakeTag.stakeholderTagId).includes(stakeholderTag.id)}>
                    {stakeholderTag.name}
                  </MultipleSelect.Item>
                ))}
              </MultipleSelect>
            )}
          />
          <hr className='margin-top' />
          <p className='t-body margin-bottom'>Upload the documents that need to be signed below</p>
          <Controller
            control={control}
            name='documentTemplateAttributes.fileId'
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FilePicker
                value={value}
                disabled={block?.usedInStakeholderJourney}
                onChange={onChange}
                error={errors?.documentTemplateAttributes?.fileId?.type}
                onCustomRemove={handleRemoveDocument}
              />
            )}
          />
          <hr className='margin-top' />
          <p className='t-body margin-bottom'>Add custom fields description below</p>
          <div className='margin-bottom--small card card-with-border background-secondary px-4 w-100'>
            {fields.map((_, index) => (
              <Input
                key={index}
                register={register}
                name={`documentTemplateAttributes.customFields.${index}.name`}
                className={styles.input}
                error={errors?.documentTemplateAttributes?.customFields?.[index]?.name?.type}
                validators={{ required: true}}
                placeholder='Enter custom field name...'
                actionIcon='trash-2'
                onActionClick={() => {
                  remove(index);
                  setDisableConfig(true)
                }}
              />
            ))}
            <Button type='link' onClick={addField}>+ Custom field</Button>
          </div>
          <hr className='margin-top' />
          <Button
            icon='edit-2'
            type='secondary-reverse'
            className={cn(styles.configure, 'margin-top')}
            disabled={!documentTemplate?.id || disableConfig||
              (documentTemplate?.fileId !== block?.documentTemplateAttributes?.fileId)}
            onClick={openSignEasy}
          >
            Configure
          </Button>
        </div>
      </div>
    </div>

  )
}
