import { CollapsibleBlock } from '../../../../components/CollapsibleBlock/CollapsibleBlock';
import styles from './JourneyPreview.module.scss';
import cn from 'classnames';
import { Badge } from 'react-bootstrap';
import { QuestionnairePreview } from './QuestionnairePreview/QuestionnairePreview';
import { DocumentSigningPreview } from './DocumentSigningPreview/DocumentSigningPreview';
import { InformationPreview } from './InformationPreview/InformationPreview';

export const JourneyPreview = ({ journey }) => {

  const renderTypePreview = (block) => {
    switch(block.typeOf) {
      case 'diary':
      case 'questionnaire': 
        return <QuestionnairePreview block={block} />
      case 'document':
        return <DocumentSigningPreview block={block} />
      case 'information':
        return <InformationPreview block={block} />
    }
  }

  if (journey.journeyBlocks.length <= 0) {
    return (
      <div className='t-body'>
        There are no blocks in this journey. You need at least one.
      </div>
    )
  }

  return (
    journey.journeyBlocks.map(journeyBlock => (
      <CollapsibleBlock key={journeyBlock.id} color={journeyBlock.block.stakeholderTag.color} className={cn(styles.root, 'margin-bottom')}>
        <CollapsibleBlock.Header>
          <div className={cn(styles.header, 'mt-1')}>
            <p className='title-5 font-weight-500'>{journeyBlock.block.name}</p>
            <Badge className={cn('margin-left--small', { ['badge--success']: journeyBlock.required, ['badge--default']: !journeyBlock.required })}>
              {journeyBlock.required ? 'Required' : 'Nice to do'}
            </Badge>
          </div>
        </CollapsibleBlock.Header>
        <CollapsibleBlock.Content>
          {renderTypePreview(journeyBlock.block)}
        </CollapsibleBlock.Content>
      </CollapsibleBlock>
    ))
  )
}