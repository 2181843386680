import { useForm } from 'react-hook-form';
import cn from 'classnames';
import styles from './NewFrameworkModal.module.scss';
import { Modal } from 'components/Modal/Modal';
import { Input, InputGroup, Button, Select } from './../../../../../../components/FormComponents';

export const NewFrameworkModal = ({ 
  visible,
  brands,
  onClose,
  onSubmit
}) => {

  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <Modal title='New Framework' subtitle='To create a new framework, first name it and then configure below' visible={visible} onClose={onClose}>
      <form className='margin-top' onSubmit={handleSubmit(onSubmit)}>
        <Input 
          name='name'
          placeholder='Enter your framework name...'
          register={register}
          validators={{ required: true }}
          inputProps={{ tabIndex: 1 }}
          error={errors?.name?.type}
        />
        <div className={cn(styles.config, 'background-secondary', 'margin-top--large')}>
          <div className='card card-with-border'>
            <div className='card_content'>

              <InputGroup title='Brand' inline className='margin-bottom-0'>
                <Select name='brandId' register={register}>
                  {brands.map((brand, index) => (
                    <Select.Item key={brand.id} active={index===0} value={brand.id}>{brand.brandName}</Select.Item>
                  ))}
                </Select>
              </InputGroup>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between w-100 margin-top'>
          <Button type='secondary' onClick={onClose}>Cancel</Button>
          <Button submit>Save</Button>
        </div>
      </form>
    </Modal>
  )
}