import ReactSignatureCanvas from 'react-signature-canvas';
import styles from './Signature.module.scss';
import { useRef, useState, useCallback } from 'react';
import { loader } from "graphql.macro";
import { useMutation, useLazyQuery } from "@apollo/client";
import { getFileMetadata } from '../../utils/active-storage-utils';
import { directUpload } from '../../utils/direct-upload';
import { useEffect } from 'react';
import { Loader } from 'components/Loader/Loader';
import cn from 'classnames';
import { Button } from 'components/FormComponents';

const SIGN_UPLOAD_MUTATION =   loader("src/graphql/mutations/sign_upload.graphql");
const CREATE_UPLOAD_MUTATION = loader("src/graphql/mutations/create_upload.graphql");
const DELETE_UPLOAD_MUTATION = loader("src/graphql/mutations/delete_upload.graphql");
const UPLOAD_QUERY =           loader("src/graphql/queries/upload.graphql");

export const Signature = ({imageClassName, canvasClassName, signatureId, onChange, className, disabled, setError, name, clearErrors, stakeholderJourneyToken }) => {
  const [uploadId, setUploadId] = useState(null);

  const [uploading, setUploading] = useState(false);

  const [empty, setEmpty] = useState(true);
  const [image, setImage] = useState(null);
  const [errorMsg, setErrorMsg ] = useState(null)
  const signatureRef = useRef(null);

  const [fetchUpload, { loading: fetching }] = useLazyQuery(UPLOAD_QUERY, {
    onCompleted: data => setImage(data.upload?.url)
  });

  const [signUpload] = useMutation(SIGN_UPLOAD_MUTATION);
  const [createUpload] = useMutation(CREATE_UPLOAD_MUTATION);
  const [deleteUpload] = useMutation(DELETE_UPLOAD_MUTATION);

  useEffect(()=>{
    if (!empty && !image && signatureRef.current?.toData()?.length){
      setError(`${name}`, { type: "custom"});
      setErrorMsg("Please confirm your signature")
    }else {
      clearErrors(`${name}`)
      setErrorMsg(null)
    }
  },[signatureRef.current?.toData()?.length])

  useEffect(() => {
    if(uploadId) fetchUpload({ variables: { id: uploadId, token: stakeholderJourneyToken }})
    else setImage(null)
  }, [uploadId])

  useEffect(() => {
    setUploadId(signatureId)
  }, [signatureId])

  /**
   * --- On upload ---
   * 1) Get file meta
   * 2) Sign upload
   * 3) Upload to s3
   * 4) Create upload
   * 5) Set value
   */

  const dataURLtoFile = (dataurl, filename) => {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
  }

  const handleSignature = useCallback(async (acceptedFiles) => {
    setUploading(true);

    const file = dataURLtoFile(signatureRef.current.getTrimmedCanvas().toDataURL('image/png'), 'signature');

    if(!file) return;

    const fileMetadata = await getFileMetadata(file);
    const signedResponse = await signUpload({ variables: { signUploadAttributes: fileMetadata, token: stakeholderJourneyToken  }});
    const { url, headers, signedBlobId } = signedResponse.data.signUpload;
    await directUpload(url, JSON.parse(headers), file);
    const uploadResponse = await createUpload({ variables: { blobId: signedBlobId, token: stakeholderJourneyToken }});
    const { id: imageId, url: imageUrl } = uploadResponse.data.createUpload;
    setUploadId(imageId);
    setImage(imageUrl);
    setUploading(false);
    onChange(imageId);
    setEmpty(true);
  }, []);

  const handleClear = () => {
    if (uploadId) {
      deleteUpload({ variables: { id: uploadId, token: stakeholderJourneyToken  }})
    } else {
      signatureRef.current.clear();
      setImage(null);
      setEmpty(signatureRef.current.isEmpty())
    }
    onChange(null);
  }

  return (
    <>
      <div className={cn(styles.root, {[className]: className}, 'd-flex flex-column t-color-secondary t-tiny')}>
        <p>Please draw your initials or signature below and when satisfied press the green button on the top right. You can also clear using the red button</p>
      {(uploading || fetching) && (
        <div className={styles.load}>
          <Loader />
        </div>
      )}
        <div>
          {!empty && <Button className={styles.save} onClick={handleSignature}>Confirm Signature</Button>}
          {((!empty || image) && !disabled) && <Button className={styles.cancel} onClick={handleClear}>Redo</Button>}
          {image && <div className={cn(styles.image, {[imageClassName]: imageClassName})}><img className={styles.sign} src={image} alt='signature' /></div>}
          {(!disabled && !image) && (<ReactSignatureCanvas
                canvasProps={{className:cn(styles.canvas, {[canvasClassName]: canvasClassName})}}
                ref={signatureRef}
                onEnd={() => {setEmpty(signatureRef.current.isEmpty())}}
              />
          )}
        </div>
        {(errorMsg ) && <span className={styles.error}>{errorMsg}</span> }
      </div>
    </>
  )
}
