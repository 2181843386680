import { EditableSettings } from "components/EditableSettings/EditableSettings";
import { LogicOperatorTypes } from "constants/logic-jump-operators-enum";
import { useFieldArray } from "react-hook-form";
import { LogicFieldsConfig } from "./components/LogicFieldsConfig/LogicFieldsConfig";
import { Button } from "components/FormComponents";
import FeatherIcon from 'feather-icons-react';
import styles from './LogicQuestionnaireSettings.module.scss'
import cn from 'classnames'

export const LogicQuestionnaireSettings = ({watch, register, control, setValue, unregister}) =>{
    const blockFields = watch('fieldsAttributes')?.filter((f)=>f?.id)
    const allowedFields = ['yes_no','multi_choice', 'opinion_scale', 'star_rating', 'date', 'yes_no_evidence'];
    const filteredFields = blockFields?.filter((f)=> allowedFields.includes(f.typeOf));

    const { fields, append, swap, remove } = useFieldArray({ control, name: 'blockLogicJumpsAttributes', keyName: 'key' });

    const watchFields = watch('blockLogicJumpsAttributes');

    const handleFieldRemove = (indexToRemove) => {
        let _fields = [];
        fields.forEach((field, index) => {
          let _field;
          if(index === indexToRemove || field._destroy) {
            _field = {...field, _destroy: true }
          } else {
            _field = {...field }
          }
          delete _field.key;
          if(!_field._destroy || _field.id) _fields.push(_field)
        });
        remove(indexToRemove)
        setValue('blockLogicJumpsAttributes', _fields);
      }

    const addField = () => {
        append({
          operator: LogicOperatorTypes.Is,    
        });
    }

    return (
        <EditableSettings title='Logic Questionnaire Settings' className={styles.root}>
            {fields?.map((logicJump, index) => {
                if (!logicJump._destroy) {
                    return (
                        <LogicFieldsConfig
                            key={logicJump.key}
                            logicJump={watchFields[index]}
                            blockFields={filteredFields}
                            unfilteredFields={blockFields}
                            register={register}
                            control={control}
                            watch={watch}
                            index={index}
                            remove={handleFieldRemove}
                        />
                    );
                }
                return null;
            })}
            <Button className={cn('w-100',styles.button)} type='secondary-reverse' onClick={addField}>
                <FeatherIcon size={18} className='margin-right' icon='plus' />
                Add Logic Jump
            </Button>
        </EditableSettings>
    );
    
}