import { Select } from "components/FormComponents";
import { LogicOperatorTypesList, LogicOperatorTypes } from "constants/logic-jump-operators-enum";
import { FieldTypeDetails } from "constants/field-types-enum";
import styles from './LogicFieldsConfig.module.scss'
import cn from 'classnames'
import { DatePicker } from "components/FormComponents";
import { IconButton } from "components/IconButton/IconButton";

export const LogicFieldsConfig = ({blockFields, register, index, watch, remove, unfilteredFields}) =>{
    const registerName = `blockLogicJumpsAttributes.${index}`;
    const watchFields  = watch(registerName)

    const fieldValue = watch(`${registerName}.field`)
    const chosenBlock = blockFields.find(f=>f.order ===fieldValue)

    const fromBlockFields = unfilteredFields?.filter((f)=> f.order >= chosenBlock?.order)

    const fromFieldValue = watch(`${registerName}.from`)

    const toBlockFields = unfilteredFields?.filter((f)=> f.order > fromFieldValue)
    
    const operators = LogicOperatorTypesList.filter((f)=>f.allowedTypes.includes(chosenBlock?.typeOf))
    const operatorInfo = operators?.find((o)=>o?.value === watchFields?.operator)

    const operatorToValue = () =>{
        if (!chosenBlock) return

        switch (chosenBlock.typeOf){
            case 'yes_no_evidence':
            case 'yes_no':
                return ['Yes', 'No']
            case 'multi_choice':
                return chosenBlock?.options?.options?.map((o)=>o.value)
            case 'opinion_scale':
                return ['1','2','3','4','5','6','7','8','9','10']
            case 'star_rating':
                const number = chosenBlock?.options?.useCustomStars ? parseInt(chosenBlock?.options?.stars) : 5
                return Array.from({ length: number }, (_, index) => (index + 1).toString());
        }
    }


    return (
        <div className={cn("card card-with-border mb-2",styles.root)}>
            <div className={styles.header}>
                <p className={styles.text}>If <b>{`(${fieldValue? fieldValue : ''})`}</b> is <b>{operatorInfo?.label} to "{watchFields?.value}"</b> then jump from <b> {`(${watchFields?.from})`} </b> to <b> {`(${watchFields?.to})`} </b></p>
                <IconButton tip='Delete Field' className={styles.removeButton} onClick={() => {
                    remove(index)
                }} icon='trash-2'
                />
            </div>
            <div className={styles.select}>{!!blockFields?.length && <>
                    <p>If</p>
                    <Select register={register} name={`${registerName}.field`} placeholder="Choose Logic Field">
                        {blockFields?.map(block => (
                            <Select.Item
                            key={block?.id}
                            value={block?.order}
                            active={fieldValue===block?.order}
                            >
                            {`${block?.order}. ${FieldTypeDetails[block.typeOf].title} ${block?.text}`}
                            </Select.Item>
                        ))}
                    </Select>
                    </>}
            </div>
            <div className={styles.select}>
                <p>Is</p>
                <Select className="mb-3" register={register} name={`${registerName}.operator`} placeholder="Choose Logic">
                    {operators?.map(operator =>(
                        <Select.Item
                        key={operator.value}
                        value={operator.value}
                        active={watchFields.operator === operator.value}
                        >
                        {operator.label}
                        </Select.Item>
                    ))

                    }
                </Select>
                { chosenBlock?.typeOf === 'date' ? 

                    <DatePicker
                    name={`${registerName}.value`}
                    register={register}
                    initialValue={watchFields.value}
                    />

                    :
                    
                    <Select register={register} name={`${registerName}.value`} placeholder="Choose Logic">
                        {operatorToValue()?.map(value =>(
                            <Select.Item
                            key={value}
                            value={value}
                            active={watchFields?.value === value }
                            >
                            {value}
                            </Select.Item>
                        ))

                        }
                    </Select>
                }
            </div>

            <div className={styles.select}>
                <p>Then Jump From</p>
                <Select register={register} name={`${registerName}.from`}>
                    {fromBlockFields?.map(field =>(
                        <Select.Item
                        key={field.order}
                        value={field.order}
                        active={watchFields?.from === field.order }
                        >
                        {`${field.order}. ${field.text}`}
                        </Select.Item>
                    ))

                    }
                </Select>
            </div>
            <div className={styles.select}>
                <p>To</p>
                <Select register={register} name={`${registerName}.to`}>
                    {toBlockFields?.map(field =>(
                        <Select.Item
                        key={field.order}
                        value={field.order}
                        active={watchFields?.to === field.order }
                        >
                        {`${field.order}. ${field.text}`}
                        </Select.Item>
                    ))

                    }
                </Select>
            </div>

        </div>
    )
}