import { useForm } from 'react-hook-form';
import { Input, InputGroup, Button, FormMessage } from './../../components/FormComponents';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Loading } from './../../components/Loading/Loading';
import { useMutation, useApolloClient, useLazyQuery } from "@apollo/client";
import { stateExternalStakeholder, stateCompany } from './../../graphql/state';
import { loader } from "graphql.macro";
import Cookies from 'js-cookie';
import styles from './CandidateSignInScreen.module.scss';
import { useState } from 'react';

const SIGN_IN_EXTERNAL_STAKEHOLDER = loader("./../../graphql/mutations/sign_in_external_stakeholder.graphql")

export const CandidateSignInScreen = () => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const client = useApolloClient();

  const { register, handleSubmit, formState: { errors } } = useForm({
    reValidateMode: 'onChange',
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const [ signInExternalStakeholder ] = useMutation(SIGN_IN_EXTERNAL_STAKEHOLDER, {
    onCompleted: async(data) => {
      const { token, externalStakeholder, company } = data.signInExternalStakeholder;
      await client.clearStore();
      Cookies.set('token', token, { path: '' });
      setLoading(false);
      stateCompany(company);
      stateExternalStakeholder(externalStakeholder);
    },
    onError: error => {
      setError(error.message);
      setLoading(false);
    }
  });

  const onSubmit = (data) => {
    setLoading(true);
    signInExternalStakeholder({ variables: { email: data.email, password: data.password } })
  }

  return (
    <div className={styles.root}>
      <Loading visible={loading} />
      <img className={styles.logoImg} src='/assets/images/knowmy/ensure.svg' alt='KnowMy Logo' />
        <div className='card card-with-shadow margin-top'>
          <div className={cn('card_content', styles.content)}>
            <h1 className='design-marker'>Welcome back Grower!</h1>
            <FormMessage message={error} className='margin-bottom' onClose={() => setError(null)} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputGroup title='Email'>
                <Input
                  name='email'
                  icon='mail'
                  placeholder='john@knowmy.io'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.email?.type}
                  inputProps={{ tabIndex: 1 }}
                />
              </InputGroup>
              <InputGroup title='Password'>
                <Link className={cn('link', 'margin-top--small', styles.forgotPassword)} to="/grower_forgot_password">Forgot your password?</Link>
                <Input
                  name='password'
                  icon='lock'
                  type='password'
                  placeholder='Enter password'
                  register={register}
                  validators={{ required: true }}
                  error={errors?.password?.type}
                  inputProps={{ tabIndex: 2 }}
                />
              </InputGroup>
              <Button submit buttonProps={{ tabIndex: 3 }} className='w-100'>Log In</Button>
            </form>
          </div>
        </div>
    </div>
  )
}
