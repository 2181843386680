import { useState } from 'react';
import { List } from '../../../../components/List/List';
import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect } from 'react';
import { NewJourneyModal } from './components/NewJourneyModal/NewJourneyModal';
import { JourneyBuilder } from './components/JourneyBuilder/JourneyBuilder';
import { Placeholder } from './components/Placeholder/Placeholder';
import { Modal } from "components/Modal/Modal";
import styles from './Journeys.module.scss';
import cn from 'classnames'

const JOURNEYS_QUERY = loader("src/graphql/queries/journeys.graphql");
const CREATE_JOURNEY_MUTATION = loader("src/graphql/mutations/create_journey.graphql");
const UPDATE_JOURNEY_MUTATION = loader("src/graphql/mutations/update_journey.graphql");
const CUSTOMIZE_JOURNEY_MUTATION = loader("src/graphql/mutations/customize_journey.graphql");
const DELETE_JOURNEY_MUTATION = loader("src/graphql/mutations/delete_journey.graphql");
const BLOCKS_QUERY = loader("src/graphql/queries/blocks.graphql");
const BRANDS_QUERY = loader("src/graphql/queries/brands.graphql");
const STAKEHOLDER_TAGS_QUERY = loader('src/graphql/queries/stakeholder_tags.graphql');
const DELETE_JOURNEYS_MUTATION = loader("src/graphql/mutations/delete_journeys.graphql");
const CLONE_JOURNEY_MUTATION = loader("src/graphql/mutations/copy_journey.graphql")

export const Journeys = ({ setLoading, alert, error }) => {

  const [journeys, setJourneys] = useState([]);
  const [journey, setJourney] = useState();
  const [blocks, setBlocks] = useState([]);
  const [brands, setBrands] = useState([]);
  const [stakeholderTags, setStakeholderTags] = useState([]);
  const [selectedJourneys, setSelectedJourneys] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);

  const { loading: fetching } = useQuery(JOURNEYS_QUERY, {
    onCompleted: data => {
      setJourneys(data.journeys)
    }
  });

  const { loading: fetchingBlocks } = useQuery(BLOCKS_QUERY, {
    onCompleted: data => setBlocks(data.blocks)
  });

  const { loading: fetchingBrands } = useQuery(BRANDS_QUERY, {
    onCompleted: data => setBrands(data.brands)
  });

  const { loading: fetchingTags } = useQuery(STAKEHOLDER_TAGS_QUERY, {
    onCompleted: data => setStakeholderTags(data.stakeholderTags),
  })

  const [ createJourney, { loading: creating } ] = useMutation(CREATE_JOURNEY_MUTATION, {
    onCompleted: async(data) => {
      const newJourney = data.createJourney;
      setJourneys(curr => [...curr, newJourney]);
      setJourney(newJourney)
    }
  });

  const [ updateJourney, { loading: updating } ] = useMutation(UPDATE_JOURNEY_MUTATION, {
    onCompleted: async(data) => {
      const updatedJourney = data.updateJourney;
      const _journeys = journeys.map(journey => {
        if(journey.id === updatedJourney.id) return {...journey, ...updatedJourney }
        else return journey
      })
      setJourneys(_journeys);
      setJourney(updatedJourney);
      alert('Journey successfully saved');
    },
    onError: err => error(err.message)
  })

  const [ customizeJourney, { loading: customizing } ] = useMutation(CUSTOMIZE_JOURNEY_MUTATION, {
    onCompleted: async(data) => {
      const customizedJourney = data.customizeJourney;
      const _journeys = journeys.map(journey => {
        if(journey.id === customizedJourney.id) return {...journey, ...customizedJourney }
        else return journey
      })
      setJourneys(_journeys);
      setJourney(customizedJourney);
      alert('Journey customized successfully');
    },
    onError: err => error(err.message)
  })

  const [ deleteJourney, { loading: deleting } ] = useMutation(DELETE_JOURNEY_MUTATION, {
    onCompleted: async(data) => {
      const deletedJourney = data.deleteJourney;
      setJourneys(curr => curr.filter(journey => journey.id !== deletedJourney.id));
      setJourney(null);
      alert('Journey successfully deleted');
    }
  });

  const [ deleteJourneys, { loading: deletingJourenys } ] = useMutation(DELETE_JOURNEYS_MUTATION, {
    onCompleted: async(data) => {
      const deletedJourneys = data.deleteJourneys;
      deletedJourneys.forEach((deletedJourney)=>{
        setJourneys(curr => curr.filter(journey => journey.id !== deletedJourney.id));
      })
      setSelectedJourneys([])
      setJourney(null);
      alert('Journeys successfully deleted');
    }
  });

  const [ cloneJourney, { loading: cloning } ] = useMutation(CLONE_JOURNEY_MUTATION, {
    onCompleted: async(data) => {
      const newJourney = data.copyJourney;
      setJourneys(curr => [...curr, newJourney]);
      setJourney(newJourney)
      alert('Journey successfully cloned');
    }
  });

  useEffect(() => {
    setLoading(fetching || fetchingBlocks || creating || deleting || updating || fetchingBrands || fetchingTags || customizing || cloning)
  }, [fetching, fetchingBlocks, creating, deleting, updating, fetchingBrands, fetchingTags, customizing, cloning])

  const handleNewJourney = (journeyAttributes) => {
    createJourney({ variables: { journeyAttributes: journeyAttributes }})
    setShowNewModal(false);
  }

  const handleJourneyUpdate = (journeyAttributes) => {
    delete journeyAttributes?.brand
    const customization = journeyAttributes?.journeyCustomizationAttributes;
    const journeyDocumentBlocks = blocks.filter(block => {
      return journeyAttributes?.journeyBlocksAttributes?.some(
        journeyBlock => journeyBlock.blockId === block.id) &&
      block.typeOf === 'document';
    });

    const usePublicUrlAccess = customization?.usePublicUrlAccess;

    if (usePublicUrlAccess && journeyDocumentBlocks.length > 0)
      return setOpenModal(true);

    if (customization?.useMedia) {
      if ( customization?.mediaType === 'video' && !customization?.videoSrc) {
        error('Please insert a valid media')
        return
      }

      if (customization?.mediaType === 'image' && !customization?.imageId) {
        error('Please insert a valid media')
        return
      }
    }

    let errors = false;
    journeyAttributes?.journeyBlocksAttributes?.forEach((journeyBlock) => {
      if(journeyBlock?.actionsAttributes?.length > 0) {
        journeyBlock.actionsAttributes.forEach((action) => {
          if(action.actionsStakeholderTagsAttributes.length <= 0) {
            error('Please fill all the actions properly.')
            errors = true;
          }
        })
      }

      if(journeyBlock?.triggersAttributes?.length > 0) {
        journeyBlock.triggersAttributes.forEach((trigger) => {
          if(trigger.typeOf === "dependent_completed" && !trigger.dependentBlockId) {
            error('Please fill all the triggers properly.');
            errors = true;
          } else if(trigger.typeOf !== "dependent_completed" && (!trigger.params?.dateAmount || !trigger.params?.dateType)) {
            error('Please fill all the triggers properly.');
            errors = true;
          }
        })

      }
    })

    if(errors) { return }
    delete journeyAttributes?.used
    delete journeyAttributes?.incomplete

    if(journeyAttributes.journeyBlocksAttributes){
      const filteredBlocks = blocks.filter((block)=>{
        if(block.typeOf==='quiz'){
          return journeyAttributes.journeyBlocksAttributes.find((jB)=> jB.blockId === block.id)
        }
      })
      if (!filteredBlocks?.length) journeyAttributes.passingMarks = null
    }
    updateJourney({ variables: { journeyAttributes } })
  }

  const handleJourneyCustomization = (journeyCustomizeAttributes, successCallback) => {
    const journeyDocumentBlocks = blocks.filter(block => {
      return journey?.journeyBlocksAttributes?.some(
        journeyBlock => journeyBlock.blockId === block.id) &&
      block.typeOf === 'document';
    });

    if (journeyCustomizeAttributes.usePublicUrlAccess && journeyDocumentBlocks.length > 0) {
      successCallback(false)
      return setOpenModal(true);
    } else {
      successCallback(true)
      customizeJourney({ variables: journeyCustomizeAttributes })
    }
  }

  const handleJourneyDeletion = () => {
    deleteJourney({ variables: { id: journey.id } })
  }

  const handleJourneysDeletion = () =>{
    const ids = selectedJourneys?.map((id)=>parseInt(id))
    deleteJourneys({ variables: { ids: ids } })
  }

  const handleClone = (data) => {
    delete data.__typename;

    if(!data.journeyCustomizationAttributes?.useMedia) {
      data.journeyCustomizationAttributes.useMedia = false;
      data.journeyCustomizationAttributes.mediaType = 'video';
    }

    if(!data.journeyCustomizationAttributes.useDashboardHeadings) data.journeyCustomizationAttributes.useDashboardHeadings = false;

    if(!data.journeyCustomizationAttributes.useWelcomeScreenHeadings) {
      data.journeyCustomizationAttributes.useWelcomeScreenHeadings = false;
    }

    if(!data.journeyCustomizationAttributes.usePublicUrlAccess) {
      data.journeyCustomizationAttributes.usePublicUrlAccess = false;
    }

    if(data.journeyCustomizationAttributes) delete data.journeyCustomizationAttributes.image;

    // Welcome screen data type
    if(data.journeyCustomizationAttributes) delete data.journeyCustomizationAttributes.__typename;

    // Journey blocks data type
    data.journeyBlocksAttributes.forEach(journeyBlock => {
      // Parse boolean
      journeyBlock.required = journeyBlock.required === 'false' ? false : true;
      // Delete typename
      delete journeyBlock.__typename

      journeyBlock?.triggersAttributes?.forEach(trigger => {
        delete trigger?.__typename
        delete trigger?.params?.__typename
        if (trigger.typeOf !== 'before_date' && trigger.typeOf !== 'after_date') {
          delete trigger.params
        }
      })

      journeyBlock?.actionsAttributes?.forEach((action) => {
        delete action?.key
        delete action?.__typename
        action?.actionsStakeholderTagsAttributes?.forEach((stakeholderTagId) => {
          delete stakeholderTagId.__typename
        })
      })
    });

    if(!data.journeyCustomizationAttributes.usePublicUrlAccess && !data.journeyCustomizationAttributes.useWelcomeScreenHeadings && !data.journeyCustomizationAttributes.useDashboardHeadings && data.journeyCustomizationAttributes.id) {
      data.journeyCustomizationAttributes._destroy = true;
    } else if(!data.journeyCustomizationAttributes.useWelcomeScreenHeadings && !data.journeyCustomizationAttributes.useDashboardHeadings && !data.journeyCustomizationAttributes.usePublicUrlAccess) {
      delete data.journeyCustomizationAttributes
    }

    // delete journeyAttributes?.brand
    delete data?.used
    delete data?.incomplete
    delete data?.brand

    cloneJourney({ variables: { journeyAttributes: data } })
  }

  return (
    <div className='d-flex justify-around'>
      <NewJourneyModal
        brands={brands}
        visible={showNewModal}
        onClose={() => setShowNewModal(false)}
        onSubmit={handleNewJourney}
      />
      <List
        title='Journeys'
        subtitle='Choose from a list of existing journeys, or go ahead and add a new one.'
        className='margin-right--large'
        onNew={() => setShowNewModal(true)}
        selectedIds={selectedJourneys}
        handleSelectedDeletion={handleJourneysDeletion}
      >
        <List.Category title='KnowMy Journeys'>
          {journeys?.map(journey => (
            <List.Item
              key={journey.id}
              title={`${journey.name} (${journey.brand.name})`}
              value={journey.id}
              onClick={() => setJourney(journey)}
              displayCheckbox={true}
              setSelectedIds={setSelectedJourneys}
              selectedIds={selectedJourneys}
            />
          ))}
        </List.Category>
      </List>
      {journey ?
        <div className={cn({ [styles.overlay]: !!journey?.incomplete }) }>
          <JourneyBuilder
            journey={journey}
            blocks={blocks}
            onDelete={handleJourneyDeletion}
            onSave={handleJourneyUpdate}
            onCustomizeJourney={handleJourneyCustomization}
            onClose={() => setJourney(null)}
            stakeholderTags={stakeholderTags}
            error={error}
            brands={brands}
            updateJourney={updateJourney}
            onClone={handleClone}
          />
        </div>
         :
        <Placeholder />
      }
      <Modal
        visible={openModal}
        title="Can't make this journey public"
        subtitle="Sorry, you cannot make this journey public because it includes document block(s)."
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </div>
  )
}
