import styles from './RightBar.module.scss';
import cn from 'classnames'
import FeatherIcon from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'components/Tabs/Tabs';
import { List } from './Components/List/List';
import { useNavigate } from "react-router-dom";

export const RightBar = ({
  stakeholder,
  onStateChange = () => {}
}) => {

  const [hidden, setHidden] = useState(false);
  useEffect(() => onStateChange(hidden), [hidden]);
  const navigate = useNavigate();

  const separateStakeholderBlocks=(stakeholderJourneys)=>{
    let overdueTrue = [];
    let overdueFalse = [];

    stakeholderJourneys?.forEach(journey => {
        const token = journey.token;
        journey.stakeholderBlocks.forEach(block => {
            if (block.block.typeOf === "diary") {
                const blockWithToken = { ...block, token };
                if (block.overdue) {
                    overdueTrue.push(blockWithToken);
                } else {
                    overdueFalse.push(blockWithToken);
                }
            }
        });
    });

    return {
        overdueTrue,
        overdueFalse
    };
  }

  const {overdueFalse, overdueTrue} = separateStakeholderBlocks(stakeholder.stakeholderJourneys)

  return (
    <aside className={cn(styles.root, { [styles.rootHidden]: hidden })}>
      <div>
        <div className={cn(styles.header, { [styles.headerHidden]: hidden })}>
          <div className={cn(styles.titleIcon,  { [styles.headerHidden]: hidden })}>
            <FeatherIcon icon='clipboard'/>
            <div className={cn('title-5',styles.title)}>My Tasks</div>
          </div>
          {/* <FeatherIcon icon='chevron-right' className={styles.toggleButton} onClick={() => setHidden(!hidden)} /> */}
        </div>
        <Tabs navItemStyles={styles.navItemStyles} contentStyles={styles.tabsContent} activeTabColour={styles.activeTabColor} defaultActiveKey="upcoming">
          <Tab eventKey="upcoming" title="Upcoming">
            <div className={cn(styles.container,'mt-3')}>
                  {overdueFalse?.map((block)=>{
                    return (<div className={cn(styles.card, "w-100")}>
                      <List 
                        entries={block?.entries}
                        onNavigate={() => navigate(`/journey/${block.token}/block/${block.journeyBlockId}`, {
                          state: { referrer: window.location.pathname } // Pass the referrer as state
                        })}
                        {...block}
                        block={block?.block}
                      />
                    </div>)
                  })}
              </div>
          </Tab>
          <Tab eventKey="overdue" title="Overdue">
            <div className={cn(styles.container,'mt-3')}>
                {overdueTrue?.map((block)=>{
                  return (<div className={cn(styles.card, "w-100")}>
                    <List 
                      onNavigate={() => navigate(`/journey/${block.token}/block/${block.journeyBlockId}`, {
                        state: { referrer: window.location.pathname } // Pass the referrer as state
                      })}
                      {...block}
                      block={block?.block}
                    />
                  </div>)
                })}
            </div>

            {!overdueTrue?.length && <p className={styles.overdue}>There are no overdue tasks</p>}
          </Tab>
        </Tabs>
      </div>
    </aside>
  )
}
