import { AppLayout, useAppContext } from "../../templates/AppLayout/AppLayout";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { List } from "./../../components/List/List";
import { StakeholderJourneyConfig } from "./components/StakeholderJourneyConfig/StakeholderJourneyConfig";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NewStakeholderJourneyScreen.module.scss";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { JourneyCustomizationPreview } from "./components/JourneyCustomizationPreview/JourneyCustomizationPreview";


const JOURNEYS_QUERY = loader("src/graphql/queries/journeys.graphql");
const JOURNEY_QUERY = loader("src/graphql/queries/journey.graphql");
const STAKEHOLDER_QUERY = loader("src/graphql/queries/internal_stakeholders.graphql");
const CREATE_STAKEHOLDER_JOURNEY_MUTATION = loader("src/graphql/mutations/create_stakeholder_journey.graphql");
const CREATE_NEW_BULK_STAKEHOLER_JOURNEY_MUTATION = loader("src/graphql/mutations/create_new_bulk_stakeholders_journey.graphql")

export const NewStakeholderJourneyScreen = ({}) => {
  const navigate = useNavigate();

  const { setLoading, alert, triggerUnexpectedError } = useAppContext();

  const [journeys, setJourneys] = useState([]);
  const [journey, setJourney] = useState();
  const [internalStakeholders, setInternalStakeholders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [fetchId, setFetchId] = useState();
  const [step, setStep] = useState(1);
  const [openSettings, setOpenSettings] = useState(false);

  const { loading: fetchingJourneys } = useQuery(JOURNEYS_QUERY, {
    onCompleted: (data) => setJourneys(data.journeys),
  });

  const [fetchJourney, { loading: fetchingJourney }] = useLazyQuery(JOURNEY_QUERY, {
    onCompleted: (data) => {
      setJourney(data.journey);
    },
  });

  const { loading: fetchingStakeholders } = useQuery(STAKEHOLDER_QUERY, {
    onCompleted: (data) => {
      setInternalStakeholders(data.internalStakeholders);
    },
  });

  const [createStakeholderJourney, { loading: creating }] = useMutation(CREATE_STAKEHOLDER_JOURNEY_MUTATION, {
    onCompleted: async (_) => {
      navigate("/");
    },
    onError: (error) => {
      triggerUnexpectedError();
    },
  });

  const [createNewBulkStakeholderJourney, { loading: creatingNewBulkJourney }] = useMutation(CREATE_NEW_BULK_STAKEHOLER_JOURNEY_MUTATION, {
    onCompleted: async (_) => {
      navigate("/");
    },
    onError: (error) => {
      triggerUnexpectedError(error?.message);
    },
  });

  useEffect(() => {
    setLoading(fetchingJourneys || fetchingJourney || fetchingStakeholders || creating || creatingNewBulkJourney);
  }, [fetchingJourneys, fetchingJourney, fetchingStakeholders, creating, creatingNewBulkJourney]);

  const handleCreate = (data) => {
    delete data?.formType
    delete data?.formTypecustomActions
    let error = false;

    const documentBlocks = journey?.journeyBlocks?.filter((journeyBlock) => journeyBlock?.block?.typeOf === "document");

    data.internalStakeholdersAttributes = data?.internalStakeholdersAttributes.flat();

    data.internalStakeholdersAttributes.map((internalStakeholder) => {
      delete internalStakeholder.__typename;
      delete internalStakeholder.stakeholderTag;
    });

    data?.stakeholderJourneyCustomizationsAttributes?.forEach((customizationAttribute) => {
      customizationAttribute.documentUploadId = customizationAttribute?.documentUploadAttributes?.documentUploadId
      delete customizationAttribute?.documentUploadAttributes
      delete customizationAttribute?.key
    })

    let isDocumentJourney = !!documentBlocks?.length

    if(isDocumentJourney){

      const external = data?.externalStakeholderAttributes?.email || data?.externalStakeholderIds?.map((e)=> e?.email)
      const internals = data?.internalStakeholdersAttributes?.map((i)=>i.email)

      const externalEmails = Array.isArray(external) ? external : [external];
      const externalMatch = externalEmails.some((email) => internals.includes(email));

      const internalSet = new Set(internals);
      const hasDuplicates = internalSet.size !== internals.length;

      const emailMatched = externalMatch || hasDuplicates;

      if (emailMatched) {
        error = true
        triggerUnexpectedError("Document signer's must not have matching emails")
      }
    }   
    if (documentBlocks.length === 0 || !error) {
      try {
         if (data?.externalStakeholders || data?.externalStakeholderIds) createNewBulkStakeholderJourney({ variables: { stakeholdersJourneyAttributes: {...data,externalStakeholderIds: data?.externalStakeholderIds?.map((i)=>parseInt(i?.id))} } })
         else createStakeholderJourney({ variables: { stakeholderJourneyAttributes: data } })
      } catch (error) {
        triggerUnexpectedError(error?.message);
      }
    }
  };

  const handleJourneySelection = () => {
    setJourney(null);
    setStep(1);
    fetchJourney({ variables: { id: fetchId } });
    setOpenModal(false);
  };

  const handleClick = (id) => {
    if (id === journey?.id) return;
    if (journey && step === 1) {
      return fetchJourney({ variables: { id: id } });
    }
    if (journey) {
      setFetchId(id);
      setOpenModal(true);
      return;
    }

    fetchJourney({ variables: { id: id } });
  };

  return (
    <>
      {journey?.journeyCustomization && (
        <JourneyCustomizationPreview
          journey={journey}
          visible={openSettings}
          onClose={() => {
            setOpenSettings(false);
          }}
        />
      )}
        <div className="d-flex justify-content-center align-items-start w-100">
          <ConfirmationModal
            visible={openModal}
            title="Your journey information will be lost"
            subtitle="Are you sure you want to continue?"
            confirmationIcon="trash-2"
            onClose={() => {
              setOpenModal(false);
            }}
            onConfirmation={handleJourneySelection}
            buttonTitle="Yes"
          />
          <List
            title="Journeys"
            subtitle="Choose from a list of existing journeys, or go ahead and add a new one."
            className="margin-right--large height-100"
          >
            <List.Category title="KnowMy Journeys">
              {journeys.map((journey) => (
                <List.Item
                  key={journey.id}
                  title={`${journey.name} (${journey.brand.name})`}
                  value={journey.id}
                  onClick={() => {
                    handleClick(journey.id);
                  }}
                />
              ))}
            </List.Category>
          </List>
          <div className={styles.journey}>
            {journey && (
              <StakeholderJourneyConfig
                journey={journey}
                internalStakeholders={internalStakeholders}
                onSubmit={handleCreate}
                onClose={() => setJourney(null)}
                error={triggerUnexpectedError}
                setParentStep={setStep}
                setOpenSettings={setOpenSettings}
                triggerUnexpectedError={triggerUnexpectedError}
                setLoading={setLoading}
              />
            )}
          </div>
        </div>
    </>
  );
};
