import styles from './KnowMyIntegrationLayout.module.scss';
import { Outlet, useNavigate} from 'react-router-dom';
import { Loading } from '../../components/Loading/Loading';
import { useQuery, useMutation } from "@apollo/client";
import { useQueryParams } from 'hooks/query-params';
import { loader } from "graphql.macro";
import cn from 'classnames';
import { useState, createContext, useContext } from 'react';
import { ErrorAlert } from 'components/ErrorAlert/ErrorAlert';

const LayoutContext = createContext(null);

export const useKnowMyIntegrationLayoutContext = () => {
  const context = useContext(LayoutContext)
  if (!context) throw new Error(`Compound components cannot be rendered outside the KnowMyIntegrationLayout component`);
  return context
}

const OAUTH_CLIENT_QUERY = loader("src/graphql/queries/o_auth_client.graphql");
const AUTHORIZATION_CODE_MUTATION = loader("src/graphql/mutations/authorization_code.graphql");

export const KnowMyIntegrationLayout = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [oAuthClient, setOAuthClient] = useState();
  const [authCode, setAuthCode] = useState(null);

  const [ clientId, responseType, redirectUri, scope, state ] = useQueryParams(['client_id', 'response_type', 'redirect_uri', 'scope', 'state']);

  const reject = () => {
    redirect({
      error: 'forbidden',
      error_description: 'User declined authorization',
      state
    });
  }

  const redirect = (params = {}) => {
    if(!redirectUri) {
      setError('Invalid redirect URI');
      return;
    }

    let url = `${redirectUri}?`;
    let keys = Object.keys(params);
    for(let i=0; i < keys.length; i++) {
      const key = keys[i];
      if(!params[key]) continue;
      url += `${key}=${encodeURI(params[key])}&`
    }

    window.location.replace(url);
  }

  const { loading: fetchingClient } = useQuery(OAUTH_CLIENT_QUERY, {
    variables: { clientId },
    errorPolicy: 'none',
    onCompleted: data => {
      setOAuthClient(data.oAuthClient);
    },
    onError: error => {
      if(error.message === 'Unauthorized') {
        const state = Buffer.from(window.location.search).toString('base64');
        navigate(`/sign_in?redirect_uri=integration&state=${state}`)
      } else {
        setError('Client Error')
      }
    }
  });

  const [ authorizationCode, { loading: gettingAuthorizeCode } ] = useMutation(AUTHORIZATION_CODE_MUTATION, {
    variables: { authorizationAttributes: { clientId, responseType, redirectUri, scope, state }},
    onCompleted: async (data) => {
      const { code } = data.authorizationCode;
      setAuthCode(code)
      redirect({ code, state })
    },
    onError: error => {
      setError(error.message)
      redirect({
        error_code: 'bad_request',
        error_description: error.message,
        state
      });
    }
  });

  const [showErrorAlert, setShowErrorAlert] = useState([null, false]);

  const triggerUnexpectedError = (error) => {
    setShowErrorAlert([error || null, true]);
  }


  const contextValue = {
    loading: loading || fetchingClient || gettingAuthorizeCode,
    triggerUnexpectedError,
    client: oAuthClient,
    setLoading,
    error,
    setError,
    reject,
    redirect,
    authorizationCode,
    authCode,
    setAuthCode,
    params:{clientId, responseType, redirectUri, scope, state}
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      <div className={styles.root}>
        <Loading visible={loading} />
        <ErrorAlert errorMessage={showErrorAlert[0]} visible={showErrorAlert[1]} onClose={() => setShowErrorAlert(false)} />
        <div className={cn(styles.content)}>
          <Outlet />
        </div>
      </div>
    </LayoutContext.Provider>
  )
}

const Content = ({
  withFooter = true,
  className,
  children,
}) => {

  const { loading } = useKnowMyIntegrationLayoutContext()

  return (
    <div
      className={cn(styles.root)}
    >
      <Loading visible={loading} />
      <div className={cn({ [className]: className })}>{children}</div>
    </div>
  )
}

KnowMyIntegrationLayout.Content = Content

const Title = ({ children }) => (
  <h1 className={cn(styles.title, 'title-3')}>{children}</h1>
)
KnowMyIntegrationLayout.Title = Title;


const Subtitle = ({ children }) => (
  <p className={styles.subtitle}>{children}</p>
)
KnowMyIntegrationLayout.Subtitle = Subtitle;
