import { Button, InputGroup } from 'components/FormComponents';
import cn from 'classnames';
import { useKnowMyIntegrationLayoutContext } from 'templates/KnowMyIntegrationLayout/KnowMyIntegrationLayout';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import styles from './LaunchJourneyScreen.module.scss';
import { useState, useEffect } from 'react';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { StakeholderJourneyConfig } from 'views/NewStakeholderJourneyScreen/components/StakeholderJourneyConfig/StakeholderJourneyConfig';
import { ReactSelect } from 'components/FormComponents/ReactSelect/ReactSelect';
import { makeOptions } from 'utils/helpers';

const JOURNEYS_QUERY = loader("src/graphql/queries/journeys.graphql");
const JOURNEY_QUERY = loader("src/graphql/queries/journey.graphql");
const STAKEHOLDER_QUERY = loader("src/graphql/queries/internal_stakeholders.graphql");
const CREATE_STAKEHOLDER_JOURNEY_MUTATION = loader("src/graphql/mutations/create_stakeholder_journey.graphql");
const CREATE_NEW_BULK_STAKEHOLER_JOURNEY_MUTATION = loader("src/graphql/mutations/create_new_bulk_stakeholders_journey.graphql")

export const LaunchJourneyScreen = ({error, setError}) => {

  const {
    triggerUnexpectedError,
    authCode,
    params,
    redirect,
    setLoading
  } = useKnowMyIntegrationLayoutContext();
  const [journeys, setJourneys] = useState([]);
  const [journey, setJourney] = useState();
  const [internalStakeholders, setInternalStakeholders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [fetchId, setFetchId] = useState();
  const [step, setStep] = useState(1);
  const [isNext, setIsNext] = useState(true);

  const { loading: fetchingJourneys } = useQuery(JOURNEYS_QUERY, {
    onCompleted: (data) => setJourneys(data.journeys),
  });

  const [fetchJourney, { loading: fetchingJourney }] = useLazyQuery(JOURNEY_QUERY, {
    onCompleted: (data) => {
      setJourney(data.journey);
    },
  });

  const { loading: fetchingStakeholders } = useQuery(STAKEHOLDER_QUERY, {
    onCompleted: (data) => {
      setInternalStakeholders(data.internalStakeholders);
    },
  });

  const [createStakeholderJourney, { loading: creating }] = useMutation(CREATE_STAKEHOLDER_JOURNEY_MUTATION, {
    onCompleted: async (_) => {
      setJourney(null);
      redirect({ authCode, state: params?.state });
    },
    onError: (error) => {
      triggerUnexpectedError(error?.message);
    },
  });

  const [createNewBulkStakeholderJourney, { loading: creatingNewBulkJourney }] = useMutation(CREATE_NEW_BULK_STAKEHOLER_JOURNEY_MUTATION, {
    onCompleted: async (_) => {
      setJourney(null);
      redirect({ authCode, state: params?.state });
    },
    onError: (error) => {
      triggerUnexpectedError(error?.message);
    },
  });


  const handleCreate = (data) => {
    delete data?.formType
    let error = false;

    const documentBlocks = journey?.journeyBlocks?.filter((journeyBlock) => journeyBlock?.block?.typeOf === "document");

    data.internalStakeholdersAttributes = data?.internalStakeholdersAttributes.flat();

    data.internalStakeholdersAttributes.map((internalStakeholder) => {
      delete internalStakeholder.__typename;
      delete internalStakeholder.stakeholderTag;
      return true;
    });
    data?.stakeholderJourneyCustomizationsAttributes?.forEach((customizationAttribute) => {
      customizationAttribute.documentUploadId = customizationAttribute?.documentUploadAttributes?.documentUploadId
      delete customizationAttribute?.documentUploadAttributes
      delete customizationAttribute?.key
    })
   
    if (documentBlocks.length === 0 || !error) {
      try {
         if (data?.externalStakeholders || data?.externalStakeholderIds) createNewBulkStakeholderJourney({ variables: { stakeholdersJourneyAttributes: {...data,externalStakeholderIds: data?.externalStakeholderIds?.map((i)=>parseInt(i?.id))} } })
         else createStakeholderJourney({ variables: { stakeholderJourneyAttributes: data } })
      } catch (error) {
        triggerUnexpectedError(error?.message);
      }
    }
  };

  const handleJourneySelection = () => {
    setJourney(null);
    setStep(1);
    fetchJourney({ variables: { id: fetchId } });
    setOpenModal(false);
  };

  const handleClick = (id) => {
    setFetchId(id);
    if (id === journey?.id || isNext) return;
    if (journey && step === 1) {
      return fetchJourney({ variables: { id: id } });
    }
    if (journey) {
      setOpenModal(true);
      return;
    }

    fetchJourney({ variables: { id: id } });
  };


  const journeyOptions = makeOptions(journeys)

  useEffect(() => {
    setLoading(
      creatingNewBulkJourney ||
      creating ||
      fetchingStakeholders ||
      fetchingJourney ||
      fetchingJourneys
    );
  }, [creatingNewBulkJourney, creating, fetchingStakeholders, fetchingJourney, fetchingJourneys]);

  const handleNext = () => {
    if (fetchId === journey?.id) return;
    if (journey && step === 1) {
      return fetchJourney({ variables: { id: fetchId } });
    }
    if (journey) {
      setFetchId(fetchId);
      setOpenModal(true);
      return;
    }
    setIsNext(false)

    fetchJourney({ variables: { id: fetchId } });
  };

  return (
    <div className={styles.root}>
      <ConfirmationModal
        visible={openModal}
        title="Your journey information will be lost"
        subtitle="Are you sure you want to continue?"
        confirmationIcon="trash-2"
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirmation={handleJourneySelection}
        buttonTitle="Yes"
      />
      <div className={cn('card card-with-border margin-top--large', styles.journeyContainer)}>
        <h1 className={styles.heading}>Launch Journey</h1>
        <div className={styles.journey}>
          {journeyOptions?.length > 0 &&
            <InputGroup title="Journeys" titleStyles={styles.journeySelector}>
              <ReactSelect
                className={'mb-4'}
                isSearchable
                options={journeyOptions}
                placeholder={'Select Journey'}
                onChange={handleClick}
              />
            </InputGroup>
          }
          {journey && (
            <StakeholderJourneyConfig
              journey={journey}
              internalStakeholders={internalStakeholders}
              onSubmit={handleCreate}
              onClose={() => {
                setJourney(null)
                setIsNext(true)
              }}
              error={error}
              setParentStep={setStep}
              disableSettings={true}
              triggerUnexpectedError={triggerUnexpectedError}
              setLoading={setLoading}
            />
          )}
        </div>
        {!journey && <div className='d-flex align-items-center justify-content-center margin-top--large'>
          <Button className='w-50' disabled={!fetchId} onClick={handleNext}>Continue</Button>
        </div>}
      </div>
    </div>
  )
}
