export const Days = [
    {
        key: 'Monday',
        value: "monday"
    },
    {
        key: 'Tuesday',
        value: 'tuesday'
    },
    {
        key: 'Wednesday',
        value: 'wednesday'
    },
    {
        key: 'Thursday',
        value: 'thursday'
    },
    {
        key: 'Friday',
        value: 'friday'
    },
    {
        key: 'Saturday',
        value: 'saturday'
    },
    {
        key: 'Sunday',
        value: 'sunday'
    }
]