import React from "react";
import { EditPasswordForm } from "./components/EditPasswordForm/EditPasswordForm";
import { useMutation } from "@apollo/client";
import styles from "./PasswordTab.module.scss";
import cn from "classnames";
import { loader } from "graphql.macro";
import { Loading } from "../../../../components/Loading/Loading";
import { useState } from "react";

const UPDATE_PASSWORD_MUTATION = loader("src/graphql/mutations/update_password.graphql");

export const PasswordTab = ({ alert, error }) => {
  const [invalidPassword, setInvalidPassword] = useState();
  const [updatePassword, { loading: updatingPassword }] = useMutation(UPDATE_PASSWORD_MUTATION, {
    onCompleted: (data) => {
      if (data?.updatePassword) {
        alert("Password was successfully updated.");
        setInvalidPassword(null);
      } else {
        error("Password was not updated.");
      }
    },
    onError: (errorMessage) => {
      errorMessage?.message === "Incorrect Password." ? setInvalidPassword(errorMessage.message) : error(errorMessage.message);
    },
  });

  return (
    <div className={cn("card card-with-border", styles.root)}>
      <Loading visible={updatingPassword} />
      <div className="padding-bottom-0 d-flex align-items-start justify-content-start card_content">
        <header className={styles.header}>
          <h1 className="title-2">Password</h1>
        </header>
        <div className={styles.subHeading}>Update your password below by entering your current password and then creating a new one.</div>
      </div>
      <EditPasswordForm setInvalidPassword={setInvalidPassword} updatePassword={updatePassword} invalidPassword={invalidPassword} />
    </div>
  );
};
