import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm, useFieldArray, useWatch, Controller } from "react-hook-form";
import { CollapsibleBlock } from "components/CollapsibleBlock/CollapsibleBlock";
import styles from "./StakeholderFrameworkForm.module.scss";
import { InlineOptionsSelector } from 'components/FormComponents';
import { ExistingStakeholdersSelector } from "views/NewStakeholderJourneyScreen/components/StakeholderJourneyForm/Components/ExistingStakeholdersSelector.jsx/ExistingStakeholdersSelector";
import { Input, InputGroup, MultipleSelect, DatePicker } from "components/FormComponents";
import { useState } from "react";
import cn from "classnames";
import { csvDataToJson } from "utils/helpers";
import { loader } from "graphql.macro";

const STAKEHOLDER_TAG_QUERY = loader("src/graphql/queries/stakeholder_tags.graphql");

export const StakeholderFrameworkForm = forwardRef(({externalTag, setCsvData, csvData, disabled,journey, initialData, internalStakeholdersList, onSubmit = () => {}, setLoading, triggerUnexpectedError, framework },  ref) => {
  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    unregister,
    setError: setFormError,
    formState: { errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: initialData
  });
  useFieldArray({ control, name: "internalStakeholdersAttributes" });
  useFieldArray({ control, name: "documentsCustomFields" });

  const [externalStakeholderTag, setExternalStakeholderTag] = useState(externalTag);
  const [internalStakeholderTags, setInternalStakeholderTags] = useState([]);
  const [error, setError] = useState(null);

  const [documentsWithCustomFields, setDocumentsWithCustomFields] = useState([]);
  const formType = useWatch({ control, name: `formType` })

  const internals = useWatch({ control, name: `internalStakeholdersAttributes` })
  const externals = useWatch({ control, name: `externalStakeholderAttributes` })
  
  const isExternalMatchInternal = (externals, internal) => {
    const keysToCheck = ['firstName', 'email', 'lastName'];
    return keysToCheck.every(key => externals?.[key] === internal?.[key]);
  };
  
  const isMatch = internals?.some(internal => isExternalMatchInternal(externals, internal));
  const matchingInternal = internals?.find(internal => isExternalMatchInternal(externals, internal))
  const matchedTag = internalStakeholderTags?.find((iTag)=>iTag?.id === matchingInternal?.stakeholderTagId)

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit(onSubmit)();
    },
  }));


  return (
    <form className={cn(styles.root, "card")}>
      <input type="hidden" {...register("frameworkId")} value={framework.id} />
      <CollapsibleBlock open={error} color={externalStakeholderTag?.color} className={styles.marginBottom} startOpen>
        <CollapsibleBlock.Header>
          <h1 className={styles.blockTitle}>
            <span>
              <span style={{ color: externalStakeholderTag?.color }}>{externalStakeholderTag?.name}</span>
              Details
            </span>
            <p className={cn(styles.subTitle, "t-subtitle")}>{initialData ? 'Enter the details below' : `Enter the details of the ${externalStakeholderTag?.name} below`}</p>
          </h1>
        </CollapsibleBlock.Header>
          <CollapsibleBlock.Content className="margin-top">
            {!disabled && !csvData?.length && (
              <div className="mb-5">
                <Controller
                  control={control}
                  name='formType'
                  render={({ field: { onChange, value } }) => (
                    <InlineOptionsSelector value={value} onChange={onChange} selectedColor={"#9B51E0"}>
                      <InlineOptionsSelector.Option value={1}>Existing Employee</InlineOptionsSelector.Option>
                      <InlineOptionsSelector.Option value={2}>New Employee</InlineOptionsSelector.Option>
                    </InlineOptionsSelector>
                  )}
                />
              </div>
            )}
            {
              formType === 1 ? <ExistingStakeholdersSelector emptyError isInline setCsvData={()=>{}} setError={setFormError} clearErrors={clearErrors} unregister={unregister} register={register} errors={errors} control={control} setLoading={setLoading} triggerUnexpectedError={triggerUnexpectedError} />
              : <ExternalStakeholderForm isMatchError={isMatch} setError={setFormError} control={control} clearErrors={clearErrors} unregister={unregister} setValue={setValue} csvData={csvData} setCsvData={setCsvData} register={register} errors={errors} disabled={disabled} />
            }
          </CollapsibleBlock.Content>
        </CollapsibleBlock>
    </form>
  );
});

const ExternalStakeholderForm = ({ setError, clearErrors, unregister, register,setValue, errors, disabled, csvData, setCsvData, control, setLoading, triggerUnexpectedError, isMatchError }) => {
  const [dataError, setDataError] = useState(null)
  useEffect(()=>{
    if (csvData){
      unregister('externalStakeholderAttributes')
      clearErrors('csv')
      const data = csvDataToJson(csvData)
      if (data?.error){
        setDataError(data?.error)
        setError('csv')
      }else{
        setDataError(null)
        data?.forEach((row,index)=>{
        register(`externalStakeholders.${index}`)
        setValue(`externalStakeholders.${index}`, row)
      })
      }
    }else if(isMatchError){
      setError('externalStakeholderAttributes.custom', {type: 'Custom'})
    }
    else{
      unregister('stakeholderJourneyAttributes.externalStakeholderIds')
      unregister('stakeholderJourneyAttributes')
      clearErrors()
    }
  },[csvData, isMatchError])

  let errorCount = 0
  errors?.internalStakeholdersAttributes?.forEach((e)=> errorCount = e ? errorCount + 1 : errorCount)
  if (!errorCount && errors?.internalStakeholdersAttributes){
    clearErrors('internalStakeholdersAttributes')
  }

  return (

      <>
        <div className="inline">
          <InputGroup title="First Name">
            <Input
              name="externalStakeholderAttributes.firstName"
              placeholder="Add first name..."
              register={register}
              validators={{ required: true }}
              error={errors?.externalStakeholderAttributes?.firstName?.type}
            />
          </InputGroup>
          <InputGroup title="Last Name">
            <Input
              name="externalStakeholderAttributes.lastName"
              placeholder="Add last name..."
              register={register}
              validators={{ required: true }}
              error={errors?.externalStakeholderAttributes?.lastName?.type}
            />
          </InputGroup>
        </div>
        <div className="inline">
          <InputGroup title="Email">
            <Input
              name="externalStakeholderAttributes.email"
              placeholder="Add email address..."
              register={register}
              validators={{ required: true }}
              error={errors?.externalStakeholderAttributes?.email?.type}
            />
          </InputGroup>
          <InputGroup title="Phone Number">
            <Input
              name="externalStakeholderAttributes.phoneNumber"
              placeholder="Add phone number..."
              register={register}
              validators={{ required: true }}
              error={errors?.externalStakeholderAttributes?.phoneNumber?.type}
            />
          </InputGroup>
        </div>
        <InputGroup title="Start Date">
          <DatePicker name="externalStakeholderAttributes.startDate" register={register} minDate={new Date()} />
        </InputGroup>
      </>
    
  );
};

const InternalStakeholderForm = ({
  unregister,
  register,
  control,
  registerName,
  stakeholderTag,
  internalStakeholdersList,
  data,
  errors,
  clearErrors,
  internalStakeholdersAttributes,
}) => {
  const stakeholderAttributes = internalStakeholdersAttributes?.flat()?.find((stakeholder => stakeholder?.stakeholderTag?.id === stakeholderTag?.id))
  const [isNew, setIsNew] = useState(!stakeholderAttributes?.id);

  useEffect(() => {
    if (!isNew) {
      clearErrors();
      unregister(`${registerName}.firstName`);
      unregister(`${registerName}.lastName`);
      unregister(`${registerName}.email`);
      unregister(`${registerName}.phoneNumber`);
    }
  }, [isNew]);

  return (
    <>
      <div className={cn(styles.selector, "margin-bottom--large")}>
        <div
          onClick={() => setIsNew(false)}
          className={styles.selectorItem}
          style={{ ...(isNew ? {} : { backgroundColor: stakeholderTag.color, color: "white", borderColor: stakeholderTag.color }) }}
        >
          Existing {stakeholderTag.name}
        </div>
        <div
          onClick={() => setIsNew(true)}
          className={styles.selectorItem}
          style={{ ...(isNew ? { backgroundColor: stakeholderTag.color, color: "white", borderColor: stakeholderTag.color } : {}) }}
        >
          New {stakeholderTag.name}
        </div>
      </div>
      <input type="hidden" {...register(`${registerName}.stakeholderTagId`)} value={stakeholderTag.id} />
      {isNew ? (
        <>
          <div className="inline">
            <InputGroup title="First Name">
              <Input
                name={`${registerName}.firstName`}
                placeholder="Add first name..."
                register={register}
                validators={{ required: true }}
                error={errors?.internalStakeholdersAttributes ? errors?.internalStakeholdersAttributes[0]?.firstName?.type : null}
              />
            </InputGroup>
            <InputGroup title="Last Name">
              <Input
                name={`${registerName}.lastName`}
                placeholder="Add last name..."
                register={register}
                validators={{ required: true }}
                error={errors?.internalStakeholdersAttributes ? errors?.internalStakeholdersAttributes[0]?.lastName?.type : null}
              />
            </InputGroup>
          </div>
          <div className="inline">
            <InputGroup title="Email">
              <Input
                name={`${registerName}.email`}
                placeholder="Add email address..."
                register={register}
                validators={{ required: true }}
                error={errors?.internalStakeholdersAttributes ? errors?.internalStakeholdersAttributes[0]?.email?.type : null}
              />
            </InputGroup>
            <InputGroup title="Phone Number">
              <Input
                name={`${registerName}.phoneNumber`}
                placeholder="Add phone number..."
                register={register}
                validators={{ required: true }}
                error={errors?.internalStakeholdersAttributes ? errors?.internalStakeholdersAttributes[0]?.phoneNumber?.type : null}
              />
            </InputGroup>
          </div>
        </>
      ) : (
        <>
          <Controller
            control={control}
            name={`${registerName}`}
            render={({ field: { onChange, value } }) => (
              <MultipleSelect value={value} onChange={onChange} className="margin-bottom" placeholder="Please select .">
                {internalStakeholdersList.map((internalStakeholder) => (
                  <MultipleSelect.Item
                    key={internalStakeholder.id}
                    value={internalStakeholder}
                    active={internalStakeholdersAttributes?.flat()?.filter((stakeholder => stakeholder?.stakeholderTag?.id === stakeholderTag?.id)).some(obj => obj?.id === internalStakeholder?.id)}
                  >
                    {`
                  ${internalStakeholder.firstName} -\n
                  ${internalStakeholder.email} /\n
                  ${internalStakeholder.phoneNumber}
                `}
                  </MultipleSelect.Item>
                ))}
              </MultipleSelect>
            )}
          />
        </>
      )}
    </>
  );
};

const DocumentBlockCustomFieldsForm = ({ register, block, documentFields = null, control, registerName, setValue, errors }) => {
  const { fields: customFields, append } = useFieldArray({ control, name: `${registerName}.customFields` });

  useEffect(() => {
    setValue(registerName, { blockId: block.id });
    const fieldsCreated = documentFields ? documentFields[0].customFields : false;
    if (!fieldsCreated) {
      block.documentTemplateAttributes.customFields.forEach((customField) => {
        append({ name: customField.name, value: "" });
      });
    }
    else {
      customFields.forEach((customField, index) => {
        setValue(`${registerName}.customFields.${index}.value`, customField.value)
      });
    }
  }, []);

  return (
    <>
      {customFields.map((customField, index) => (
        <DocumentBlockCustomField
          key={index}
          customField={customField}
          register={register}
          registerName={`${registerName}.customFields.${index}`}
          errors={errors?.customFields ? errors?.customFields[index] : null}
        />
      ))}
    </>
  );
};

const DocumentBlockCustomField = ({ register, registerName, customField, errors }) => {
  return (
    <InputGroup title={customField.name}>
      <Input
        name={`${registerName}.value`}
        placeholder="Enter value..."
        register={register}
        validators={{ required: true }}
        error={errors?.value?.type}
      />
    </InputGroup>
  );
};
