import { Personal } from "./components/Personal/Personal";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Result } from "./components/Result/Result";
import styles from "./Overview.module.scss";
import cn from "classnames";

const UPDATE_EXTERNAL_STAKEHOLDER = loader("src/graphql/mutations/update_external_stakeholder.graphql");

export const Overview = ({ stakeholder, setStakeholder, refetchStakeholder }) => {
  
  const [externalStakeholder , setExternalStakeholder] = useState({stakeholder})

  useEffect(()=>{
    setExternalStakeholder(stakeholder)
  },[stakeholder])

  const stakeholderName = externalStakeholder?.name?.replace(/ /g, "_");
  let blocks = [];
  const internalStakeholders = []
  const blockResults = externalStakeholder?.stakeholderJourneys?.map((journey) => {
      blocks.push(journey.journey.journeyBlocks);
      internalStakeholders.push({internalStakeholder: journey.internalStakeholders.flat(), stakeholderJourneyId: journey.id})
      blocks = blocks.flat();
      return journey.blockResults;
    }).flat();
  const document = blockResults?.filter((t) => t?.block?.typeOf === "document");
  const informationQuiz = blockResults?.filter((t) => t?.block?.typeOf === "information" || t?.block?.typeOf === "questionnaire");
  const bundles = blockResults?.filter((t)=>t?.block?.typeOf === "bundle")
  const quizzes = blockResults?.filter((t)=>t?.block?.typeOf === "quiz")

  const questionInfoJourneyBlocks = informationQuiz?.map((qblock) => {
    return blocks?.filter((t) => t?.block?.id === qblock?.block.id);
  });
  const documentJourneyBlocks = document?.map((jBlock) => {
    return blocks?.filter((t) => t?.block?.id === jBlock?.block.id);
  });
  const bundleJourneyBlocks = bundles?.map((bBlock)=>{
    return blocks?.filter((t) => t?.block?.id === bBlock?.block?.id )
  })
  const quizJourneyBlocks = quizzes?.map((qBlock)=>{
    return blocks?.filter((t) => t?.block?.id === qBlock?.block?.id )
  })

  const optionalDocuments = externalStakeholder?.stakeholderJourneys?.map((sj)=>{
    return sj?.stakeholderJourneyCustomizations
  })?.filter((obj)=>obj?.length)

  const [updateUser, { loading: updatingUser }] = useMutation(UPDATE_EXTERNAL_STAKEHOLDER, {
    onCompleted: async (data) => {
      setStakeholder(data.updateExternalStakeholder);
    },
  });

  return (
    <div className="d-flex align-items-baseline justify-content-center">
      <div>
        <Personal updateUser={updateUser} externalStakeholder={externalStakeholder} />
      </div>
      {blockResults && (
        <div className={cn(styles.blockResults)}>
          <Result
            icon="file-minus"
            title="Signed Documents"
            tabTitle="Documents"
            blockResults={document}
            stakeholderName={stakeholderName}
            refetchStakeholder={refetchStakeholder}
            journeyBlocks={documentJourneyBlocks}
            internalStakeholders={internalStakeholders}
          />
          <Result
            title="Completed Data"
            blockResults={informationQuiz}
            stakeholderName={stakeholderName}
            tabTitle="Current"
            refetchStakeholder={refetchStakeholder}
            journeyBlocks={questionInfoJourneyBlocks}
            internalStakeholders={internalStakeholders}
            setStakeholder={setStakeholder}
          />
          <Result
            title="Quizzes Data"
            blockResults={quizzes}
            stakeholderName={stakeholderName}
            tabTitle="Current"
            refetchStakeholder={refetchStakeholder}
            journeyBlocks={quizJourneyBlocks}
            internalStakeholders={internalStakeholders}
            setStakeholder={setStakeholder}
          />
          <Result
            title="Bundles"
            blockResults={bundles}
            stakeholderName={stakeholderName}
            tabTitle="Current"
            refetchStakeholder={refetchStakeholder}
            journeyBlocks={bundleJourneyBlocks}
            internalStakeholders={internalStakeholders}
            setStakeholder={setStakeholder}
            isBundle
          />
          {!!optionalDocuments?.length && <Result
            title="Optional Documents"
            tabTitle="Documents"
            optionalDocuments={optionalDocuments}
          />}
        </div>
      )}
    </div>
  );
};
