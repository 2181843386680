import { EnsureStakeholderLayout, useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { stateExternalStakeholder } from "graphql/state";
import { useReactiveVar } from "@apollo/client";
import { OverviewTab } from "./Tabs/OverviewTab/OverviewTab";
import { useLocation } from 'react-router-dom'
import { HistoryTab } from "./Tabs/HistoryTab/HistoryTab";
import { Tab, Tabs } from "components/Tabs/Tabs";
import { useEffect, useState } from "react";
import styles from './EnsureStakeholderDashboardScreen.module.scss';
import { Loading } from "components/Loading/Loading";
import { FrameworksTab } from "./Tabs/Frameworks/FrameworksTab";

export const EnsureStakeholderDashboardScreen = () =>{
    const [currentStakeholder, setCurrentStakeholder] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab ] = useState('frameworks')

    const { state } = useLocation();
    const tabName  = state?.tab

    useEffect(() => {
        if (tabName) setActiveTab(tabName)
    }, [tabName, activeTab]);
   
    const stakeholder = useReactiveVar(stateExternalStakeholder);

    useEffect(()=>{
        setCurrentStakeholder(stakeholder)
        setLoading(false)
    },[stakeholder])

    if(loading) return <Loading visible={loading} />

    const filteredStakeholderJourneys = currentStakeholder?.stakeholderJourneys?.map(journey => ({
        ...journey,
        stakeholderBlocks: journey?.stakeholderBlocks.filter(blockObj => blockObj.block.typeOf !== "diary")
    }))
    .filter(journey => journey?.stakeholderBlocks?.length > 0);

    return(
        <>
            <EnsureStakeholderLayout.Header>
                <EnsureStakeholderLayout.Title>Dashboard</EnsureStakeholderLayout.Title>
                <EnsureStakeholderLayout.Subtitle>Below is your summary of Journeys and Blocks.</EnsureStakeholderLayout.Subtitle>
            </EnsureStakeholderLayout.Header>
            <EnsureStakeholderLayout.Body>
                <Tabs maxWidthContentClassName={styles.maxWidthContent} contentStyles={styles.tabsContent} activeTabColour={styles.activeTabColor} navItemStyles={styles.tab} defaultActiveKey={activeTab}>
                    <Tab eventKey="frameworks" title="Frameworks">
                        <FrameworksTab/>
                    </Tab>
                    <Tab eventKey="journeys" title="Journeys">
                        <div className={styles.container}>
                            <OverviewTab nonDiaryJourneys={filteredStakeholderJourneys} currentStakeholder={currentStakeholder}/>
                        </div>
                    </Tab>
                    <Tab eventKey="history" title="History">
                        <HistoryTab currentStakeholder={currentStakeholder}/>
                    </Tab>
                </Tabs>
            </EnsureStakeholderLayout.Body>
        </>
    )
}