import { StakeholderJourneyLayout, useStakeholderJourneyLayoutContext } from "templates/StakeholderJourneyLayout/StakeholderJourneyLayout";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { QuestionnaireForm } from "./QuestionnaireForm/QuestionnaireForm";
import { DocumentSigningForm } from "./DocumentSigningForm/DocumentSigningForm";
import { BundleBlock } from "./BundleBlock/BundleBlock";
import { InfoBlock } from "./InfoBlock/InfoBlock";
import { useRef } from "react";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { BlockTypes } from "constants/block-types-enum";
import styles from "./StakeholderJourneyBlockScreen.module.scss";
import cn from "classnames";
import { stateExternalStakeholder } from "graphql/state";
import { useReactiveVar } from "@apollo/client";
import { DiaryForm } from "./DiaryForm/DiaryFormLatest";

const UPDATE_CREATE_BLOCK_RESULT_MUTATION = loader("src/graphql/mutations/update_or_create_block_result.graphql");
const UPDATE_DIARY_BLOCK_RESULT_MUTATION = loader("src/graphql/mutations/update_diary_block_result.graphql");

export const StakeholderJourneyBlockScreen = ({}) => {
  const [stakeholder, setStakeholder] = useState();

  const isStakeholderLogged = !!useReactiveVar(stateExternalStakeholder)
  // Query params
  const { journey_block_id } = useParams();

  // Form ref
  const ref = useRef(null);

  // Navigation
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the referrer from the location state
  const referrer = location?.state?.referrer
  const tab = location?.state?.tab


  // Stakeholder Journey data
  const { stakeholderJourney, token, setLoading, updateBlockResult, fetch } = useStakeholderJourneyLayoutContext();

  // Journey Block
  const [stakeholderBlock, setStakeholderBlock] = useState();

  // Set Journey Block
  useEffect(() => {
    if (!stakeholderJourney?.stakeholderBlocks) return;
    setStakeholderBlock(
      stakeholderJourney.stakeholderBlocks.find((stakeholderBlock) => stakeholderBlock.journeyBlockId === journey_block_id)
    );
  }, [journey_block_id, stakeholderJourney]);

  // Update Block Result Status
  useEffect(() => {
    if (stakeholderBlock?.result?.status === "pending") {
      updateBlockResultMutation({
        variables: {
          blockResultAttributes: {
            id: stakeholderBlock.result.id,
            blockId: stakeholderBlock.block.id,
            stakeholderJourneyId: stakeholderJourney.id,
            status: "in_progress",
          },
          token: token,
        },
      });
    }
  }, [stakeholderBlock]);

  useEffect(() => {
    if (!stakeholderJourney) return;
    setStakeholder(stakeholderJourney.internalStakeholder || stakeholderJourney.externalStakeholder);
  }, [stakeholderJourney]);

  // Update mutation
  const [updateBlockResultMutation] = useMutation(UPDATE_CREATE_BLOCK_RESULT_MUTATION);
  const [updateDiaryBlock]  = useMutation(UPDATE_DIARY_BLOCK_RESULT_MUTATION);

  const navigateTo = () => {
    let indexHolder = null;
    const block = stakeholderJourney.stakeholderBlocks.filter((block, index) => {
      if (block.journeyBlockId === journey_block_id) {
        indexHolder = index;
        return block;
      } else if (index === indexHolder + 1 && indexHolder !== null) {
        return block;
      }
    });

    if(isStakeholderLogged) {
      return navigate(referrer, {state: {tab: tab}})
    }

    if (block.length === 2 && block[1].result.status !== "locked") {
      fetch();
      return navigate(`/journey/${token}/block/${block[1].journeyBlockId}`);
    } else {
      fetch();
      return navigate(`/journey/${token}/get_started`,{ state: { navigated:true } });
    }
  };

  const transformAnswerTags =(tagsAttributes , index)=>{
    
    const prevAnswerTags = stakeholderBlock?.result?.answersAttributes[index]?.tags

    const attributes =  tagsAttributes?.map(tagAttr => {
      // Check if the object has the __isNew__ property
      if (tagAttr.__isNew__) {
        // If it's a new tag, remove label, value, and __isNew__ properties
        const { label, value, ...rest } = tagAttr;
        // Add name key with the value of label
        return { name: label};
      } else {
        // If it's an existing tag, remove label and value properties
        const { label, value} = tagAttr;

        return { id: value }
      }
    });

    const newAttIdNames = attributes?.map((a)=> a?.id)

    const changedAttributes = prevAnswerTags?.map((prevATag)=>{
      if(!newAttIdNames?.includes(prevATag?.id)){
        attributes.push({id: prevATag.id, _destroy: true})
      }
    })
    
    return attributes?.filter((f)=>f)
  }

  const verifyFields = (answers) => {
    answers?.map((answer, index) => {
      answer.tagsAttributes = transformAnswerTags(answer?.tagsAttributes , index)
      delete answer?.tags
      const type = stakeholderBlock.block?.fields[index]?.typeOf;
      if (
        (type === "free_text" || type === "date" || type === "phone_number" || type === "yes_no") &&
        (answer?.value?.text === "" || answer?.value?.text === undefined || answer?.value?.text === null)
      ) {
        delete answer?.value;
        delete answers[index];
      } else if (
        (type === "opinion_scale" || type === "star_rating") &&
        (answer?.value?.number === null || answer?.value?.number === undefined)
      ) {
        delete answer?.value;
        delete answers[index];
      } else if (
        (type === "upload" || type === "signature") &&
        (answer?.value?.uploadId === undefined || answer?.value?.uploadId === null)
      ) {
        delete answer?.value;
        delete answers[index];
      }else if (type === "multi_choice"){
        const field = stakeholderBlock?.block?.fields?.find((field)=>field.id === answer?.fieldId)
        if ((field?.options?.multipleSelection && (!answer?.value?.options?.length )) || (!field?.options?.multipleSelection && !answer?.value?.text) ){
          delete answer?.value;
          delete answers[index];
        }
      }else if(type === "yes_no_evidence"){
        const yesNo = answer?.value?.options?.[0]
        if (!yesNo && !answer?.value?.uploadId && !answer?.value?.text) {
          delete answer?.value
          delete answers[index]
        }
      }
    });
    return answers;
  };

  const saveDiaryAndClose = () =>{
    if(isStakeholderLogged) return navigate(referrer, {state: {tab: tab}})
    return navigate(`/journey/${token}/get_started`,{ state: { navigated:true } });
  }

  // Save results and close
  const updateResultsAndClose = async (blockResultAttributes) => {
    blockResultAttributes.answersAttributes = verifyFields(blockResultAttributes?.answersAttributes);

    delete blockResultAttributes?.tag
    blockResultAttributes.answersAttributes?.map((answer) => {
      delete answer.upload;
      delete answer.__typename;
      delete answer.value.__typename;
    });

    setLoading(true);

    if (stakeholderBlock?.result?.status !== "completed") {
      const response = await updateBlockResultMutation({
        variables: {
          blockResultAttributes: {
            ...blockResultAttributes,
          },
          token: token,
        },
      });

      updateBlockResult(response.data.updateBlockResult);
    }


    setLoading(false);
    if(isStakeholderLogged){
      return navigate(referrer, {state: {tab: tab}})
    }
    else{
      return navigate(`/journey/${token}/get_started`,{ state: { navigated:true } });
    }
  };

  const updateResultsAndNext = async (blockResultAttributes) => {
    delete blockResultAttributes?.tag
    blockResultAttributes.answersAttributes?.map((answer) => {
      delete answer.upload;
      delete answer.__typename;
      delete answer.value.__typename;
    });

    blockResultAttributes.answersAttributes = verifyFields(blockResultAttributes.answersAttributes);

    setLoading(true);
    if (stakeholderBlock?.result?.status !== "completed") {
      const response = await updateBlockResultMutation({
        variables: {
          blockResultAttributes: {
            ...blockResultAttributes,
          },
          token: token,
        },
      });

      updateBlockResult(response.data.updateBlockResult);
    }

    setLoading(false);
    navigateTo();
  };

  const infoBlockContinue = async (dashboard) => {
    const response = await updateBlockResultMutation({
      variables: {
        blockResultAttributes: {
          id: stakeholderBlock.result.id,
          blockId: stakeholderBlock.block.id,
          stakeholderJourneyId: stakeholderJourney.id,
        },
        token: token,
      },
    });
    
    updateBlockResult(response.data.updateBlockResult);
    if(isStakeholderLogged){
      return navigate(referrer, {state: {tab: tab}})
    }
    else if (dashboard) {
      if(!dashboard?.signatureId){
        navigate(`/journey/${token}/get_started`,{ state: { navigated:true } });
      }
      fetch();
      return;
    }

    navigateTo();
  };

  // Render right block form
  const renderBlockForm = () => {
    if (!stakeholderBlock) return null;

    // eslint-disable-next-line default-case
    switch (stakeholderBlock.block.typeOf) {
      case BlockTypes.Questionnaire:
        return (
          <QuestionnaireForm
            ref={ref}
            required={stakeholderBlock.required}
            block={stakeholderBlock.block}
            result={stakeholderBlock.result}
            stakeholderJourneyId={stakeholderJourney.id}
            onSubmit={updateResultsAndNext}
            onSubmitCta={updateResultsAndClose}
            stakeholderName={stakeholderJourney?.externalStakeholder?.name}
            otherBlocks={stakeholderJourney.stakeholderBlocks?.filter((sB)=> sB.journeyBlockId !== stakeholderBlock.journeyBlockId)}
            answerTags={stakeholderJourney?.externalStakeholder?.tags}
          />
        );
      case BlockTypes.DocumentSigning:
        return (
          <DocumentSigningForm
            required={stakeholderBlock.required}
            block={stakeholderBlock.block}
            result={stakeholderBlock.result}
            stakeholderTag={stakeholder.stakeholderTag}
            onCompleted={infoBlockContinue}
            onContinue={infoBlockContinue}
            blockResultId={stakeholderBlock?.result?.id}
          />
        );
      case BlockTypes.Information:
        return <InfoBlock required={stakeholderBlock.required} onContinue={infoBlockContinue} block={stakeholderBlock.block}        
        />;

      case BlockTypes.Bundle:  
        return <BundleBlock blockResultId={stakeholderBlock.result.id} stakeholderId={stakeholder.id} setLoading={setLoading} token={token}/>

      case BlockTypes.Quiz:
        return (
          <QuestionnaireForm
            ref={ref}
            required={stakeholderBlock.required}
            block={stakeholderBlock.block}
            result={stakeholderBlock.result}
            stakeholderJourneyId={stakeholderJourney.id}
            onSubmit={updateResultsAndNext}
            onSubmitCta={updateResultsAndClose}
            stakeholderName={stakeholderJourney?.externalStakeholder?.name}
          />
        );

      case BlockTypes.Diary:
        return (
          <DiaryForm
            ref={ref}
            required={stakeholderBlock.required}
            block={stakeholderBlock.block}
            result={stakeholderBlock.result}
            entries={stakeholderBlock.entries}
            stakeholderJourneyId={stakeholderJourney.id}
            onSubmit={updateResultsAndNext}
            onSubmitCta={saveDiaryAndClose}
            stakeholderName={stakeholderJourney?.externalStakeholder?.name}
            token={token}
            setLoading={setLoading}
            answerTags={stakeholderJourney?.externalStakeholder?.tags}
          />
        );
      
    }
  };

  const handleCta = () => {
    if((stakeholderBlock?.block.typeOf === BlockTypes.Questionnaire) ||  (stakeholderBlock?.block.typeOf === BlockTypes.Quiz) || (stakeholderBlock?.block.typeOf === BlockTypes.Diary)){
      ref.current.submitCta();
    } else {
      infoBlockContinue(true);
    }
  };

  // Render
  return (
    <StakeholderJourneyLayout.Content
      withCtaButton={true}
      withHeader
      withFooter
      onNavbarCtaClick={() => handleCta()}
      className={styles.root}
    >
      <div className={cn(styles.block, "flex")}>{stakeholder && renderBlockForm()}</div>
    </StakeholderJourneyLayout.Content>
  );
};
