//@ts-nocheck
import { useParams } from "react-router-dom";
import { EnsureStakeholderLayout } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import styles from "./StakeholderRecordsScreen.module.scss"
import cn from 'classnames'
import { Input } from "components/FormComponents"
import FeatherIcon from 'feather-icons-react'; 
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { useForm } from "react-hook-form";
import fuzzysort from "fuzzysort";
import { Link } from "react-router-dom";
import { IconButton } from "components/IconButton/IconButton";
import { useLocation } from "react-router-dom";
import { Records } from "./components/Records/Records";

const RECORDS_IN_BLOCK_QUERY = loader("src/graphql/queries/external_stakeholder_records_in_block.graphql")

export const EnsureStakeholderRecordsScreen=()=>{

    const [records, setRecords] = useState<Record[]>([])
    const [filteredRecords, setFilteredRecords] = useState<Record[]>([])
    const [entryCount , setEntryCount] = useState(0)

    const params = useParams()
    const location = useLocation()
 
    const {blockName, journeyName} = location?.state

    const {register, watch} = useForm({reValidateMode: "onChange", criteriaMode: "firstError", shouldFocusError: true});

    const {setLoading} = useEnsureStakeholderLayoutContext()

    const { loading: fetching } = useQuery(RECORDS_IN_BLOCK_QUERY, {
        variables: {blockId: params.blockId, stakeholderJourneyId: params.stakeholderJourneyId, fieldType: 'record'},
        onCompleted: data => {
            setRecords(data.externalStakeholderRecordsInBlock?.recordFields);
            setEntryCount(data.externalStakeholderRecordsInBlock?.entries)
        },
      });
    const search = watch('searchRecord')

    useEffect(() => {
        if (search !== null && search?.trim() !== "") {
          const filter = fuzzysort.go(search, records, { key: "text", threshold: -10000 });
          const searchedRecords: Record[] = [];
          filter.forEach((o)=>searchedRecords.push(o.obj as Record))
          setFilteredRecords(searchedRecords)
        }else{
            setFilteredRecords(records)
        }
      }, [search]);

    useEffect(()=>{
        setFilteredRecords(records)
    },[records])

    useEffect(()=>{
        setLoading(fetching)
    },[fetching])
                                                                           
    return (
        <>
        <EnsureStakeholderLayout.Header>
            <EnsureStakeholderLayout.Title>
                <div className={cn(styles.headerContainer, 'd-flex justify-content-center align-items-start')}>
                    <div className={cn(styles.innerContainer,'d-flex justify-content-start align-items-center')}>
                        <FeatherIcon className={styles.icon} />
                        <Link to='/block_records'><IconButton className={styles.icon} icon='chevron-down'/></Link>
                        <div className={cn('d-flex justify-content-center align-items-center', styles.title)}>
                            <div className={styles.journeyName}>
                                {journeyName}
                            </div>
                            <div className={styles.blockName}>
                                {blockName}
                            </div>
                            <div className={styles.entries}>
                                {records?.length} types • {entryCount} entries
                            </div>
                        </div>
                    </div>
                    <Input name="searchRecord" className={cn(styles.search)} register={register} inputClassName={styles.input} type="text" icon="search" placeholder="Search" />
                </div>
            </EnsureStakeholderLayout.Title>
        </EnsureStakeholderLayout.Header>
        <EnsureStakeholderLayout.Body>
            <>
                <Records stakeholderJourneyId={params.stakeholderJourneyId} blockName={blockName} blockId={params.blockId} records={filteredRecords} journeyName={journeyName}/>
            </>
        </EnsureStakeholderLayout.Body>
    </>
    )
}