import styles from './StakeholderFrameworkConfig.module.scss';
import cn from 'classnames';
import { Button } from 'components/FormComponents/Button/Button';
import { useState, useRef } from 'react';
import FeatherIcon from 'feather-icons-react';
import { IconButton } from 'components/IconButton/IconButton';
import { readFile, sampleCsv } from 'utils/helpers';
import { FrameworkPreview } from '../FrameworkPreview/FrameworkPreview';
import { StakeholderFrameworkForm } from '../StakeholderFrameworkForm/StakeholderFrameworkForm';
import { JourneyBlocks } from 'views/BlockBuilderScreen/tabs/Frameworks/components/JourneyFrameworkConfig/components/JourneyBlocks/JourneyBlocks';
import { StakeholderFrameworkPreview } from '../StakeholderFrameworkPreview/StakeholderFrameworkPreview';

export const StakeholderFrameworkConfig = ({
  framework,
  disableSettings,
  internalStakeholders = [],
  onSubmit = () => {},
  onClose = () => {},
  error = () => {},
  setParentStep = () => {},
  setOpenSettings = () => {},
  existingStakeholderCheck ,
  existingStakeholderData ,
  triggerUnexpectedError,
  setLoading,
}) => {

  const [step, setStep] = useState(1);
  
  const [stakeholderJourneyData, setStakeholderJourneyData] = useState(existingStakeholderCheck ? existingStakeholderData : {formType:2});
  
  const formRef = useRef(null);

  const [data, setData] = useState(null)

  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    event.target.value=null
    readFile(fileUploaded, setData, triggerUnexpectedError)
  };

  const nextStep = () => {
    switch (step) {
      case 1:
        setStep(2);
        setParentStep(2);
        break;
      case 2:
        formRef.current.submit();
        break;
      case 3:
        onSubmit(stakeholderJourneyData);
        break;
    }
  }

  const handleDataSubmission = (data) => {
    setStakeholderJourneyData(data);
    setStep(3);
  }

  const setPrevious = () => {
    setStep(step - 1)
    setParentStep(step - 1)
  }

  const stakeholderTags = framework?.journeyFrameworks?.map((journeyFramework)=>{
    return journeyFramework?.journey?.journeyBlocks?.map((journeyBlock)=>{
      return journeyBlock?.block?.stakeholderTag
    }).flat()
  }).flat()

  const externalStakeholderTag = stakeholderTags?.find((stakeholderTag)=> stakeholderTag?.typeOf === 'external')
  const internalStakeholderTags = stakeholderTags?.filter((stakeholderTag)=> stakeholderTag?.typeOf === 'internal')

  return (
    <div className='card card-with-border'>
      <div className='card_content'>
        <header className='d-flex justify-content-between w-100'>
          <div>
            <h1 className='title-2'>{framework.name}</h1>
            <p className={cn(styles.subtitle, 't-body')}>{framework?.journeyFrameworks?.length || framework?.journeyFrameworksAttributes?.length} Journeys</p>
          </div>
          <div className={cn(styles.icons)}>
            {step ===2 && !existingStakeholderData &&
            <>
              <input type="file"
                ref={hiddenFileInput}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleChange}
                style={{display:'none'}}
              />
              <IconButton icon='upload' tip='bulk upload CSV' onClick={handleClick} className={cn(styles.csv, 'me-2')}/>
              <Button type='secondary' icon='download' tip='download CSV sample' className={cn(styles.downloadButton)} onClick={sampleCsv}>Templates</Button>
            </>}
          </div>
        </header>
        <StepIndicator step={step} setStep={setStep} setParentStep={setParentStep}/>
      </div>
      <div className={cn('card_content', 'background-secondary', styles.content, {[styles.largePadding]: (step===2 || step ===3)})}>
        {step === 1 &&
            <FrameworkPreview journeyFrameworks={framework?.journeyFrameworks}/>            
        }
        {step === 2 &&
            <StakeholderFrameworkForm initialData={stakeholderJourneyData} externalTag={externalStakeholderTag} framework={framework} ref={formRef}  internalStakeholdersList={internalStakeholders} onSubmit={handleDataSubmission} setLoading={setLoading} triggerUnexpectedError={triggerUnexpectedError}/>
        }
        {step === 3 &&
            <StakeholderFrameworkPreview externalStakeholderTag={externalStakeholderTag} internalStakeholderTags={internalStakeholderTags} stakeholderJourneyData={stakeholderJourneyData}/>
        }
      </div>
      <div className={cn('card_footer d-flex justify-content-between')}>
        <Button type='secondary' onClick={onClose}>Cancel</Button>
        <div className='d-flex'>
          {step > 1 && !existingStakeholderCheck &&
            <Button type='secondary' className='margin-right' onClick={setPrevious}>Previous </Button>
          }
          <Button disabled={false} onClick={nextStep}>{step === 3 ? 'Launch': 'Next'}</Button>
        </div>
      </div>
    </div>
  )
}

const StepIndicator = ({ step = 1, setStep, setParentStep }) => {

  const stepBack = (self) => {
    if (step > 1 && self === 1) {
      setStep(1)
      setParentStep(1)
    } else if (step > 2 && self === 2) {
      setStep(2)
      setParentStep(2)
    }
  }

  return (
    <div className={styles.stepIndicator}>
      <div onClick={() => {stepBack(1)}} className={cn(styles.step, {[styles.stepSelected]: step === 1, [styles.stepCompleted]: step > 1 })}>
        Review Journey {step > 1 && <FeatherIcon icon='check' size={16} className='margin-left--small'/>}
      </div>
      <div onClick={() => {stepBack(2)}} className={cn(styles.step, {[styles.stepSelected]: step === 2, [styles.stepCompleted]: step > 2 })}>
        Add Information {step > 2 && <FeatherIcon icon='check' size={16} className='margin-left--small'/>}
      </div>
      <div className={cn(styles.step, {[styles.stepSelected]: step === 3 })}>
        Summary
      </div>
    </div>
  )
}
