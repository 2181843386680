import { AppLayout, useAppContext } from "../../../../templates/AppLayout/AppLayout";
import FeatherIcon from "feather-icons-react";
import { loader } from "graphql.macro";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { stateUser, stateCompany } from "graphql/state";
import { useState, useEffect } from "react";
import { UserRow } from "./components/UserRow/UserRow";
import styles from "./Users.module.scss";
import { Button, Input, Select } from "components/FormComponents";
import { Checkbox } from "components/FormComponents/Checkbox/Checkbox";
import cn from "classnames";
import { checkPermission, checkSuperAdmin, makeOptions } from "utils/helpers";
import { useForm, useWatch } from "react-hook-form";
import fuzzysort from "fuzzysort";
import { InviteUserForm } from "./components/InviteUserForm/InviteUserForm";
import { UpdateUserForm } from "./components/UpdateUserForm/UpdateUserForm";

const USERS_QUERY                   =  loader("src/graphql/queries/users.graphql");
const ROLES_QUERY                   = loader('src/graphql/queries/roles.graphql');
const COMPANIES_QUERY               =  loader("src/graphql/queries/companies.graphql");
const INVITE_USER_MUTATION          = loader('src/graphql/mutations/invite_user.graphql');
const RESEND_USER_INVITATION_MUTATION = loader('src/graphql/mutations/resend_user_invitation.graphql');
const UPDATE_USER_MUTATION          = loader('src/graphql/mutations/update_user.graphql');
const DELETE_USER                   = loader("src/graphql/mutations/delete_user.graphql");

export const Users = ({ setLoading, alert, error }) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useForm({
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    useDefaultValues: { companyId: "empty" },
  });

  const searchName = watch("searchName");
  const companyWatch = useWatch({ control, name: "companyId" });

  const {  triggerUnexpectedError } = useAppContext();

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filtered, setFiltered] = useState();
  const [filterBy, setFilterBy] = useState([]);
  const [selected, setSelected] = useState([])
  const [activeUser, setActiveUser] = useState(null);

  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const user = useReactiveVar(stateUser);

  const filterByCompany = (filtered) => {
    if (companyWatch === "empty" || !companyWatch)
      return filtered;
    else
      return filtered?.filter((user) => user.company.id === companyWatch);
  };

  const filter = {
    company: {
      asc: (a, b) => b.company.name.localeCompare(a.company.name),
      desc: (a, b) => a.company.name.localeCompare(b.company.name),
    },
    name: {
      asc: (a, b) => b.name.localeCompare(a.name),
      desc: (a, b) => a.name.localeCompare(b.name),
    },
    email: {
      asc: (a, b) => b.email.localeCompare(a.email),
      desc: (a, b) => a.email.localeCompare(b.email),
    },
  };

  const handleFilterChange = (column, order) => {
    if (filterBy[0] === column && filterBy[1] === order) {
      if (filterBy[1] === "asc") {
        setFilterBy([column, "desc"]);
        setFiltered(filtered?.sort(filter[column]["desc"]));
      } else {
        setFilterBy([column, "asc"]);
        setFiltered(filtered?.sort(filter[column]["asc"]));
      }
    } else {
      setFilterBy([column, order]);
      setFiltered(filtered?.sort(filter[column][order]));
    }
  };

  const [fetchUsers, { loading: fetchingUsers }] = useLazyQuery(USERS_QUERY, {
    onCompleted: (data) => {
      setUsers(data.users);
      setFiltered(data.users);
    },
    onError: () => {
      triggerUnexpectedError();
    },
  });

  const [ fetchRoles, { loading: fetchingRoles }] = useLazyQuery(ROLES_QUERY, {
    onCompleted: data => setRoles(data.roles),
    onError: error => triggerUnexpectedError(error.message)
  });

  const [deleteUser, { loading: deleting }] = useMutation(DELETE_USER, {
    onCompleted: () => {
      fetchUsers();
      alert("User successfully deleted");
    },
    onError: () => {
      triggerUnexpectedError();
    },
  });

  const [ inviteUser, { loading: invitingUser } ] = useMutation(INVITE_USER_MUTATION, {
    onCompleted: async(data) => {
      fetchUsers();
      alert("Invitation has been sent to User.");
    },
    onError: error => triggerUnexpectedError(error.message)
  });

  const [ resendUserInvitation, { loading: resendingInvitation } ] = useMutation(RESEND_USER_INVITATION_MUTATION, {
    onCompleted: async(data) => {
      fetchUsers();
      alert("Invitation has been sent to User.");
    },
    onError: error => triggerUnexpectedError(error.message)
  });

  const [ updateUser, { loading: updatingUser } ] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: async(data) => {
      const updatedUser = data.updateUser;
      const _users = filtered.map(user => {
        if(user.id === updatedUser.id) return {...user, ...updatedUser }
        else return user
      })
      setFiltered(_users);
      alert('User successfully updated');
    },
    onError: error => triggerUnexpectedError(error.message)
  })

  const [fetchCompanies,
    {
      loading: fetchingCompanies,
      data: companiesData
    }
  ] = useLazyQuery(COMPANIES_QUERY, {
    onError: error => triggerUnexpectedError(error.message)
  });

  const handleInviteUser = (userAttributes) => {
    userAttributes["email"] = userAttributes["email"].toLowerCase();
    inviteUser({ variables: { userAttributes } })
  }
  const handleResendUserInvitation = (userId) => {
    resendUserInvitation({ variables: { userId } })
  }

  const handleUserUpdate = (userAttributes) => {
    delete userAttributes.email;
    updateUser({ variables: { userAttributes } })
  }

  useEffect(() => {
    setLoading(
      fetchingUsers     ||
      fetchingRoles     ||
      invitingUser      ||
      updatingUser      ||
      fetchingCompanies ||
      deleting          ||
      resendingInvitation
    );
  }, [ deleting, invitingUser,fetchingRoles, updatingUser, fetchingUsers, fetchingCompanies, resendingInvitation]);

  useEffect(() => {
    fetchUsers();
    fetchRoles();
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (searchName !== null && searchName?.trim() !== "") {
      const filter = fuzzysort.go(searchName, users, { key: "name", threshold: -10000 });
      const result = [];
      filter.forEach((e) => {
        result.push(e.obj);
      });
      setFiltered(result);
    } else {
      fetchUsers();
    }
  }, [searchName]);


  const handleDeleteUser = (id) => deleteUser({ variables: { id } });

  const handleCheckBoxClick = (status) => status ? setSelected([]) : setSelected(filterByCompany(filtered)?.map((es)=>es?.id))

  const companiesOptions =
    checkSuperAdmin(user) && makeOptions(companiesData?.companies)

  return (
    <>
      <AppLayout.Header className={cn(styles.header, 'card-with-border')}>
        <div className="d-flex justify-content-between">
          <form className={cn(styles.searchForm)}>
            <Input className={styles.searchInput} name="searchName" register={register} type="text" icon="search" placeholder="Search for a user..." />
            {checkPermission(user, 'read:company') &&
              <div className={cn(styles.companyDropdown, "d-flex align-items-center justify-content-center")}>
              Company
              <Select register={register} name={'companyId'} placeholder="Select any" className={cn(styles.select, "ms-3 me-3")}>
                <Select.Item key={"null"} value={"empty"} active={companyWatch === ""}>
                  All Companies
                </Select.Item>
                {companiesData?.companies?.map((company) => (
                  <Select.Item key={`company-${company.id}`} value={company.id} active={companyWatch === company.id}>
                    {company.name}
                  </Select.Item>
                ))}
              </Select>
            </div>}
          </form>
          <div className="flex-03 d-flex align-items-center">
            <div onClick={() => {setModal(true)}} className={cn(styles.addBtn, "button")}>
              <FeatherIcon icon="user-plus" size={17} className="margin-right--small" />
              Invite
            </div>
          </div>
        </div>

      </AppLayout.Header>
      <AppLayout.Body className={cn(styles.body, 'card-with-border')}>
        {!!selected?.length &&
          <div className={styles.checkboxBar}>
            <div className={styles.bar}>
              <div className={styles.content}>
                <div className={styles.text}>Bulk Actions ( {selected?.length} users selected ) </div>
                <div className={styles.deleteUsers}><Button icon="trash-2" type="primary" onClick={()=>{}} className={styles.button}> {'Delete'}</Button></div>
              </div>
              <div className={styles.arrowDown}></div>
            </div>
          </div>
        }
        <div className={cn(styles.tableHeaders, {
          [styles.tableHeadersWithoutCompany]: !checkPermission(user, 'read:company')
        })}>
          <span
            className={cn(styles.statusParent, styles.filter, "d-flex align-items-center")}
          >
            <Checkbox className={styles.checkbox} onClick={()=>handleCheckBoxClick(selected?.length === filterByCompany(filtered)?.length)} checked={!!selected?.length && selected?.length === filterByCompany(filtered)?.length}/>
          </span>
          {checkPermission(user, 'read:company') &&
            <span
              onClick={() => {
                handleFilterChange("company", "asc");
              }}
              className={cn(styles.filter, "d-flex align-items-center")}
            >
              Company
              {filterBy[0] === "company" && (
                <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
              )}
            </span>
          }
          <span
            onClick={() => {
              handleFilterChange("name", "asc");
            }}
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Name
            {filterBy[0] === "name" && (
              <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
            )}
          </span>
          <span
            onClick={() => {
              handleFilterChange("email", "asc");
            }}
            className={cn(styles.filter, "d-flex align-items-center")}
          >
            Email
            {filterBy[0] === "email" && (
              <FeatherIcon icon={`chevron-${filterBy[1] === "desc" ? "up" : "down"}`} size={15} className="margin-left--small" />
            )}
          </span>
          <span>
            Contact Number
          </span>
          <span>
            Verified
          </span>
          <span className={cn(styles.roles,"d-flex align-items-center justify-content-end")}>
          </span>

        </div>
        <div className={styles.tableRows}>
          {users?.length === 0 && (
            <div className={cn(styles.message, "d-flex align-items-center justify-content-center")}>
              <p className={cn(styles.centerTitle, "title-3 margin-right")}>{String.fromCodePoint("128526")} Get started by creating a</p>
              <div onClick={() => {setModal(true)}} className={cn(styles.centerBtn, "button")}>
                <FeatherIcon icon="user-plus" size={15} className="margin-right--small" />
                New User
              </div>
            </div>
          )}
          {filterByCompany(filtered)?.map((user) => (
            <UserRow
              key={user.id}
              user={user}
              setActiveUser={setActiveUser}
              setIsOpen={setIsOpen}
              selected={selected}
              setSelected={setSelected}
              onDelete={handleDeleteUser}
              onResendUserInvitation={handleResendUserInvitation}
            />
          ))}
          <InviteUserForm
            handleInviteUser={handleInviteUser}
            allRoles={roles}
            companies={checkSuperAdmin(user)&&
              companiesData?.companies}
            onClose={() => {setModal(false)}}
            visible={modal}
          />
          {isOpen && <UpdateUserForm
            onUserUpdate={handleUserUpdate}
            selectedUser={activeUser}
            roles={roles}
            companies={companiesOptions}
            onClose={() => { setIsOpen(false) }}
            visible={isOpen}
          />}
        </div>
      </AppLayout.Body>
    </>
  );
};
