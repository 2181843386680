import { Badge, Dropdown, ListGroup } from "react-bootstrap";
import moment from "moment";
import { IconButton } from 'components/IconButton/IconButton';
import cn from 'classnames';
import styles from './List.module.scss'
import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { consolidatedCsvs } from "utils/helpers";
import { saveAs } from "file-saver";

const DOWNLOAD_DIARY_BLOCK_QUERY = loader("src/graphql/queries/multiple_block_results.graphql")

export const List =({
  required,
  stakeholder,
  block,
  result,
  formattedDateNZ,
  diaryBlock,
  forceCompletion = false,
  onNavigate = () => {},
  entries,
  className = "",
  })=> {

    const [downloadCsv, { loading: downloading }] = useLazyQuery(DOWNLOAD_DIARY_BLOCK_QUERY, {
      onCompleted: (data) => {
        if (data?.multipleBlockResults) {
          const blob = consolidatedCsvs(data.multipleBlockResults.map((csv)=>csv.result))
            saveAs(
                blob,
                `${block?.name.replace(/ /g, "_")}.csv`
            );
        }
      }
    });

    const formatUpdatedAt=(dateString)=> {
      return moment.utc(dateString).local().format('MMM DD, YYYY @h:mmA');
    }

    const latestUpdatedAt = result.reduce((latest, res) => {
        const updatedAt = moment.utc(res.updatedAt);
        if (!latest || updatedAt.isAfter(latest)) {
            return updatedAt;
        } else {
            return latest;
        }
    }, null);
    
    let formattedTime;
    if(!entries){
      formattedTime = "No Logging found"
    }else if (latestUpdatedAt) {
        formattedTime = `Last updated ${formatUpdatedAt(latestUpdatedAt)}`;
    } else {
        formattedTime = "No Logging found";
    }

    const downloadResultCsv=()=>{
      downloadCsv({variables:{blockResultIds: result?.map((r)=>r.id)}})
    }

    return (
        <div className="mb-4">
        <ListGroup>
            <ListGroup.Item
            className={cn(`${styles.listItem}  list-item--success d-flex justify-content-between`)}
            style={{ borderLeft: `3px solid #134f5c` }}
            >
              <div className={cn(styles.content)} onClick={onNavigate}>
                <div className={cn("d-flex")}>
                  <p className="title-4 font-weight-500">{block?.name}</p>
                </div>
                <p className={cn(styles.date) + " pt-2"}>{formattedTime}</p>
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle className={cn(styles.dropdownToggle)}>
                    <IconButton className={cn(styles.moreBtn)} icon="more-vertical" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={cn(styles.dropdownMenu)}>
                    <Dropdown.Item
                    className={cn(styles.menuItem)}
                    href="#"
                    onClick={downloadResultCsv}
                    >
                      Download
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              </ListGroup.Item>
        </ListGroup>
      </div>
    )
}