//@ts-nocheck
import { List } from "components/List/List"
import { RecordsProps } from "./interfaces/RecordsProps"
import { useQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { loader } from "graphql.macro";
import cn from 'classnames';
import styles from './Records.module.scss'
import { RecordEntries } from "./components/RecordEntries/RecordEntries";

const BLOCKS_WITH_RECORDS_QUERY = loader("src/graphql/queries/external_stakeholder_blocks_with_records.graphql")

export const Records: React.FC<RecordsProps> = ({id, setLoading, title, fieldType}) =>{
    const [blocks, setBlocks] = useState([])
    const [data , setData ] = useState({})

    const { loading: fetching } = useQuery(BLOCKS_WITH_RECORDS_QUERY, {
        variables: { stakeholderId: id, fieldType: fieldType },
        onCompleted: (data) => {
            setBlocks(data?.externalStakeholderBlocksWithRecords)
        }
      });
    
    useEffect(()=>{
        setLoading(fetching)
    },[fetching])

    return(
        <div className="d-flex">
            <List
                title={title}
                subtitle={`Select one of the ${title} Blocks to view all existing entries.`}
                className="margin-right--large"
            >
                <List.Category>
                    {blocks?.map((block) => (
                            <List.Item
                            key={block.blockId}
                            title={block.blockName}
                            value={block.blockId}
                            showWithChildren
                            childrenClassName={styles.listItem}
                            onClick={() => {
                                let data = {
                                    blockId: block.blockId,
                                    stakeholderJourneyId: block.stakeholderJourneyId,
                                    types: block.types,
                                    blockName: block.blockName
                                }
                                setData(data)
                            }}><div className={cn(styles.root,"d-flex")}>
                                <div className={styles.journeyName}>{block.blockName}</div>
                                <div className={styles.blockName}>{block.journeyName}</div>
                                </div></List.Item>
                        ))
                    }
                </List.Category>
            </List>

            <>{!!(data && data?.blockId) &&
                <RecordEntries setLoading={setLoading} types={data.types} blockName={data.blockName} blockId={data?.blockId} stakeholderJourneyId={data.stakeholderJourneyId}/>
            }
            </>
        </div>
    )
}