import { useState } from 'react';
import { List } from '../../../../components/List/List';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { BlockTypes } from '../../../../constants/block-types-enum.js';
import { BlockBuilder } from './BlockBuilder/BlockBuilder';
import { loader } from "graphql.macro";
import { useEffect } from 'react';
import { useAppContext } from 'templates/AppLayout/AppLayout';
import { StakeholderTagType } from 'constants/stakeholder-tag-type-enum';
import { Placeholder } from './Placeholder/Placeholder';
import { Modal } from 'components/Modal/Modal';

const BLOCKS_QUERY                      = loader('src/graphql/queries/blocks.graphql');
const STAKEHOLDER_TAGS_QUERY            = loader('src/graphql/queries/stakeholder_tags.graphql');
const CREATE_BLOCK_MUTATION             = loader('src/graphql/mutations/create_block.graphql');
const UPDATE_BLOCK_MUTATION             = loader('src/graphql/mutations/update_block.graphql');
const DELETE_BLOCK_MUTATION             = loader('src/graphql/mutations/delete_block.graphql');
const GET_TEMPLATE_EDIT_URL_MUTATION    = loader('src/graphql/mutations/get_template_edit_url.graphql');
const UPDATE_DOCUMENT_TEMPLATE_MUTATION = loader('src/graphql/mutations/update_document_template.graphql');
const DELETE_BLOCKS_MUTATION            = loader('src/graphql/mutations/delete_blocks.graphql');
const COPY_BLOCK_MUTATION               = loader('src/graphql/mutations/copy_block.graphql')

export const Blocks = ({ setLoading, alert, error }) => {

  const { triggerUnexpectedError } = useAppContext();

  const [block, setBlock] = useState();
  const [blocks, setBlocks] = useState([]);
  const [saved, setSaved] = useState(false);
  const [stakeholderTags, setStakeholderTags] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [errorModal, setErrorModal] = useState(false)
  const [isDocumentRemoved, setIsDocumentRemoved] = useState(false)
  const [loaderState, setLoaderState] = useState(false)

  const [ fetchBlocks, { loading: fetchingBlocks }] = useLazyQuery(BLOCKS_QUERY, {
    onCompleted: data => setBlocks(data.blocks),
    onError: error => triggerUnexpectedError(error.message)
  });

  const { loading: fetchingStakeholderTags } = useQuery(STAKEHOLDER_TAGS_QUERY, {
    onCompleted: data => setStakeholderTags(data.stakeholderTags),
    onError: error => triggerUnexpectedError(error.message)
  })

  const selectedBlocksInJourney=()=>{
    var check = false
    blocks?.forEach((block)=>check = selectedBlocks?.includes(block?.id) && block?.usedInJourney ? true : check)
    return check
  }
  const [ createBlock, { loading: creatingBlock } ] = useMutation(CREATE_BLOCK_MUTATION, {
    onCompleted: async(data) => {
      const newBlock = data.createBlock;
      setBlocks(curr => [...curr, newBlock]);
      setBlock(newBlock)
    },
    onError: error => triggerUnexpectedError(error.message)
  });

  const [ updateBlock, { loading: updatingBlock } ] = useMutation(UPDATE_BLOCK_MUTATION, {
    onCompleted: async(data) => {
      const updatedBlock = data.updateBlock;
      const _blocks = blocks.map(block => {
        if(block.id === updatedBlock.id) return {...block, ...updatedBlock }
        else return block
      })
      setBlocks(_blocks);
      setBlock(null);
      updatedBlock?.typeOf!== 'document' && setSaved(false);
      setBlock(updatedBlock);
      alert('Block successfully saved');
    },
    onError: error => triggerUnexpectedError(error.message)
  })

  const [ deleteBlock, { loading: deletingBlock } ] = useMutation(DELETE_BLOCK_MUTATION, {
    onCompleted: async(data) => {
      const deletedBlock = data.deleteBlock;
      setBlocks(curr => curr.filter(block => block.id !== deletedBlock.id));
      setBlock(null);
      alert('Block successfully deleted');
    },
    onError: error => triggerUnexpectedError(error.message)
  });

  const [ deleteBlocks, { loading: deletingBlocks } ] = useMutation(DELETE_BLOCKS_MUTATION, {
    onCompleted: async(data) => {
     const deletedBlocks = data.deleteBlocks
     deletedBlocks?.forEach((deletedBlock)=>{
      setBlocks(curr => curr.filter(block => block.id !== deletedBlock.id))
     })
     setBlock(null)
     setSelectedBlocks([])
     alert('Blocks successfully deleted');
    },
    onError: error => triggerUnexpectedError(error.message)
  });

  const [ copyBlock, { loading: copyingBlock } ] = useMutation(COPY_BLOCK_MUTATION, {
    onCompleted: (data) => {
      const newBlock = data.copyBlock;
      setBlocks(curr => [...curr, newBlock]);
      setBlock(newBlock)
      alert('Block clone successfully created');

    },
    onError: (error) => {
      triggerUnexpectedError(error.message)
    }
  });

  const [ getTemplateEditUrl, { loading: gettingTemplateUrl } ] = useMutation(GET_TEMPLATE_EDIT_URL_MUTATION);

  const [ updateDocumentTemplate, { loading: updatingDocumentTemplate } ] = useMutation(UPDATE_DOCUMENT_TEMPLATE_MUTATION, {
    onCompleted: () => {
      fetchBlocks()
    },
    onError: error => triggerUnexpectedError(error.message)
  });


  const handleNewBlock = () => {
    const externalStakeholderTag = stakeholderTags.find(st => st.typeOf === StakeholderTagType.External);

    createBlock({
      variables: {
        blockAttributes: {
          name: 'Enter block name',
          heading: 'Enter block heading',
          typeOf: BlockTypes.Questionnaire,
          stakeholderTagId: externalStakeholderTag.id
        }
      }
    })
  }

  const handleBlockUpdate = (blockAttributes) => {
    if (isDocumentRemoved) {
      delete blockAttributes.documentTemplateAttributes?.id;
      delete blockAttributes.documentTemplateAttributes?.documentTemplatesStakeholderTagsAttributes?.forEach((docTempStakeholderTag) => {
        delete docTempStakeholderTag.id;
        delete docTempStakeholderTag.deleted;
      });
      setIsDocumentRemoved(false)
    }
    delete blockAttributes?.documentTemplateAttributes?.hellosignTemplateId
    delete blockAttributes?.usedInJourney
    delete blockAttributes?.usedInStakeholderJourney
    blockAttributes?.fieldsAttributes?.forEach((e) => {
      delete e.upload
    })

    if(blockAttributes.heading === 'Enter block heading') {
      blockAttributes.heading = blockAttributes.name
    }

    delete blockAttributes.shortCode
    updateBlock({ variables: { blockAttributes } })
    setSaved(true);
  }

  const handleBlockDeletion = () => {
    deleteBlock({ variables: { id: block.id } })
  }

  const handleBlocksDeletion = () =>{
    const ids = selectedBlocks?.map((id)=>parseInt(id))
    deleteBlocks({variables: {ids: ids}})
  }

  useEffect(() => {
    setLoading(
      fetchingBlocks ||
      creatingBlock ||
      updatingBlock ||
      deletingBlock ||
      fetchingStakeholderTags ||
      gettingTemplateUrl ||
      updatingDocumentTemplate ||
      loaderState ||
      copyingBlock
    )
  }, [fetchingBlocks, creatingBlock, updatingBlock, deletingBlock, fetchingStakeholderTags, gettingTemplateUrl, updatingDocumentTemplate, loaderState, copyingBlock])


  useEffect(() => {
    fetchBlocks()
  }, [])

  return (
    <div className='d-flex justify-around'>
       <Modal
        visible={errorModal}
        title="Can't Delete Blocks"
        subtitle="To delete blocks, remove them from journey."
        onClose={() => {setErrorModal(false)}}
      />
      <List
        title='Blocks'
        subtitle='Choose from a list of existing blocks, or go ahead and add a new one.'
        className='margin-right--large'
        onNew={handleNewBlock}
        selectedIds={selectedBlocks}
        setErrorModal={setErrorModal}
        selectedIdsError={selectedBlocksInJourney}
        handleSelectedDeletion={handleBlocksDeletion}
      >
        <List.Category title='Custom Modules' withCount>
          {blocks.map(block => (
            <List.Item
              key={block.id}
              title={block.name}
              value={block.id}
              onClick={() => setBlock(block)}
              displayCheckbox={true}
              setSelectedIds={setSelectedBlocks}
              selectedIds={selectedBlocks}
            />
          ))}
        </List.Category>
      </List>
      {block ?
        <BlockBuilder
          stakeholderTags={stakeholderTags}
          onSave={handleBlockUpdate}
          onDelete={handleBlockDeletion}
          getTemplateEditUrl={getTemplateEditUrl}
          updateDocumentTemplate={updateDocumentTemplate}
          onClose={() => setBlock(null)}
          saved={saved}
          setSaved={setSaved}
          handleRemoveDocument={setIsDocumentRemoved}
          isDocumentRemoved={isDocumentRemoved}
          setLoaderState={setLoaderState}
          setLoading={setLoading}
          triggerUnexpectedError={triggerUnexpectedError}
          id={block.id}
          copyBlock={copyBlock}
        /> :
        <Placeholder />
      }
    </div>
  )
}
