import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { useEnsureStakeholderLayoutContext } from "templates/EnsureStakeholderLayout/EnsureStakeholderLayout";
import { HistoryRow } from "views/StakeholderProfileScreen/tabs/History/components/HistoryRow/HistoryRow";
import cn from 'classnames';
import styles from './FrameworksTab.module.scss';

import { Select } from "components/FormComponents";
import { useForm, useWatch } from "react-hook-form";
import { BlockCardPreview } from "views/StakeholderJourneyScreens/components/BlockCardPreview/BlockCardPreview";
import { StakeholderJourneyStatusType } from "constants/stakeholder-journey-status-enum";
import { titleize } from "utils/helpers";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import ProgressBar from "@ramonak/react-progress-bar";


const STAKEHOLDER_FRAMEWORKS = loader("src/graphql/queries/logged_in_stakeholder_frameworks.graphql");

export const FrameworksTab = () =>{

    const {
        register,
        control,
        setValue,
        } = useForm({
            reValidateMode: "onChange",
            criteriaMode: "firstError",
            shouldFocusError: true,
            useDefaultValues: { tag: "", status: "empty", journey: "empty" },
            defaultValues: {
            status: ''
        }
      });

    const [frameworks, setFrameworks] = useState([])
    const [filtered , setFiltered] = useState([])
    const [framework, setFramework] = useState(null)

    const statusWatch = useWatch({ control, name: "status" });
    const frameworkWatch = useWatch({ control, name: "framework" });
    
    const { setLoading } = useEnsureStakeholderLayoutContext();

    const { loading: fetchingFrameworks } = useQuery(STAKEHOLDER_FRAMEWORKS, {
        onCompleted: data => {
            data?.loggedInStakeholderFrameworks?.forEach((fWork)=>{
              fWork?.stakeholderJourneys.forEach(stakeholderJourney => {
                stakeholderJourney.stakeholderBlocks.forEach(stakeholderBlock => {
                    // Check if typeOf is not "diary"
                    if (stakeholderBlock.block.typeOf !== "diary") {
                        // If typeOf is not "diary", convert result to object at 0th index
                        stakeholderBlock.result = stakeholderBlock.result[0];
                    }
                });
              });
            })
            setFrameworks(data.loggedInStakeholderFrameworks)

        }
    });

    const navigate = useNavigate();
  
    // useEffect(()=>{
    //     setFiltered(nonDiaryJourneys)
    // },[currentStakeholder, nonDiaryJourneys])
    
    useEffect(()=>{
        const filteredWithStatus = statusWatch !== 'empty' ? 
        framework?.stakeholderJourneys?.filter((f) => f.status === statusWatch)
        : framework?.stakeholderJourneys;
        setFiltered(filteredWithStatus);
    },[statusWatch])


    const countStakeholderBlocks = () => {
        let totalBlocks = 0;
        let completedBlocks = 0;
        
        framework?.stakeholderJourneys?.forEach(journey => {
            journey?.stakeholderBlocks?.forEach((stakeholderBlock) => {
                totalBlocks++;
                if (stakeholderBlock?.result?.status === 'completed' && stakeholderBlock?.block?.typeOf !=='diary') {
                    completedBlocks++;
                }
            });
        });
        
        return { totalBlocks, completedBlocks };
    }
    const { totalBlocks, completedBlocks } = countStakeholderBlocks();

    const completedPercentage = () => {
        return Math.floor((totalBlocks > 0) ? (completedBlocks / totalBlocks) * 100 : 0);
    }

    const formatUpdatedAt=(updatedAt)=> {
      return moment(updatedAt).format("MMM DD, YYYY @h:mmA");
    }
    
    const findRecentUpdatedAt=(stakeholderJourney)=> {
        let recentUpdatedAt = null;
    
        stakeholderJourney.stakeholderBlocks.forEach(block => {
            if (block.result && block.typeOf !=='diary') {
                let results = Array.isArray(block.result) ? block.result : [block.result];
                results.forEach(result => {
                    if (result.updatedAt) {
                        let updatedAt = moment(result.updatedAt);
                        if (!recentUpdatedAt || updatedAt.isAfter(recentUpdatedAt)) {
                            recentUpdatedAt = updatedAt;
                        }
                    }
                });
            }
        });
    
        if (recentUpdatedAt) {
            return `• Last updated at ${formatUpdatedAt(recentUpdatedAt)}`;
        } else {
            return "No Data found";
        }
    }

    useEffect(()=>{
      const _frameworks = frameworks?.find((f)=>f.id === frameworkWatch)
      setFramework(_frameworks)
      setFiltered(_frameworks?.stakeholderJourneys)
    },[frameworkWatch])

    useEffect(()=>{
      setLoading(fetchingFrameworks)
    },[fetchingFrameworks])

      return (
        
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={cn("d-flex justify-content-between", styles.header)}>
              <div>
                <h1 className="title-3 mt-4">
                <Select register={register} name='framework' buttonStyles={styles.frameworkSelector}>
                    {frameworks?.map(framework => (
                        <Select.Item
                        key={framework.id}
                        value={framework.id}
                        active={frameworks[0].id}
                        >
                        {framework.name}
                        </Select.Item>
                    ))}
                </Select>
                </h1>
                <p className={styles.subtitle}>Edit and review your framework information below</p>
              </div>
              <div className={cn("flex-04 d-flex align-items-center justify-content-end w-50",styles.search)}>
                <div className="t-subtitle d-flex align-items-center justify-content-center w-50">
                  Status
                  <Select register={register} name={`status`} placeholder="Select any" className={cn(styles.select, "ms-3 me-3")}>
                    <Select.Item key={"null"} value={"empty"} active={statusWatch === ""}>
                      Any
                    </Select.Item>
                    {StakeholderJourneyStatusType?.map((status, index) => (
                      <Select.Item key={index} value={status.key} active={statusWatch === status.key}>
                        {titleize(status.value)}
                      </Select.Item>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.body} >
              <div className={cn("card card-with-border", styles.card)}>
                {framework?.stakeholderJourneys?.length ? <p className={styles.text}><span>{`${completedBlocks}/${totalBlocks}`}</span> Blocks completed from<span>{` ${framework?.stakeholderJourneys?.length} `}</span>Journeys of {framework?.name}</p> : 'No Frameworks Yet'}
                <ProgressBar className={styles.progressBar} labelAlignment="center" bgColor="linear-gradient(to right, #469db2, #134f5c)" maxCompleted={100} completed={completedPercentage()} />
              </div>
              <hr className={styles.lineBreak} />
            </div>
            {
              filtered?.map((sj) => {
                const name = sj?.name
                const status = titleize(sj?.status)
                
                const formattedDateNZ = findRecentUpdatedAt(sj)
    
                return (
                  <div className={styles.root}>
                    <div className={styles.title}>
                      <div className={styles.headerContainer}>
                        <h2 className={styles.name}>{name}</h2>
                        <h3 className={styles.date}>{formattedDateNZ}</h3>
                      </div>
                      <Badge className={cn(styles.badge, `badge--status-${sj?.status}`)}>{status}</Badge>
                    </div>
                    <div className={cn(styles.block)}>
                      {
                        sj?.stakeholderBlocks?.map((stakeholderBlock) => {
                          return (
                            <div className={cn(styles.card, "w-100")}>
                              <BlockCardPreview
                                key={stakeholderBlock?.journeyBlockId}
                                stakeholder={""}
                                diaryBlock={stakeholderBlock?.block?.typeOf==='diary'}
                                {...stakeholderBlock}
                                onNavigate={() => navigate(`/journey/${sj.token}/block/${stakeholderBlock.journeyBlockId}`, {
                                    state: { referrer: window.location.pathname, tab: 'frameworks' } // Pass the referrer as state
                                  })}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                    <hr className={styles.lineBreak} />
                  </div>
                )
              })
            }
          </div>
        </div>
      )
}