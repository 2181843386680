import { LinkVideoInput } from 'components/FormComponents';

export const VideoSettings = ({ register, name, watch, errors }) => {
  return (
    <LinkVideoInput 
      register={register}
      className='margin-top--large'
      name={`${name}`}
      value={watch(`${name}`)}
      validators={{ required: true }}
      error={errors?.options?.videoSrc?.type}
    />
  )
}