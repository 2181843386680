import { List } from "components/List/List";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FrameworkBuilder } from "./FrameworkBuilder/FrameworkBuilder";
import { NewFrameworkModal } from "./components/NewFrameworkModal/NewFrameworkModal";


const FRAMEWORKS_QUERY = loader('src/graphql/queries/frameworks.graphql');
const BRANDS_QUERY = loader("src/graphql/queries/brands.graphql");
const JOURNEYS_QUERY = loader("src/graphql/queries/journeys_with_blocks.graphql")

const CREATE_FRAMEWORK_MUTATION = loader('src/graphql/mutations/create_framework.graphql')
const UPDATE_FRAMEWORK_MUTATION = loader('src/graphql/mutations/update_framework.graphql')
const DELETE_FRAMEWORK_MUTATION = loader('src/graphql/mutations/delete_framework.graphql')


export const Frameworks = ({setLoading, alert, error}) =>{
  const [frameworks, setFrameworks] = useState([])
  const [framework, setFramework] = useState(null)

  const [brands, setBrands] = useState([])

  const [journeys, setJourneys] = useState([])

  const [newModal, setNewModal] = useState(false)

  const { loading: fetchingFrameworks } = useQuery(FRAMEWORKS_QUERY, {
    onCompleted: data => setFrameworks(data.frameworks),
  })
  const { loading: fetchingBrands } = useQuery(BRANDS_QUERY, {
    onCompleted: data => setBrands(data.brands),
  })
  const { loading: fetchingJourneys } = useQuery(JOURNEYS_QUERY, {
    onCompleted: data => setJourneys(data.journeysWithBlocks),
  })

  const [ createFramework, { loading: creatingFrameworks } ] = useMutation(CREATE_FRAMEWORK_MUTATION, {
    onCompleted: async(data) => {
      const newFramework = data.createFramework;
      setFrameworks(curr => [...curr, newFramework]);
      setFramework(newFramework)
      alert("Framework successfully created.")
    }
  });

  const [ updateFramework, { loading: updatingFramework } ] = useMutation(UPDATE_FRAMEWORK_MUTATION, {
    onCompleted: async(data) => {
      const updatedFramework = data.updateFramework;
      const _frameworks = frameworks.map(framework => {
        if(framework.id === updateFramework.id) return {...framework, ...updatedFramework }
        else return framework
      })
      setFrameworks(_frameworks)
      setFramework(updatedFramework)
      alert("Framework successfully updated.")
    }
  });

  const [ deleteFramework, { loading: deletingFramework } ] = useMutation(DELETE_FRAMEWORK_MUTATION, {
    onCompleted: async(data) => {
      const deletedFramework = data.deleteFramework;
      const _frameworks = frameworks.filter((_framework)=> _framework.id !== deletedFramework.id)
      setFrameworks(_frameworks)
      setFramework(null)
      alert("Framework successfully deleted.")
    }
  });

  const handleNewFramework = (frameworkAttributes) => {
    createFramework({ variables: { frameworkAttributes: frameworkAttributes }})
    setNewModal(false);
  }

  const handleFrameworkUpdation = (data) =>{
    updateFramework({variables: {frameworkAttributes: data}})
  }

  const handleFrameworkDeletion = (id) =>{
    deleteFramework({variables: {id: id}})
  }

  useEffect(()=>{
    setLoading(creatingFrameworks || fetchingFrameworks || fetchingBrands || fetchingJourneys || updatingFramework)
  },[fetchingFrameworks,creatingFrameworks,fetchingBrands,fetchingJourneys,updatingFramework ])

    return (
        <div className='d-flex justify-around'>
          <NewFrameworkModal
            brands={brands}
            visible={newModal}
            onClose={() => setNewModal(false)}
            onSubmit={handleNewFramework}
          />
          <List
              title='Frameworks'
              subtitle='Choose from a list of existing frameworks, or go ahead and add a new one.'
              className='margin-right--large'
              onNew={() => setNewModal(true)}
          >
        <List.Category title='Custom Modules' withCount>
          {frameworks.map(framework => (
            <List.Item
              key={framework.id}
              title={framework.name}
              value={framework.id}
              onClick={()=>setFramework(framework)}
              // displayCheckbox={true}
              // setSelectedIds={setSelectedBlocks}
              // selectedIds={selectedBlocks}
            />
          ))}
        </List.Category>
      </List>
      {framework &&
       <FrameworkBuilder
        journeys={journeys}
        brands={brands}
        id={framework.id}
        setLoading={setLoading}
        onSave={handleFrameworkUpdation}
        onDelete={handleFrameworkDeletion}
       />
      }
        </div>
    )
}