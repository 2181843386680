import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { Input, InputGroup, Button } from './../../components/FormComponents';
import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useState } from 'react';
import { Loading } from './../../components/Loading/Loading';
import styles from '../ForgotPasswordScreen/ForgotPasswordScreen.module.scss';
import adjustments from './ResetPasswordScreen.module.scss';
import PasswordStrengthBar from 'react-password-strength-bar';
import cn from 'classnames';
import img from '../../assets/images/emailImage.svg';

const RESET_PASSWORD_MUTATION = loader('src/graphql/mutations/reset_password.graphql')
const SET_PASSWORD_MUTATION = loader('src/graphql/mutations/set_password.graphql')
const RESEND_USER_INVITATION_MUTATION = loader('src/graphql/mutations/resend_user_invitation.graphql');
const VERIFY_JWT_TOKEN_MUTATION = loader("src/graphql/mutations/verify_jwt_token.graphql");

export const ResetPasswordScreen = ({resetPasswordType}) => {
  const [step, setStep] = useState(1);
  const [scorePass, setScorePass] = useState(false);
  const [valid, setValid] = useState(false);
  const [customError, setCustomError] = useState(null);

  const { signedId } = useParams();

  const { register, handleSubmit, formState: { errors }, control, setError, clearErrors } = useForm({
    reValidateMode: 'onChange',
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  const watchPassword = useWatch({ control, name: 'password'});
  const watchConfirmPassword = useWatch({control, name: 'confirm_password'});

  const [ resetPassword, { loading: resetingPassword } ] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: async(data) => {
      if (data.resetPassword) {
        setStep(4); // Redirect to sign_in after successfull password reset
      }
    },
    onError: error => {
      setCustomError(error.message)
      setStep(2);
    }
  });

  const [verifyJwtToken, { loading: verifying }] = useMutation(VERIFY_JWT_TOKEN_MUTATION, {
    variables: { token: signedId },
    onCompleted: () => {
      setStep(1);
    },
    onError: (error) => {
      if (error.message === 'token_expired')
        setCustomError(`Your ${resetPasswordType ? 'verification' : 'invitation'} link has expired`)
      else
        setCustomError(`Your ${resetPasswordType ? 'verification' : 'invitation'} link is invalid`)
      setStep(2);
    }
  });

  useEffect(()=>{
    verifyJwtToken()
  },[])

  const [ setPassword, { loading: settingPassword } ] = useMutation(SET_PASSWORD_MUTATION, {
    onCompleted: async(data) => {
      if (data.setPassword) {
        setStep(4); // Redirect to sign_in after successfull password set
      }
    },
    onError: error => {
      setCustomError(error.message)
      setStep(2);
    }
  });

  const [ resendUserInvitation, { loading: resendingInvitation } ] = useMutation(RESEND_USER_INVITATION_MUTATION, {
    onCompleted: async(data) => {
        setStep(3);
      },
      onError: error => {
      setCustomError(error.message)
    }
  });

  const handleResendUserInvitation = () => {
    resendUserInvitation({ variables: {token: signedId } })
  }


  const handleResetPassword = (newPassword, signedId) => {
    resetPassword({
      variables: {
        newPassword,
        signedId
      }
    })
  }

  const handleSetPassword = (newPassword, signedId) => {
    setPassword({
      variables: {
        newPassword,
        signedId
      }
    })
  }

  useEffect(() => {
    if(!watchPassword) {return}

    if (scorePass) {
      clearErrors('password');
    } else {
      setError('password', {type: 'custom', message: 'The password is not strong enough'})
    }

    if (watchPassword === watchConfirmPassword) {
      clearErrors('confirm_password');
    } else {
      setError('confirm_password', {type: 'custom', message: 'The passwords need to match'})
    }

    setValid(Object.keys(errors).length === 0);
  }, [watchConfirmPassword, watchPassword, scorePass, errors])

  const onSubmit = (data) => {
    if (!valid) return;

    resetPasswordType ?
      handleResetPassword(data.password, signedId) :
      handleSetPassword(data.password, signedId);
  }

  return (
    <div className={styles.mainContainer}>
      <Loading visible={resetingPassword || settingPassword || resendingInvitation || verifying} />
      <img className={styles.logoImg} src='/assets/images/knowmy/ensure.svg' alt='Ensure Logo' />
      {step === 1 &&
        <div className='card card-with-shadow margin-top'>
            <div className={`${styles.contentContainer} ${adjustments.contentContainer} card_content`}>
              <h1 className={`${styles.primaryText} ${adjustments.primaryText} design-marker`}>
                {resetPasswordType ? 'Reset your password' : 'Set your new password'}</h1>
              <p className={styles.secondaryText}>Enter your new password twice and we will take care of the rest 👍</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className={adjustments.input} title='New Password'>
                  <Input
                    name='password'
                    icon='lock'
                    type='password'
                    placeholder='Enter your new password'
                    register={register}
                    validators={{ required: true }}
                    error={errors?.password?.message || errors?.password?.type}
                    inputProps={{ tabIndex: 2 }}
                  />
                  <PasswordStrengthBar
                    password={watchPassword}
                    onChangeScore={(score) => { score > 2 ? setScorePass(true) : setScorePass(false) }}
                  />
                </InputGroup>
                <InputGroup className={adjustments.input} title='Confirm New Password'>
                  <Input
                    name='confirm_password'
                    icon='lock'
                    type='password'
                    placeholder='Re-Enter your new password'
                    register={register}
                    validators={{ required: true }}
                    error={errors?.confirm_password?.message || errors?.confirm_password?.type}
                    inputProps={{ tabIndex: 2 }}
                  />
                </InputGroup>
                <Button submit buttonProps={{ tabIndex: 3 }} className='w-100'> {resetPasswordType ? 'Reset Password' : 'Set Password'}</Button>
              </form>
            </div>
          </div>
      }
      {
        step === 2 &&
        <LinkExpired
          resetPasswordType={resetPasswordType}
          message={customError}
          onResendClick={handleResendUserInvitation}
        />
      }
      {step === 3 &&
        <LinkSent />
      }
      {step === 4 && <Navigate to="/sign_in" />}
    </div>
  );
}

const LinkSent = () => {
  return (
    <div className='card card-with-shadow margin-top'>
      <div className={`${adjustments.messageContainer} card_content`}>
        <h1 className={cn(adjustments.messageText, adjustments.designMarker,  adjustments.linkSent)}>
          Invite Link Sent
        </h1>
        <p className={cn(adjustments.messageDescription)}>You should receive an email with a link to accept the invitation. You can close this window.</p>
        <img className={adjustments.emailImg} src={img} alt='Email Sent' />
      </div>
    </div>
  )
}

const LinkExpired = ({resetPasswordType, message, onResendClick = () => { } }) => {
  const navigate = useNavigate();
  return (
    <div className='card card-with-shadow margin-top'>
      <div className={`${adjustments.errorContainer} card_content`}>
        <h1 className={` ${adjustments.errorText} ${adjustments.designMarker}`}>
          {message} 😟
        </h1>
        <Button
          type='link'
          buttonProps={{ tabIndex: 3 }}
          className={adjustments.resendButton}
          onClick={resetPasswordType ?
            () => navigate('/forgot_password') :
            onResendClick}
        >
          Resend Link
        </Button>
      </div>
    </div>
  )
}
